import { editHyperlinkHandler } from './editHyperlink'

export function previewHyperlinkModal(options) {
  const { href } = options.link
  let isEditModalOpen = false
  const theme = localStorage.getItem('oriens-active-theme')

  // Create the main container
  const mainContainer = document.createElement('div')
  mainContainer.classList.add('elevation-1', 'v-card', 'v-sheet', `theme--${theme}`)
  mainContainer.style.maxWidth = '300px'

  // Create the inner container for text
  const innerContainer = document.createElement('div')
  innerContainer.classList.add('v-card__text', 'pa-3')
  mainContainer.appendChild(innerContainer)

  // Create the form
  const form = document.createElement('form')
  form.classList.add('v-form')
  form.setAttribute('novalidate', 'novalidate')
  innerContainer.appendChild(form)

  // Create the row container
  const row = document.createElement('div')
  row.classList.add('row', 'align-center')
  form.appendChild(row)

  // Create the first column with the link
  const col1 = document.createElement('div')
  col1.classList.add('col', 'col-6')
  const link = document.createElement('a')
  link.href = href
  link.textContent = href.length > 30 ? `${href.substring(0, 30)}...` : href
  link.setAttribute('target', '_blank')
  col1.appendChild(link)
  row.appendChild(col1)

  // Create the second column with buttons
  const col2 = document.createElement('div')
  col2.classList.add('d-flex', 'col', 'col-6')
  row.appendChild(col2)

  // Function to create buttons
  function createButton(iconPath) {
    const button = document.createElement('button')
    button.type = 'button'
    button.classList.add(
      'v-btn',
      'v-btn--is-elevated',
      'v-btn--fab',
      'v-btn--has-bg',
      'v-btn--round',
      `theme--${theme}`,
      'v-size--x-small',
      'primary',
    )
    const buttonContent = document.createElement('span')
    buttonContent.classList.add('v-btn__content')
    const iconSpan = document.createElement('span')
    iconSpan.classList.add('v-icon', 'notranslate', `theme--${theme}`)
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    svg.setAttribute('viewBox', '0 0 24 24')
    svg.setAttribute('role', 'img')
    svg.setAttribute('aria-hidden', 'true')
    svg.classList.add('v-icon__svg')
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.setAttribute('d', iconPath)
    svg.appendChild(path)
    iconSpan.appendChild(svg)
    buttonContent.appendChild(iconSpan)
    button.appendChild(buttonContent)

    return button
  }

  // Create and append the buttons
  const icons = [
    'M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z',
    'M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z',
    'M2,5.27L3.28,4L20,20.72L18.73,22L13.9,17.17L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L12.5,15.76L10.88,14.15C10.87,14.39 10.77,14.64 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C8.12,13.77 7.63,12.37 7.72,11L2,5.27M12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.79,8.97L9.38,7.55L12.71,4.22M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.2,10.54 16.61,12.5 16.06,14.23L14.28,12.46C14.23,11.78 13.94,11.11 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z',
  ]

  icons.forEach((iconPath, index) => {
    const button = createButton(iconPath)
    if (index === 0) {
      button.addEventListener('click', () => {
        options.tippy.hide()
        navigator.clipboard.writeText(href)
      })
    }

    if (index === 1) {
      button.addEventListener('click', () => {
        isEditModalOpen = true
        editHyperlinkHandler({ ...options, mainContainer })
      })
      button.classList.add('mx-1')
    }

    if (index === 2) {
      button.addEventListener('click', () => {
        options.tippy.hide()
        options.editor
          .chain()
          .setTextSelection(options.link.pmViewDesc.posAtStart + 1)
          .unsetHyperlink()
          .setTextSelection(options.link.pmViewDesc.posAtEnd)
          .run()
      })
    }

    col2.appendChild(button)
  })

  // Tooltip show/hide with delay logic
  let hideTimeout

  mainContainer.addEventListener('mouseleave', () => {
    if (!isEditModalOpen) {
      hideTimeout = setTimeout(() => {
        options.tippy.hide()
      }, 700)
    }
  })

  mainContainer.addEventListener('mouseenter', () => {
    clearTimeout(hideTimeout)
  })

  return mainContainer
}
