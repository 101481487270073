import { mergeAttributes, Node } from '@tiptap/core'

export const Column = Node.create({
  name: 'column',
  group: 'block',
  content: 'block+',
  isolating: true,

  addAttributes() {
    return {
      width: {
        default: 50,
        parseHTML: element => element.style.width.replace('px', ''),
        renderHTML: attributes => {
          return { style: `width: ${attributes.width}%` }
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[class=column]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ class: 'column' }, HTMLAttributes), 0]
  },
})
