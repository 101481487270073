import {
  automationLog as automationLogQuery,
  automations as automationsQuery,
  createAutomation as craeteAutomationMutation,
  deleteAutomation as deleteAutomationMutation,
  getAutomationById as getAutomationByIdQuery,
  toggleStatusAutomation as toggleStatusAutomationMutation,
  updateAutomation as updateAutomationMutation,
} from '@/graphql/query/automation'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import Vue from 'vue'

// v3, based on Notion Automation
const useAutomation = () => {
  const fetchAutomations = (folderId, jobTypeId, filter, pagination) => {
    return new Promise((resolve, reject) =>
      apolloClient
        .query({
          query: automationsQuery,
          fetchPolicy: 'no-cache',
          variables: {
            filter: filter,
            folderId: folderId,
            jobTypeId: jobTypeId,
            pagination: pagination,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          resolve(data.automations)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        }),
    )
  }

  const fetchAutomationLog = (folderId, jobTypeId, filter, pagination) => {
    return new Promise((resolve, reject) =>
      apolloClient
        .query({
          query: automationLogQuery,
          fetchPolicy: 'no-cache',
          variables: {
            filter: filter,
            folderId: folderId,
            jobTypeId: jobTypeId,
            pagination: pagination,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          resolve(data.automationLog)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        }),
    )
  }

  const fetchAutomationById = automation_id =>
    new Promise((resolve, reject) =>
      apolloClient
        .query({
          query: getAutomationByIdQuery,
          fetchPolicy: 'no-cache',
          variables: {
            automationId: automation_id,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          resolve(data.getAutomationById)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        }),
    )

  const createAutomation = (automation, triggers, actions) =>
    new Promise((resolve, reject) =>
      apolloClient
        .mutate({
          mutation: craeteAutomationMutation,
          fetchPolicy: 'no-cache',
          variables: {
            automation: automation,
            trigger: triggers,
            action: actions,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(resolve())
        .catch(err => {
          errorHandling(err)
          reject(err)
        }),
    )

  const updateAutomation = (automationId, automation, triggers, actions) =>
    new Promise((resolve, reject) =>
      apolloClient
        .mutate({
          mutation: updateAutomationMutation,
          fetchPolicy: 'no-cache',
          variables: {
            automationId: automationId,
            automation: automation,
            trigger: triggers,
            action: actions,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(resolve())
        .catch(err => {
          errorHandling(err)
          reject(err)
        }),
    )

  const deleteAutomation = automationId =>
    new Promise((resolve, reject) =>
      apolloClient
        .mutate({
          mutation: deleteAutomationMutation,
          fetchPolicy: 'no-cache',
          variables: {
            automationId: automationId,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(resolve())
        .catch(err => {
          errorHandling(err)
          reject(err)
        }),
    )

  const toggleStatusAutomation = (automation_id, value) => {
    apolloClient
      .mutate({
        mutation: toggleStatusAutomationMutation,
        fetchPolicy: 'no-cache',
        variables: {
          value: value,
          automationId: automation_id,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(() =>
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah status Automation!',
        }),
      )
  }

  return {
    fetchAutomations,
    fetchAutomationLog,
    fetchAutomationById,

    createAutomation,
    updateAutomation,
    deleteAutomation,
    toggleStatusAutomation,
  }
}

export default useAutomation
