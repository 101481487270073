import { Extension } from '@tiptap/core'

import DragHandle from './dragHandle'

export const DragAndDrop = Extension.create({
  name: 'dragAndDrop',

  addProseMirrorPlugins() {
    return [
      DragHandle({
        dragHandleWidth: 24,
      }),
    ]
  },
})
