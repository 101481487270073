import {
  mdiFormatParagraph,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatListNumbered,
  mdiFormatListBulleted,
  mdiFormatColumns,
  mdiCodeTags,
  mdiFormatQuoteOpen,
  mdiFormatListCheckbox,
  mdiTable,
  mdiDragHorizontal,
  mdiImage,
  mdiVideo,
  mdiImageText,
  // mdiFileDocumentOutline,
  mdiChevronRightCircleOutline,
  mdiBookmarkOutline,
  mdiFileUploadOutline,
  mdiVolumeHigh,
} from '@mdi/js'
import { PopupImage } from '../buttons/popupImage'
import { PopupVideo } from '../buttons/popupVideo'

const blockTools = [
  {
    name: 'paragraph',
    title: 'Paragraph',
    desc: 'Create a simple text',
    icon: mdiFormatParagraph,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('paragraph').run()
    },
  },
  {
    name: 'heading1',
    title: 'Heading 1',
    desc: 'Large section heading',
    icon: mdiFormatHeader1,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run()
    },
  },
  {
    name: 'heading2',
    title: 'Heading 2',
    desc: 'Medium section heading',
    icon: mdiFormatHeader2,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run()
    },
  },
  {
    name: 'heading3',
    title: 'Heading 3',
    desc: 'Small section heading',
    icon: mdiFormatHeader3,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setNode('heading', { level: 3 }).run()
    },
  },
  {
    name: 'numberedList',
    title: 'Numbered List',
    desc: 'Create a list with numbering',
    icon: mdiFormatListNumbered,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run()
    },
  },
  {
    name: 'bulletedList',
    title: 'Bulleted List',
    desc: 'Create a simple bullet list',
    icon: mdiFormatListBulleted,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run()
    },
  },
  {
    name: 'column 2',
    title: 'Column 2',
    desc: 'Create 2 column of blocks',
    icon: mdiFormatColumns,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).insertColumns(2).run()
    },
  },
  {
    name: 'column 3',
    title: 'Column 3',
    desc: 'Create 3 column of blocks',
    icon: mdiFormatColumns,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).insertColumns(3).run()
    },
  },
  {
    name: 'codeBlock',
    title: 'Code Block',
    desc: 'Create a code snippet',
    icon: mdiCodeTags,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleCodeBlock().run()
    },
  },
  {
    name: 'blockquote',
    title: 'Blockquote',
    desc: 'Capture a quote',
    icon: mdiFormatQuoteOpen,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setBlockquote().run()
    },
  },
  {
    name: 'todoList',
    title: 'Todo List',
    desc: 'Track task with to-do list',
    icon: mdiFormatListCheckbox,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run()
    },
  },
  {
    name: 'separator',
    title: 'Separator',
    desc: 'Divide a block',
    icon: mdiDragHorizontal,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setHorizontalRule().run()
    },
  },
  {
    name: 'image',
    title: 'Image',
    desc: 'Upload or embed image',
    icon: mdiImage,
    command: ({ editor, range }) => {
      PopupImage(editor, range)
    },
  },
  {
    name: 'Video',
    title: 'Video',
    desc: 'Upload or embed youtube',
    icon: mdiVideo,
    command: ({ editor, range }) => {
      PopupVideo(editor, range)
    },
  },
  {
    name: 'callout',
    title: 'Callout',
    desc: 'Make writing stand out',
    icon: mdiImageText,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setCallout().run()
    },
  },
  {
    name: 'table',
    title: 'Table',
    desc: 'Add simple table in page',
    icon: mdiTable,
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
        .run()
    },
  },
  // {
  //   name: 'page',
  //   title: 'Page',
  //   desc: 'Add new page',
  //   icon: mdiFileDocumentOutline,
  //   command: ({ editor, range }) => {
  //     editor.chain().focus().deleteRange(range).insertSubdocument('create_new').run()
  //     const docId = uuidv4()
  //     const title = (window.prompt('Page Title') || 'Untitled').trim().substring(0, 32)
  //     const title = 'Untitled'
  //     editor.chain().focus().deleteRange(range).setPage({ docId, title }).run()
  //   },
  // },
  {
    name: 'toggle',
    title: 'Toggle list',
    desc: 'Hide and show content inside',
    icon: mdiChevronRightCircleOutline,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setDetails().run()
    },
  },
  {
    name: 'Bookmark',
    title: 'Web Bookmark',
    desc: 'Save a link as visual bookmark',
    icon: mdiBookmarkOutline,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setBookmark().run()
    },
  },
  {
    name: 'File',
    title: 'File',
    desc: 'Upload a file',
    icon: mdiFileUploadOutline,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setFile().run()
    },
  },
  {
    name: 'Audio',
    title: 'Audio',
    desc: 'Upload a audio',
    icon: mdiVolumeHigh,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setAudio().run()
    },
  },
]

export default blockTools
