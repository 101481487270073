import Vue from 'vue'
import { ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import {
  jobs,
  jobTypes,
  jobStatus,
  jobPriority,
  jobDetail as jobDetailQuery,
  jobTypeDetail as jobTypeDetailQuery,
  jobsAssignedUser,
} from '@/graphql/queries'
import {
  updateJob,
  deleteJob as deleteJobMutation,
  deleteJobType,
  archiveJobType,
  unarchiveJobType,
  proceedJobStatus,
  convertToJob as convertToJobMutation,
  convertToSubJob as convertToSubJobMutation,
  convertToJobType as convertToJobTypeMutation,
  updateJobTypePermission as updateJobTypePermissionMutation,
} from '@/graphql/mutations'
import { updateRelationJobAndDocument as updateRelationJobAndDocumentMutation } from '@/graphql/mutation/job'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

const useJob = ({ pagination, filter, search, typeId } = {}) => {
  const state = {
    ...useFieldJob([
      'jobFilter',
      'activeTabKanban',
      'jobTypeFilter',
      'jobSort',
      'jobColumns',
      'customAttributes',
      'savedViews',
      'jobGroup',
      'jobStatus',
      'jobPriority',
      'advancedFilter',
      'filterActive',
    ]),
  }
  const workspaceId = store.getters.getCurrentWorkspaceId
  const jobStatusList = ref([])
  const jobTypeList = ref([])
  const jobPriorityList = ref([])
  const jobTypeDetail = ref(null)
  const jobGroup = ref(state.jobGroup.value)
  const jobFilter = ref({
    pagination: {
      page: 1,
      limit: 20,
      offset: 0,
    },
    filter: {
      sort: {
        label: 'Tanggal Ditutup Terlama',
        field: 'expected_close_date',
        type: 'ASC',
      },
      search: '',
      is_archive: false,
    },
  })
  const jobSortOptions = ref([
    {
      label: 'Nama Job A-Z',
      field: 'name',
      order: 'ASC',
    },
    {
      label: 'Nama Job Z-A',
      field: 'name',
      order: 'DESC',
    },
    {
      label: 'Tanggal Ditutup Terlama',
      field: 'expected_close_date',
      order: 'ASC',
    },
    {
      label: 'Tanggal Ditutup Terdekat',
      field: 'expected_close_date',
      order: 'DESC',
    },
    {
      label: 'Diupdate Terlama',
      field: 'updated_at',
      order: 'ASC',
    },
    {
      label: 'Diupdate Terbaru',
      field: 'updated_at',
      order: 'DESC',
    },
  ])
  const jobDetail = ref({})
  const loadingJob = ref(false)
  const loadingUpdateJob = ref(false)
  const loadingJobType = ref(false)
  const loadingJobStatus = ref(false)
  const loadingChangeJobStatus = ref(false)
  const loadingFetchMore = ref(false)
  const loadingJobPagination = ref(false)
  const loadingJobTypeDetail = ref(false)
  const loadingJobDetail = ref(false)
  const loadingArchiveJobType = ref(false)
  const loadingDeleteJobType = ref(false)
  const loadingEditJobType = ref(false)
  const loadingJobPriority = ref(false)

  const jobAutoSuggestPagination = ref({
    limit: 20,
    offset: 0,
  })
  const jobAutoSuggestSearch = ref(null)

  // const pushJobs = list => {
  //   const filteredList = jobList.value.filter(el => !list.some(c => c.id === el.id))

  //   jobList.value = [...filteredList, ...list]
  // }

  const fetchJobs = async () => {
    loadingJob.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobs,
          variables: {
            filter: filter
              ? filter.value
              : {
                  is_archive: jobFilter.value.filter.is_archive,
                  search: jobFilter.value.filter.search,
                },
            job_type_id: typeId,
            workspace_id: workspaceId,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          // jobCount.value = result.data.jobs.count

          // if (fetchMore) {
          //   pushJobs(result.data.jobs.jobs)
          // } else {
          //   jobList.value = result.data.jobs.jobs
          // }

          resolve(data.jobs.jobs)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        })
        .finally(() => {
          loadingJob.value = false
        })
    })
  }

  const newFetchJobs = async ({
    exclude_closed_status,
    include_sub_job,
    advanced_filter,
    job_type_id,
    filter,
  }) => {
    try {
      const { data } = await apolloClient.query({
        query: jobs,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: workspaceId,
          exclude_closed_status,
          include_sub_job,
          advanced_filter,
          job_type_id,
          filter,
        },
      })

      return data.jobs
    } catch (err) {
      errorHandling(err)
      throw err
    }
  }

  // const listenScrollFetchMoreJob = useDebounceFn(async data => {
  //   if (
  //     data.target.offsetHeight + data.target.scrollTop >= data.target.scrollHeight - 400 &&
  //     jobFilter.value.pagination.offset <= jobCount.value
  //   ) {
  //     jobFilter.value.pagination.offset += 20
  //     loadingJobPagination.value = true
  //     await fetchJobs({ fetchMore: true })
  //     loadingJobPagination.value = false
  //   }
  // }, 200)

  const fetchJobsAutoSuggest = async ({ fetchMore = false } = {}) => {
    loadingJob.value = true
    await apolloClient
      .query({
        query: jobs,
        variables: {
          workspace_id: workspaceId,
          pagination: pagination && !fetchMore ? pagination : jobAutoSuggestPagination.value,
          filter: {},
          search: search ? search.value : jobAutoSuggestSearch.value,
        },
        fetchPolicy: 'no-cache',
      })
      .then(() => {
        loadingJob.value = false
        // jobCount.value = result.data.jobs.count
        if (fetchMore) {
          // pushJobs(result.data.jobs.jobs)
        } else {
          // jobList.value = result.data.jobs.jobs
        }
      })
      .catch(err => {
        loadingJob.value = false
        errorHandling(err)
      })
  }

  const listenScrollAutoSuggestFetchMore = useDebounceFn(async () => {
    jobAutoSuggestPagination.value.offset += 20
    loadingFetchMore.value = true
    await fetchJobsAutoSuggest({ fetchMore: true })
    loadingFetchMore.value = false
  }, 750)

  const debouncedFetchJobs = useDebounceFn(() => {
    fetchJobs()
  }, 1000)

  const deleteJob = id =>
    new Promise((resolve, reject) => {
      Vue.$dialog({
        title: 'Hapus job?',
        body: 'Konfirmasi jika anda ingin menghapus job.',
      }).then(confirm => {
        if (confirm) {
          apolloClient
            .mutate({
              mutation: deleteJobMutation,
              variables: {
                id,
                workspace_id: workspaceId,
              },
            })
            .then(result => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menghapus job!',
              })
              resolve(result)
            })
            .catch(err => {
              reject(err)
              errorHandling(err, 'Hapus Job')
            })
        } else {
          reject()
        }
      })
    })

  const getJobDetail = id => {
    loadingJobDetail.value = true

    apolloClient
      .query({
        query: jobDetailQuery,
        variables: {
          job_id: id,
          workspace_id: workspaceId,
        },
      })
      .then(result => {
        jobDetail.value = result.data.jobDetail
        loadingJobDetail.value = false
      })
      .catch(err => {
        loadingJobDetail.value = false
        errorHandling(err)
      })
  }

  const fetchJobPriority = ({ jobTypeId }) => {
    loadingJobPriority.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobPriority,
          variables: {
            workspace_id: workspaceId,
            job_type_id: jobTypeId,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          store.state.job.jobPriority = data.jobPriority
          jobPriorityList.value = data.jobPriority
          loadingJobPriority.value = false
          resolve(data)
        })
        .catch(err => {
          errorHandling(err)
          loadingJobPriority.value = false
          reject(err)
        })
    })
  }

  // Agar JobPriorityList tidak berubah
  const getJobPriority = ({ jobTypeId }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobPriority,
          variables: {
            workspace_id: workspaceId,
            job_type_id: jobTypeId,
          },
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
          errorHandling(err)
          reject(err)
        })
    })
  }

  const getJobTypes = (folderId, filterJobType) => {
    loadingJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobTypes,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: workspaceId,
            folder_id: folderId,
            filter: filterJobType,
          },
        })
        .then(result => {
          loadingJobType.value = false
          store.state.job.jobTypes = result.data.jobTypes
          jobTypeList.value = result.data.jobTypes
          resolve(result)
        })
        .catch(err => {
          loadingJobType.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchJobStatus = ({ jobTypeId }) => {
    loadingJobStatus.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobStatus,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: workspaceId,
            job_type_id: jobTypeId,
          },
        })
        .then(({ data }) => {
          loadingJobStatus.value = false
          store.state.job.jobStatus = data.jobStatus
          jobStatusList.value = data.jobStatus
          resolve(data.jobStatus)
        })
        .catch(err => {
          loadingJobStatus.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }
  const getJobStatus = ({ jobTypeId }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobStatus,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: workspaceId,
            job_type_id: jobTypeId,
          },
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        })
    })
  }

  const changeJobStatus = (jobId, statusId, jobTypeId) => {
    loadingChangeJobStatus.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: proceedJobStatus,
          variables: {
            id: jobId,
            status_id: statusId,
            job_type_id: jobTypeId,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          loadingChangeJobStatus.value = false
          resolve(data)
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah status job!',
          })
        })
        .catch(err => {
          loadingChangeJobStatus.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const fetchJobTypeDetail = jobTypeId => {
    loadingJobTypeDetail.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobTypeDetailQuery,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            job_type_id: jobTypeId,
            exclude_closed_status: state.jobFilter.value.options[2].value,
          },
        })
        .then(({ data }) => {
          loadingJobTypeDetail.value = false
          jobTypeDetail.value = data.jobTypeDetail
          resolve(data.jobTypeDetail)
        })
        .catch(err => {
          loadingJobTypeDetail.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const updateArchiveJobType = jobTypeId => {
    loadingArchiveJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: archiveJobType,
          variables: {
            job_type_id: jobTypeId,
            workspace_id: workspaceId,
          },
        })
        .then(result => {
          loadingArchiveJobType.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengarsipkan job type!',
          })
          resolve(result)
        })
        .catch(err => {
          loadingArchiveJobType.value = false
          reject(err)
          errorHandling(err, 'Archive Job Type')
        })
    })
  }

  const updateUnarchiveJobType = jobTypeId => {
    loadingArchiveJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: unarchiveJobType,
          variables: {
            job_type_id: jobTypeId,
            workspace_id: workspaceId,
          },
        })
        .then(result => {
          loadingArchiveJobType.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil memulihkan job type!',
          })
          resolve(result)
        })
        .catch(err => {
          loadingArchiveJobType.value = false
          reject(err)
          errorHandling(err, 'Unarchive Job Type')
        })
    })
  }

  const changeJob = (id, param) => {
    loadingUpdateJob.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateJob,
          variables: {
            id,
            workspace_id: workspaceId,
            ...param,
          },
        })
        .then(result => {
          loadingUpdateJob.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah job!',
          })
          resolve(result)
        })
        .catch(err => {
          loadingUpdateJob.value = false
          reject(err)
          errorHandling(err, 'Update Job')
        })
    })
  }

  const removeJobType = jobTypeId => {
    loadingDeleteJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: deleteJobType,
          variables: {
            job_type_id: jobTypeId,
            workspace_id: workspaceId,
          },
        })
        .then(result => {
          loadingDeleteJobType.value = false
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menghapus job type!',
          })
          resolve(result)
        })
        .catch(err => {
          loadingDeleteJobType.value = false
          reject(err)
          errorHandling(err, 'Delete Job Type')
        })
    })
  }

  const updateJobTypePermission = async ({ id, isPublic, userPermissions }) => {
    loadingEditJobType.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: updateJobTypePermissionMutation,
          variables: {
            workspace_id: workspaceId,
            job_type_id: id,
            is_public: isPublic,
            user_permissions: userPermissions,
          },
        })
        .then(({ data }) => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil mengubah job type!',
          })
          loadingEditJobType.value = false
          resolve(data.updateJobTypePermission)
        })
        .catch(err => {
          loadingEditJobType.value = false
          reject(err)
          errorHandling(err)
        })
    })
  }

  const convertToJobType = ({ jobId }) =>
    new Promise((resolve, reject) => {
      Vue.$dialog({
        title: 'Konversi Job?',
        body: 'Apakah yakin mengkonversi Job menjadi Job Type? Seluruh nilai atribut dan komen akan terhapus.',
      }).then(confirm => {
        if (confirm) {
          apolloClient
            .mutate({
              mutation: convertToJobTypeMutation,
              variables: {
                job_id: jobId,
                workspace_id: workspaceId,
              },
            })
            .then(({ data }) => {
              resolve(data.convertToJobType)
              Vue.notify({
                title: 'Sukses',
                text: data.convertToJobType.msg,
              })
            })
            .catch(err => {
              reject(err)
              errorHandling(err)
            })
        } else {
          reject()
        }
      })
    })

  const convertToSubJob = ({ jobId, destJobId }) =>
    new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: convertToSubJobMutation,
          variables: {
            job_id: jobId,
            dest_job_id: destJobId,
            workspace_id: workspaceId,
          },
        })
        .then(({ data }) => {
          resolve(data.convertToSubJob)
          Vue.notify({
            title: 'Sukses',
            text: data.convertToSubJob.msg,
          })
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        })
    })

  const convertToJob = ({ jobId, lexorank }) =>
    new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: convertToJobMutation,
          variables: {
            workspace_id: workspaceId,
            job_id: jobId,
            ...(lexorank && { lexorank }),
          },
        })
        .then(({ data }) => {
          resolve(data.convertToJob)
          Vue.notify({
            title: 'Sukses',
            text: data.convertToJob.msg,
          })
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        })
    })

  const fetchJobsAssignedUser = (pagination, user_id, tabFor, sort) =>
    new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: jobsAssignedUser,
          fetchPolicy: 'no-cache',
          variables: {
            pagination: pagination,
            filter: {
              tab_for: tabFor,
              sort: sort,
            },
            workspaceId: store.getters.getCurrentWorkspaceId,
            userId: user_id,
          },
        })
        .then(({ data }) => {
          resolve({ jobs: data.jobsAssignedUser.jobs, count: data.jobsAssignedUser.count })
        })
        .catch(err => {
          errorHandling(err)
          reject(err)
        })
    })

  const updateRelationJobAndDocument = (jobId, documentUuids) =>
    new Promise((resolve, reject) =>
      apolloClient
        .query({
          query: updateRelationJobAndDocumentMutation,
          fetchPolicy: 'no-cache',
          variables: {
            jobId: jobId,
            documentUuids: documentUuids,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          Vue.notify({
            title: 'Sukses',
            text: 'Merelasikan Document ke Job',
          })

          resolve(data)
        })
        .catch(err => {
          errorHandling(err)
          reject(err)
        }),
    )

  return {
    newFetchJobs,

    jobGroup,
    jobDetail,
    jobFilter,
    jobSortOptions,

    jobAutoSuggestPagination,
    jobAutoSuggestSearch,

    jobTypeList,
    jobTypeDetail,
    jobStatusList,
    jobPriorityList,

    loadingJob,
    loadingJobPagination,
    loadingJobDetail,
    loadingEditJobType,
    loadingJobPriority,
    loadingUpdateJob,
    loadingJobStatus,
    loadingChangeJobStatus,
    loadingDeleteJobType,
    loadingJobTypeDetail,
    loadingJobType,
    loadingArchiveJobType,

    fetchJobs,
    getJobTypes,
    fetchJobStatus,
    getJobStatus,
    getJobDetail,
    fetchJobPriority,
    getJobPriority,
    fetchJobTypeDetail,
    fetchJobsAutoSuggest,
    debouncedFetchJobs,

    listenScrollAutoSuggestFetchMore,

    changeJob,
    changeJobStatus,
    updateArchiveJobType,
    updateUnarchiveJobType,
    updateJobTypePermission,
    convertToJob,
    convertToSubJob,
    convertToJobType,
    deleteJob,
    removeJobType,

    fetchJobsAssignedUser,

    updateRelationJobAndDocument,
  }
}

export default useJob
