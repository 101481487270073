<template>
  <v-dialog
    class="automation-dialog"
    v-model="isOpen"
    max-width="1500"
    scrollable
    :persistent="isCreating || isEditing"
    @click:outside="handleCloseAutomation()"
  >
    <v-card height="90vh">
      <v-card-title class="d-flex justify-space-between py-4 px-6">
        <span class="text-h6 font-bold"> Automations </span>
        <v-icon
          v-if="!isCreating && !isEditing"
          style="width: 32px; height: 32px"
          @click="handleCloseAutomation()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text class="d-flex flex-column py-4 px-6 overflow-hidden">
        <v-row
          dense
          no-gutters
        >
          <v-tabs
            style="box-shadow: none !important"
            v-model="tabs"
          >
            <v-tab
              :class="`${!isCreating && !isEditing ? 'ml-auto ' : 'd-none'}`"
              style="width: 100%"
              key="0"
            >
              <span>Manage</span>
            </v-tab>

            <v-tab
              :class="`${!isCreating && !isEditing ? 'mr-auto' : 'd-none'}`"
              style="width: 100%"
              key="1"
            >
              <span>History</span>
            </v-tab>

            <v-tab
              :class="`${isCreating ? 'mx-auto' : 'd-none'}`"
              style="width: 100%"
              key="2"
            >
              <span>Create Automation</span>
            </v-tab>

            <v-tab
              :class="`${isEditing ? 'mx-auto' : 'd-none'}`"
              style="width: 100%"
              key="3"
            >
              <span>Edit Automation</span>
            </v-tab>
          </v-tabs>
        </v-row>

        <v-row
          class="pb-4 px-1"
          no-gutters
          dense
        >
          <v-divider />
        </v-row>

        <v-row
          class="align-center pb-4 px-1"
          no-gutters
          dense
        >
          <template v-if="!isCreating && !isEditing">
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="folderDetail"
                class="text-truncate mr-0 mr-md-2 mb-2 mb-md-0"
                solo
                flat
                dense
                outlined
                return-object
                auto-select-first
                item-value="id"
                item-text="name"
                hide-details="auto"
                placeholder="Pilih Folder"
                :rules="[required]"
                :items="folderList"
              >
                <template #selection="{ item }">
                  <div class="d-flex align-center py-3 px-1 overflow-hidden">
                    <v-icon
                      :color="item.color"
                      class="mx-1"
                      size="18"
                    >
                      {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>

                <template #item="{ item }">
                  <div class="d-flex align-center py-3 px-1 w-full">
                    <v-icon
                      :color="item.color"
                      class="mx-1"
                      size="18"
                    >
                      {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="jobTypeDetail"
                class="text-truncate mb-2 mb-md-0"
                solo
                flat
                dense
                outlined
                return-object
                auto-select-first
                height="100%"
                item-value="id"
                item-text="name"
                hide-details="auto"
                placeholder="Pilih Job Type"
                :items="jobTypeList"
              >
                <template #selection="{ item }">
                  <div class="d-flex align-center py-3 px-1 overflow-hidden">
                    <v-badge
                      class="mr-1"
                      dot
                      inline
                      :color="item.color"
                    />

                    <v-icon
                      v-if="!item.is_public"
                      size="14"
                      class="mr-1"
                    >
                      {{ icons.mdiLockOutline }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>

                <template #item="{ item }">
                  <div class="d-flex align-center py-3 px-1 w-full">
                    <v-badge
                      class="mr-1"
                      dot
                      inline
                      :color="item.color"
                    />

                    <v-icon
                      v-if="!item.is_public"
                      size="14"
                      class="mr-1"
                    >
                      {{ icons.mdiLockOutline }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </template>

          <template v-else>
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                class="px-3 mb-2 mb-md-0"
                text
                outlined
                depressed
                style="border-radius: 4px"
                @click="handleAutomationFormClose()"
              >
                <v-icon
                  class="mr-1"
                  size="26"
                >
                  {{ icons.mdiArrowLeftThin }}
                </v-icon>

                <h3 class="font-medium pr-2">Cancel</h3>
              </v-btn>
            </v-col>
          </template>

          <v-spacer />

          <template v-if="tabs === 0">
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                depressed
                color="primary"
                style="border-radius: 5px; width: 100%"
                type="sumbit"
                @click="handleOpenCreateAutomation()"
              >
                <h3
                  class="font-medium pl-2"
                  style="color: white !important"
                >
                  Add Automation
                </h3>
              </v-btn>
            </v-col>
          </template>

          <template v-else-if="isCreating">
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                depressed
                color="primary"
                style="border-radius: 5px; width: 100%"
                type="sumbit"
                @click="createForm.handleCreateAutomation()"
              >
                <h3
                  class="font-medium pl-2"
                  style="color: white !important"
                >
                  Create Automation
                </h3>
              </v-btn>
            </v-col>
          </template>

          <template v-else-if="isEditing">
            <v-col
              class="pr-md-1"
              cols="12"
              md="auto"
            >
              <v-btn
                depressed
                text
                outlined
                style="border-radius: 5px; width: 100%"
                @click="editForm.hadnleDeleteAutomation()"
              >
                <h3
                  class="font-medium pl-2 error--textg"
                  style="color: white !important"
                >
                  Delete Automation
                </h3>
              </v-btn>
            </v-col>
            <v-col
              class="pl-md-1"
              cols="12"
              md="auto"
            >
              <v-btn
                depressed
                color="primary"
                style="border-radius: 5px; width: 100%"
                @click="editForm.handleUpdateAutomation()"
              >
                <h3
                  class="font-medium pl-2"
                  style="color: white !important"
                >
                  Save Automation
                </h3>
              </v-btn>
            </v-col>
          </template>
        </v-row>

        <v-row
          class="h-full overflow-auto"
          dense
        >
          <v-tabs-items
            class="w-full h-full"
            v-model="tabs"
          >
            <v-tab-item
              class="overflow-auto px-1"
              style="max-height: 100%; margin: 0 !important"
              v-scroll.self="handleScroll"
            >
              <div class="d-flex flex-column">
                <template v-if="automationList?.length > 0">
                  <v-card
                    v-for="(detail, index) in automationList"
                    :key="`automation_list--item-${index}`"
                    class="mb-3"
                    outlined
                    @click="handleOpenEditAutomation(detail)"
                  >
                    <v-card-title class="d-flex justify-space-between pt-4 pb-0 px-5">
                      <h6 class="text-h6 font-weight-semibold">{{ detail.name }}</h6>

                      <div class="d-flex align-center">
                        <v-switch
                          v-model="detail.is_active"
                          class="mx-1 mt-0 pt-0"
                          hide-details
                          inset
                          @click="handleToggleAutomationStatus(detail.id, detail.is_active)"
                          @click.stop
                        ></v-switch>
                      </div>
                    </v-card-title>

                    <v-card-text class="d-flex flex-column pt-2 pb-4 px-5">
                      <p
                        class="mb-0 text-subtitle-2"
                        style="max-width: 85%"
                      >
                        {{ detail.description }}
                      </p>
                    </v-card-text>
                  </v-card>
                </template>

                <div
                  v-else
                  class="d-flex align-center"
                  style="min-height: 500px"
                >
                  <h6 class="w-full text-h6 text-center">Belum ada Automation yang dibuat</h6>
                </div>
              </div>
            </v-tab-item>

            <AutomationHistory v-if="!isLoading" />

            <AutomationForm
              v-if="!isLoading"
              ref="createForm"
              :isCreating="isCreating"
            />

            <AutomationForm
              v-if="!isLoading"
              ref="editForm"
              :isEditing="isEditing"
              :id="selectedAutomation"
            />
          </v-tabs-items>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useRouter } from '@/@core/utils'
import { avatarText } from '@/@core/utils/filter'
import { required } from '@/@core/utils/validation'
import useAutomation from '@/composables/useAutomationV3'
import useFolder from '@/composables/useFolder'
import { getJobTypeDetail, getJobTypes } from '@/services/jobTypeService'
import store from '@/store'
import {
  mdiArrowLeftThin,
  mdiClose,
  mdiCogOutline,
  mdiFolder,
  mdiFolderLock,
  mdiLockOutline,
} from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import Vue, { computed, onMounted, onUnmounted, provide, ref, watch } from 'vue'
import { createFieldMapper } from 'vuex-use-fields'
import AutomationForm from './AutomationForm.vue'
import AutomationHistory from './AutomationHistory.vue'

export default {
  components: {
    AutomationForm,
    AutomationHistory,
  },

  setup() {
    const { fetchFolderDetail } = useFolder()
    const { router } = useRouter()
    const { fetchAutomations, toggleStatusAutomation } = useAutomation()

    const tabs = ref(0)
    const isOpen = ref(false)
    const isLoading = ref(true)
    const isCreating = ref(false)
    const isEditing = ref(false)
    const historyView = ref(null)
    const createForm = ref(null)
    const editForm = ref(null)
    const selectedAutomation = ref()
    const pagination = ref({
      limit: 10,
      offset: 0,
    })

    const automationList = ref([])
    const automationCount = ref()
    const automationFilter = ref({
      my_automation: null,
      name: null,
      show_active: 'ALL',
      sort: 'created_at',
      sortType: 'DESC',
    })
    const folderDetail = ref()
    const { folderList } = createFieldMapper({
      getter: 'folder/getField',
      setter: 'folder/setField',
    })(['folderList'])
    const jobTypeDetail = ref()
    const jobTypeList = ref([])
    const usersList = computed(() => store.state.user.users)

    provide('tabs', tabs)
    provide('folderDetail', folderDetail)
    provide('folderList', folderList)
    provide('jobTypeDetail', jobTypeDetail)
    provide('jobTypeList', jobTypeList)
    provide('usersList', usersList)

    const handleCloseAutomation = () => {
      isLoading.value = true
      isOpen.value = false
      pagination.value.offset = 0
      tabs.value = 0
    }
    const handleAutomationFormClose = () => {
      createForm.value.setAutomationEmpty()
      editForm.value.setAutomationEmpty()

      selectedAutomation.value = undefined
      tabs.value = 0
    }

    const handleOpenCreateAutomation = () => {
      isCreating.value = true
      tabs.value = 2
    }
    const handleOpenEditAutomation = automation => {
      selectedAutomation.value = automation.id
      isEditing.value = true
      tabs.value = 3
    }

    const handleScroll = useDebounceFn(async data => {
      if (automationList.value.length >= automationCount.value) return

      if (
        data.target.offsetHeight + data.target.scrollTop >= data.target.scrollHeight - 500 &&
        automationList.value.length < automationCount.value
      ) {
        await fetchAutomations(
          folderDetail.value.id,
          jobTypeDetail.value.id,
          automationFilter.value,
          pagination.value,
        ).then(({ data }) => {
          automationList.value.push(...data)
          pagination.value.offset = automationList.value.length
        })
      }
    }, 200)

    const handleToggleAutomationStatus = (id, value) => toggleStatusAutomation(id, value)

    const handleFetchAutomations = async () =>
      await fetchAutomations(
        folderDetail.value.id,
        jobTypeDetail.value.id,
        automationFilter.value,
        pagination.value,
      ).then(({ count, data }) => {
        automationList.value = data
        automationCount.value = count
        pagination.value.offset = automationList.value.length
      })

    onMounted(() => {
      Vue.$openAutomation = async (folderId, jobTypeId) => {
        isLoading.value = true
        isOpen.value = true

        if (usersList.value?.length === 0) store.dispatch('user/getUsers')

        if (folderDetail.value?.id != folderId)
          await fetchFolderDetail(folderId).then(data => (folderDetail.value = data))

        if (jobTypeList.value?.length === 0)
          jobTypeList.value = await getJobTypes(parseInt(folderId), {
            search: '',
            my_jobType: null,
            show_archived: 'IS NOT SET',
            sort: 'name',
            sortType: 'ASC',
          })

        if (jobTypeId) jobTypeDetail.value = await getJobTypeDetail({ jobTypeId: jobTypeId })
        else if (folderId && !jobTypeId)
          jobTypeDetail.value = await getJobTypeDetail({
            jobTypeId: jobTypeList.value[0]?.id,
          })

        await handleFetchAutomations()

        isLoading.value = false
      }
    })

    onUnmounted(() => {
      pagination.value.offset = 0
    })

    watch(tabs, (newVal, oldVal) => {
      if (tabs.value === 0) {
        isCreating.value = false
        isEditing.value = false
      }

      if ([2, 3].includes(oldVal) && newVal === 0) {
        pagination.value = {
          limit: 10,
          offset: 0,
        }

        handleFetchAutomations()
      }
    })

    watch(folderDetail, async (newFolder, oldFolder) => {
      jobTypeList.value = await getJobTypes(parseInt(folderDetail.value.id), {
        search: '',
        my_jobType: null,
        show_archived: 'IS NOT SET',
        sort: 'name',
        sortType: 'ASC',
      })

      if (jobTypeDetail.value === undefined || newFolder.id !== oldFolder.id)
        jobTypeDetail.value = jobTypeList.value[0]
    })

    watch(jobTypeDetail, async (newDetail, oldDetail) => {
      if (isLoading.value) return

      if (newDetail?.id === oldDetail?.id) return

      if (oldDetail?.id !== newDetail?.id)
        jobTypeDetail.value = await getJobTypeDetail({ jobTypeId: newDetail.id })

      automationList.value = []
      automationCount.value = undefined
      pagination.value = {
        limit: 10,
        offset: 0,
      }
      await fetchAutomations(
        folderDetail.value.id,
        jobTypeDetail.value.id,
        automationFilter.value,
        pagination.value,
      ).then(({ count, data }) => {
        automationList.value = data
        automationCount.value = count
        pagination.value.offset = automationList.value.length
      })
    })

    return {
      tabs,
      isOpen,
      isLoading,
      isEditing,
      isCreating,
      historyView,
      createForm,
      editForm,
      selectedAutomation,

      automationList,
      folderDetail,
      folderList,
      jobTypeDetail,
      jobTypeList,

      handleCloseAutomation,
      handleAutomationFormClose,
      handleOpenCreateAutomation,
      handleOpenEditAutomation,
      handleScroll,
      handleToggleAutomationStatus,

      store,
      router,
      required,
      avatarText,

      icons: {
        mdiClose,
        mdiFolder,
        mdiFolderLock,
        mdiLockOutline,
        mdiArrowLeftThin,
        mdiCogOutline,
      },
    }
  },
}
</script>

<style></style>
