var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-slide-y-transition',[_c('v-row',[_c('v-col',{staticClass:"ma-0 mt-0 pt-0 pb-0",attrs:{"cols":"12","md":"6","lg":"6","xs":"12","sm":"12"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"dense":"","outlined":"","hide-details":"","hint":"Cari pengguna...","label":"Filter Berdasarkan Nama"},on:{"input":_vm.debouncedFetchWorkspaceMember},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")])],1)]},proxy:true}]),model:{value:(_vm.state.filter.value.search),callback:function ($$v) {_vm.$set(_vm.state.filter.value, "search", $$v)},expression:"state.filter.value.search"}})],1),_c('v-col',{staticClass:"ml-auto mr-0 mb-0 ma-0 mt-6 pt-0 pb-0",attrs:{"cols":"12","md":"3","lg":"3","xs":"12","sm":"12"}},[_c('FilterMenu',{attrs:{"workspace":_vm.workspace}})],1),_c('v-col',{staticClass:"ml-0 mr-0 mb-0 ma-0 mt-6 pt-0",attrs:{"cols":"12","md":"3","lg":"3","sm":"12","xs":"12"}},[_c('SortButton',{attrs:{"sort-options":_vm.sortOptions,"sort-select":_vm.state.filter.value.sortSelect,"sort-count":_vm.state.sortCount.value,"is-hover":_vm.True,"is-asc":_vm.state.filter.value.isASC},on:{"addSortir":_vm.addSortir}})],1)],1)],1),_c('v-data-iterator',{attrs:{"items":_vm.state.memberList.value,"items-per-page":10,"show-select":"","disable-sort":"","footer-props":{
      'items-per-page-options': [10, 20, 30],
      'items-per-page-text': 'Jumlah data',
    },"server-items-length":_vm.state.memberCount.value,"mobile-breakpoint":"0","loading":_vm.state.loadingMember.value,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('PerfectScrollbar',{staticClass:"ps-user-workspace mt-2 pa-2",attrs:{"options":{ suppressScrollX: true, useBothWheelAxes: false }}},[_c('v-row',_vm._l((props.items),function(member,index){return _c('v-col',{key:index,staticClass:"pa-1",attrs:{"cols":"12","lg":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex pa-3 justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{attrs:{"size":"35"}},[(member.user.photo)?_c('v-img',{staticClass:"rounded",attrs:{"src":_vm.getUrl(member.user.id, member.user.photo)}}):(_vm.avatarText)?_c('v-avatar',{staticClass:"primary",on:{"click":_vm.on}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.avatarText(`${member.user.first_name} ${member.user.last_name}`))+" ")])]):_vm._e()],1),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mb-0 mr-2 body-2 font-weight-semibold d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(member.user ? member.user.name : member.email)+" ")]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(member.user && _vm.userLogin.id === member.user.id),expression:"member.user && userLogin.id === member.user.id"}],attrs:{"x-small":"","color":"primary"}},[_vm._v(" Anda ")])],1),_c('span',{staticClass:"text-caption text--disabled"},[_vm._v(" "+_vm._s(member.user ? member.user.email : member.email)+" ")]),_c('v-select',{staticClass:"mt-1",staticStyle:{"max-width":"200px"},attrs:{"dense":"","items":_vm.workspace.owner.id !== _vm.userLogin.id && member.role.id !== 1
                          ? _vm.roleList.slice(1)
                          : _vm.roleList,"value":member.role.id,"disabled":(member.role.id === 1 && _vm.workspace.owner.id !== _vm.userLogin.id) ||
                        member.user.id === _vm.userLogin.id ||
                        _vm.workspace.owner.id === member.user.id,"item-value":"id","item-text":"name","return-object":"","hide-details":"auto"},on:{"change":data => _vm.updateUserRole(member, data)},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex align-center"},[(member.is_pending)?_c('div',[_c('v-chip',{attrs:{"small":"","color":"warning","outlined":"","label":""}},[_vm._v(" Pending ")])],1):_vm._e(),(
                      member.user &&
                      _vm.userLogin.id !== member.user.id &&
                      member.role.id > 2 &&
                      _vm.userLoginRole.id <= 1
                    )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._b({attrs:{"plain":"","icon":""},on:{"click":on}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"error--text",on:{"click":function($event){return _vm.removeUser(member.user)}}},[_vm._v(" Keluarkan dari Workspace ")])],1)],1)],1):_vm._e()],1)])])],1)}),1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }