import gql from 'graphql-tag'

export const deleteJob = gql`
  mutation ($id: Float!, $workspace_id: Float!) {
    deleteJob(id: $id, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
