import { find } from 'linkifyjs'

export const editHyperlinkHandler = options => {
  const { href, innerText } = options.link
  const theme = localStorage.getItem('oriens-active-theme')

  // Create the main container
  const mainContainer = document.createElement('div')
  mainContainer.classList.add('elevation-1', 'v-card', 'v-sheet', `theme--${theme}`)
  mainContainer.style.width = '300px'

  // Create the inner container
  const innerContainer = document.createElement('div')
  innerContainer.classList.add('v-card__text', 'pa-3')
  mainContainer.appendChild(innerContainer)

  // Create the form
  const form = document.createElement('form')
  form.setAttribute('novalidate', 'novalidate')
  form.classList.add('v-form')
  form.style.marginBottom = '10px'
  innerContainer.appendChild(form)

  // Create the flex column container
  const flexColumn = document.createElement('div')
  flexColumn.classList.add('d-flex', 'flex-column')
  form.appendChild(flexColumn)

  // Create the Link Title label
  const linkTitleLabel = document.createElement('span')
  linkTitleLabel.classList.add('my-1')
  linkTitleLabel.textContent = 'Link Title'
  flexColumn.appendChild(linkTitleLabel)

  // Create the first input container
  const inputContainer1 = document.createElement('div')
  inputContainer1.classList.add(
    'v-input',
    'v-input--hide-details',
    'v-input--dense',
    `theme--${theme}`,
    'v-text-field',
    'v-text-field--is-booted',
    'v-text-field--enclosed',
    'v-text-field--outlined',
    'v-text-field--placeholder',
  )
  flexColumn.appendChild(inputContainer1)

  // Create the first input control
  const inputControl1 = document.createElement('div')
  inputControl1.classList.add('v-input__control')
  inputContainer1.appendChild(inputControl1)

  // Create the first input slot
  const inputSlot1 = document.createElement('div')
  inputSlot1.classList.add('v-input__slot')
  inputControl1.appendChild(inputSlot1)

  // Create the first fieldset
  const fieldset1 = document.createElement('fieldset')
  fieldset1.setAttribute('aria-hidden', 'true')
  inputSlot1.appendChild(fieldset1)

  // Create the first legend
  const legend1 = document.createElement('legend')
  legend1.style.width = '0px'
  fieldset1.appendChild(legend1)

  // Add the zero-width space span
  const zeroWidthSpace1 = document.createElement('span')
  zeroWidthSpace1.classList.add('notranslate')
  zeroWidthSpace1.innerHTML = '&ZeroWidthSpace;'
  legend1.appendChild(zeroWidthSpace1)

  // Create the first input element
  const inputTitle = document.createElement('input')
  inputTitle.id = 'input-1051'
  inputTitle.setAttribute('placeholder', 'Input Title')
  inputTitle.type = 'text'
  inputTitle.value = innerText
  inputSlot1.appendChild(inputTitle)

  // Create the URL label
  const urlLabel = document.createElement('span')
  urlLabel.classList.add('mb-1')
  urlLabel.textContent = 'URL'
  flexColumn.appendChild(urlLabel)

  // Create the second input container
  const inputContainer2 = document.createElement('div')
  inputContainer2.classList.add(
    'v-input',
    'mb-2',
    'v-input--hide-details',
    'v-input--dense',
    `theme--${theme}`,
    'v-text-field',
    'v-text-field--is-booted',
    'v-text-field--enclosed',
    'v-text-field--outlined',
    'v-text-field--placeholder',
  )
  flexColumn.appendChild(inputContainer2)

  // Create the second input control
  const inputControl2 = document.createElement('div')
  inputControl2.classList.add('v-input__control')
  inputContainer2.appendChild(inputControl2)

  // Create the second input slot
  const inputSlot2 = document.createElement('div')
  inputSlot2.classList.add('v-input__slot')
  inputControl2.appendChild(inputSlot2)

  // Create the second fieldset
  const fieldset2 = document.createElement('fieldset')
  fieldset2.setAttribute('aria-hidden', 'true')
  inputSlot2.appendChild(fieldset2)

  // Create the second legend
  const legend2 = document.createElement('legend')
  legend2.style.width = '0px'
  fieldset2.appendChild(legend2)

  // Add the zero-width space span
  const zeroWidthSpace2 = document.createElement('span')
  zeroWidthSpace2.classList.add('notranslate')
  zeroWidthSpace2.innerHTML = '&ZeroWidthSpace;'
  legend2.appendChild(zeroWidthSpace2)

  // Create the second input element
  const inputLink = document.createElement('input')
  inputLink.id = 'input-1052'
  inputLink.setAttribute('placeholder', 'Input Link')
  inputLink.type = 'text'
  inputLink.value = href
  inputSlot2.appendChild(inputLink)

  // Create the Confirm button
  const confirmButton = document.createElement('button')
  confirmButton.type = 'submit'
  confirmButton.classList.add(
    'v-btn',
    'v-btn--block',
    'v-btn--is-elevated',
    'v-btn--has-bg',
    `theme--${theme}`,
    'v-size--default',
    'primary',
  )
  const confirmButtonContent = document.createElement('span')
  confirmButtonContent.classList.add('v-btn__content')
  confirmButtonContent.textContent = ' Confirm '
  confirmButton.appendChild(confirmButtonContent)
  flexColumn.appendChild(confirmButton)

  // eslint-disable-next-line no-param-reassign
  options.mainContainer.innerHTML = ''
  options.mainContainer.appendChild(mainContainer)

  inputLink.addEventListener('keydown', () => {
    inputLink.style.outlineColor = ' #dadce0'
  })

  inputLink.addEventListener('keydown', () => {
    inputTitle.style.outlineColor = ' #dadce0'
  })

  // Handle form submission
  form.addEventListener('submit', e => {
    e.preventDefault()

    const newLinkText = inputTitle.value
    const newHref = inputLink.value

    if (!newHref || !newLinkText) {
      if (!newHref) inputLink.style.outlineColor = 'red'
      if (!newLinkText) inputTitle.style.outlineColor = 'red'

      return
    }

    const sanitizeURL = find(newHref)
      .filter(link => link.isLink)
      .filter(link => {
        if (options.validate) {
          return options.validate(link.value)
        }

        return true
      })
      .at(0)

    if (!sanitizeURL?.href) {
      inputLink.style.outlineColor = 'red'

      return
    }

    if (options.nodePos) {
      options.editor
        .chain()
        .setTextSelection(options.nodePos)
        .editHyperlink({
          nodePos: options.nodePos ?? null,
          newURL: newHref,
          newText: newLinkText,
        })
        .setTextSelection(options.nodePos)

      options.tippy.hide()

      return
    }
    options.editor.chain().focus().extendMarkRange('hyperlink').editHyperlink({
      newURL: newHref,
      newText: newLinkText,
    })

    options.tippy.hide()
  })

  // Show tooltip
  options.tippy.update(options.editor.view, options.nodePos)

  setTimeout(() => inputTitle.focus())
}
