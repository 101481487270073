import { getAttributes } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import Tooltip from './tippyHelper'

const hoverHyperlinkPluginKey = new PluginKey('handleHoverHyperlink')

export default function hoverHandlerHyperlink(options) {
  const tooltip = new Tooltip(options)
  let hideTimeout

  const { tippyModal } = tooltip.init()

  return new Plugin({
    key: hoverHyperlinkPluginKey,
    props: {
      handleDOMEvents: {
        mouseover(view, event) {
          if (event.button !== 0) return false
          const nodeTarget = event.target
          const link = nodeTarget?.closest('a')

          if (
            link &&
            nodeTarget.tagName !== 'DIV' &&
            event.target.pmViewDesc?.mark?.type.name === 'hyperlink'
          ) {
            hideTimeout = setTimeout(() => {
              const nodePos = view.posAtDOM(nodeTarget, 0)

              // Find the closest link element to the target element

              // Extract attributes from the state
              const attrs = getAttributes(view.state, options.type.name)

              // Extract href and target attributes from the link element or the state
              const href = link?.href ?? attrs.href
              const target = link?.target ?? attrs.target

              // If there is no previewHyperlink modal provided, then open the link in new window
              if (!options.modal) {
                if (link && href) {
                  window.open(href, target)
                }

                return true
              }

              // if the link does not contain href attribute, hide the tooltip
              if (!link?.href) return tooltip.hide()

              // Create a preview of the hyperlink
              const hyperlinkPreview = options.modal({
                link,
                nodePos,
                isClickModal: false,
                tippy: tooltip,
                ...options,
              })

              // If there is no hyperlink preview, hide the modal
              if (!hyperlinkPreview) return tooltip.hide()

              // Empty the modal and append the hyperlink preview box

              while (tippyModal.firstChild) {
                tippyModal.removeChild(tippyModal.firstChild)
              }

              tippyModal.append(hyperlinkPreview)

              // Update the modal position
              tooltip.update(options.editor.view, nodePos)
            }, 700)
          }

          return false
        },
        mouseleave(view, event) {
          const { relatedTarget } = event
          if (!relatedTarget || !tippyModal?.contains(relatedTarget)) {
            setTimeout(() => {
              tooltip.hide()
            }, 500)
          }
        },
        mouseout() {
          clearTimeout(hideTimeout)
        },
      },
    },
  })
}
