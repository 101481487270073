<template>
  <v-card
    v-if="
      !['table', 'title', 'callout', 'blockquote', 'codeBlock', 'taskList'].includes(
        topLevelNodeType,
      )
    "
    id="popup"
    ref="itemsContainer"
    max-height="300"
    elevation="4"
    class="slash-command-list"
  >
    <v-list
      dense
      class="pt-1"
      style="outline: none"
    >
      <v-subheader class="ms-2"> Choose Block </v-subheader>
      <template v-if="filteredBlockTools.length">
        <v-list-item
          v-for="(item, index) in filteredBlockTools"
          :key="index"
          :input-value="index === selectedIndex"
          @click="item.command({ editor, range: instance.range })"
        >
          <v-list-item-avatar
            tile
            size="30"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.desc }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <div v-else>
        <span class="mx-4">No items found</span>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import { ref, computed } from 'vue'
import blockTools from './blockTools'

export default {
  props: {
    editor: {
      type: Object,
      required: true,
    },
    instance: {
      type: Object,
      required: true,
    },
    topLevelNodeType: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const selectedIndex = ref(0)
    const itemsContainer = ref(null)
    const filteredBlockTools = computed(() =>
      blockTools.filter(tool => {
        selectedIndex.value = 0
        if (!props.instance.query) {
          return true
        }

        return tool.title.toLowerCase().startsWith(props.instance.query.toLowerCase())
      }),
    )

    const scrollIfNeeded = () => {
      const element = itemsContainer.value.$el

      if (element) {
        const itemHeight = 50
        const containerHeight = element.clientHeight - 50
        const scrollPosition = element.scrollTop

        const scrollToIndex = selectedIndex.value

        if (scrollToIndex * itemHeight < scrollPosition) {
          // Scroll up
          element.scrollTop = scrollToIndex * itemHeight
        } else if ((scrollToIndex + 1) * itemHeight > scrollPosition + containerHeight) {
          // Scroll down
          element.scrollTop = (scrollToIndex + 1) * itemHeight - containerHeight
        }
      }
    }

    const moveDown = () => {
      if (
        selectedIndex.value === null ||
        selectedIndex.value === filteredBlockTools.value.length - 1
      )
        return
      selectedIndex.value += 1
      scrollIfNeeded()
    }

    const moveUp = () => {
      if (selectedIndex.value === null || selectedIndex.value === 0) return
      selectedIndex.value -= 1
      scrollIfNeeded()
    }

    const handleKeyDown = event => {
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        moveDown()
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        moveUp()
      } else if (event.key === 'Enter') {
        event.preventDefault()
        if (filteredBlockTools.value[selectedIndex.value]) {
          filteredBlockTools.value[selectedIndex.value].command({
            editor: props.editor,
            range: props.instance.range,
          })
        }
      } else if (event.key === 'Escape') {
        event.preventDefault()
        emit('close')
      }
    }

    return {
      itemsContainer,
      handleKeyDown,
      selectedIndex,
      filteredBlockTools,
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.slash-command-list {
  @include style-scroll-bar();
  overflow-y: auto;
}
</style>
