<template>
  <v-menu
    ref="alignMenu"
    offset-y
    open-on-hover
  >
    <template #activator="menu">
      <v-tooltip
        top
        :open-on-hover="false"
        :open-on-click="false"
        :value="$refs.alignMenu && $refs.alignMenu.isActive"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="px-0 mx-0"
            elevation="0"
            min-width="48"
            max-width="48"
            tile
            v-bind="{ ...menu.attrs, ...attrs }"
            v-on="{ ...menu.on, ...on }"
          >
            <v-icon size="26">
              {{ icons.mdiFormatAlignJustify }}
            </v-icon>
          </v-btn>
        </template>
        Format Align
      </v-tooltip>
    </template>
    <v-list
      dense
      class="pt-1"
    >
      <v-subheader class="ms-2"> Format Align </v-subheader>
      <div
        v-for="tool in alignTools"
        :key="tool.label"
      >
        <v-list-item
          :input-value="tool.active"
          color="primary"
          @click="tool.command"
        >
          <v-list-item-avatar
            tile
            size="24"
          >
            <v-icon>
              {{ tool.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ tool.label }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ tool.shortcut }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { computed } from 'vue'
import {
  mdiFormatAlignLeft,
  mdiFormatAlignCenter,
  mdiFormatAlignRight,
  mdiFormatAlignJustify,
} from '@mdi/js'

export default {
  props: {
    editor: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const alignTools = computed(() => [
      {
        label: 'Left',
        icon: mdiFormatAlignLeft,
        shortcut: 'Ctrl+Shift+L',
        active: props.editor.isActive({ textAlign: 'left' }),
        command: () => props.editor.chain().focus().setTextAlign('left').blur().run(),
      },
      {
        label: 'Center',
        icon: mdiFormatAlignCenter,
        shortcut: 'Ctrl+Shift+E',
        active: props.editor.isActive({ textAlign: 'center' }),
        command: () => props.editor.chain().focus().setTextAlign('center').blur().run(),
      },
      {
        label: 'Right',
        icon: mdiFormatAlignRight,
        shortcut: 'Ctrl+Shift+R',
        active: props.editor.isActive({ textAlign: 'right' }),
        command: () => props.editor.chain().focus().setTextAlign('right').blur().run(),
      },
      {
        label: 'Justify',
        icon: mdiFormatAlignJustify,
        shortcut: 'Ctrl+Shift+J',
        active: props.editor.isActive({ textAlign: 'justify' }),
        command: () => props.editor.chain().focus().setTextAlign('justify').blur().run(),
      },
    ])

    return {
      alignTools,

      icons: {
        mdiFormatAlignJustify,
      },
    }
  },
}
</script>
