import {
  Node,
  findChildren,
  findParentNode,
  mergeAttributes,
  wrappingInputRule,
} from '@tiptap/core'

import { setAttributes } from './utils/editor'

export const Details = Node.create({
  name: 'details',
  group: 'block',
  content: 'detailsSummary detailsContent',
  defining: true,
  isolating: true,
  allowGapCursor: false,
  addOptions() {
    return {
      HTMLAttributes: {},
      dictionary: {
        name: 'Details',
      },
    }
  },
  addStorage() {
    return {
      markdown: {
        parser: {
          match: node => node.type === 'containerDirective' && node.name === this.name,
          apply: (state, node, type) => {
            state.openNode(type, node.attributes).next(node.children).closeNode()
          },
        },
        serializer: {
          match: node => node.type.name === this.name,
          apply: (state, node) => {
            state
              .openNode({
                type: 'containerDirective',
                name: this.name,
                attributes: node.attrs,
              })
              .next(node.content)
              .closeNode()
          },
        },
      },
      blockMenu: {
        items: [
          {
            id: this.name,
            name: this.options.dictionary.name,
            icon: `<span class="ProseMirror-icon">➤</span>`,
            shortcut: 'Mod-Alt-D',
            keywords: 'details,zdnl',
            action: editor => editor.chain().toggleDetails().focus().run(),
          },
        ],
      },
    }
  },
  addAttributes() {
    return {
      open: {
        default: false,
        parseHTML: e => e.getAttribute('open'),
        renderHTML: a => (a.open ? { open: '' } : {}),
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'details',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['details', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addNodeView() {
    return ({ node, editor, getPos }) => {
      const dom = document.createElement('div')
      const btn = document.createElement('button')
      const ico = document.createElement('div')
      const div = document.createElement('div')

      for (const [key, value] of Object.entries(mergeAttributes(this.options.HTMLAttributes))) {
        if (value !== undefined && value !== null) {
          dom.setAttribute(key, value)
        }
      }
      dom.setAttribute('data-type', this.name)
      btn.setAttribute('data-type', `${this.name}Button`)
      div.setAttribute('data-type', `${this.name}Container`)
      if (node.attrs.open) {
        dom.setAttribute('open', 'true')
      } else {
        dom.removeAttribute('open')
      }

      ico.innerHTML = `<span class="ProseMirror-icon">➤</span>`
      btn.addEventListener('click', () => {
        const open = !dom.hasAttribute('open')
        setAttributes(editor, getPos, Object.assign(Object.assign({}, node.attrs), { open }))
      })
      btn.append(ico)
      dom.append(btn)
      dom.append(div)

      return {
        dom,
        contentDOM: div,
        update: updatedNode => {
          if (updatedNode.type !== this.type) {
            return false
          }
          if (updatedNode.attrs.open) {
            dom.setAttribute('open', 'true')
          } else {
            dom.removeAttribute('open')
          }

          return true
        },
      }
    }
  },
  addCommands() {
    return {
      setDetails: () => {
        return ({ state, chain }) => {
          var _a, _b
          const range = state.selection.$from.blockRange(state.selection.$to)
          if (!range) {
            return false
          }
          const slice = state.doc.slice(range.start, range.end)
          if (!state.schema.nodes.detailsContent.contentMatch.matchFragment(slice.content)) {
            return false
          }

          return chain()
            .insertContentAt(
              {
                from: range.start,
                to: range.end,
              },
              {
                type: this.name,
                attrs: {
                  open: false,
                },
                content: [
                  {
                    type: 'detailsSummary',
                  },
                  {
                    type: 'detailsContent',
                    content:
                      (_b =
                        (_a = slice.toJSON()) === null || _a === void 0 ? void 0 : _a.content) !==
                        null && _b !== void 0
                        ? _b
                        : [],
                  },
                ],
              },
            )
            .setTextSelection(range.start + 2)
            .run()
        }
      },
      unsetDetails: () => {
        return ({ state, chain }) => {
          var _a
          const parent = findParentNode(node => node.type === this.type)(state.selection)
          if (!parent) {
            return false
          }
          const summary = findChildren(parent.node, node => node.type.name === 'detailsSummary')
          const content = findChildren(parent.node, node => node.type.name === 'detailsContent')
          if (!summary.length || !content.length) {
            return false
          }
          const range = { from: parent.pos, to: parent.pos + parent.node.nodeSize }
          const defaultType = state.doc.resolve(range.from).parent.type.contentMatch.defaultType

          return chain()
            .insertContentAt(range, [
              defaultType === null || defaultType === void 0
                ? void 0
                : defaultType.create(null, summary[0].node.content).toJSON(),
              ...((_a = content[0].node.content.toJSON()) !== null && _a !== void 0 ? _a : []),
            ])
            .setTextSelection(range.from + 1)
            .run()
        }
      },
      toggleDetails: () => {
        return ({ state, chain }) => {
          const node = findParentNode(node => node.type === this.type)(state.selection)
          if (node) {
            return chain().unsetDetails().run()
          } else {
            return chain().setDetails().run()
          }
        }
      },
    }
  },
  addInputRules() {
    return [
      wrappingInputRule({
        find: /^:::details\s$/,
        type: this.type,
      }),
    ]
  },
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-d': () => this.editor.commands.toggleDetails(),
    }
  },
})
