import { find } from 'linkifyjs'

let tooltip

export function setHyperlinkModal(options) {
  // Create the tooltip instance
  if (!tooltip) tooltip = new options.Tooltip(options)

  // Initialize the tooltip
  const { tippyModal } = tooltip.init()

  const theme = localStorage.getItem('oriens-active-theme')

  // Create the main container div
  const containerDiv = document.createElement('div')
  containerDiv.classList.add('v-card', 'v-sheet', `theme--${theme}`, 'elevation-1')

  // Create the inner text container div
  const textContainerDiv = document.createElement('div')
  textContainerDiv.classList.add('v-card__text', 'pa-3')

  // Create the form element
  const formElement = document.createElement('form')
  formElement.setAttribute('novalidate', 'novalidate')
  formElement.classList.add('v-form')

  // Create the input container div
  const inputContainerDiv = document.createElement('div')
  inputContainerDiv.classList.add(
    'v-input',
    'mb-3',
    'v-input--hide-details',
    'v-input--dense',
    `theme--${theme}`,
    'v-text-field',
    'v-text-field--is-booted',
    'v-text-field--enclosed',
    'v-text-field--outlined',
    'v-text-field--placeholder',
  )

  // Create the input control div
  const inputControlDiv = document.createElement('div')
  inputControlDiv.classList.add('v-input__control')

  // Create the input slot div
  const inputSlotDiv = document.createElement('div')
  inputSlotDiv.classList.add('v-input__slot')

  // Create the fieldset element
  const fieldset = document.createElement('fieldset')
  fieldset.setAttribute('aria-hidden', 'true')

  // Create the legend element
  const legend = document.createElement('legend')
  legend.style.width = '0px'

  // Create the span element inside legend
  const span = document.createElement('span')
  span.classList.add('notranslate')
  span.innerHTML = '&ZeroWidthSpace;'

  // Append span to legend
  legend.appendChild(span)

  // Append legend to fieldset
  fieldset.appendChild(legend)

  // Create the text field slot div
  const textFieldSlotDiv = document.createElement('div')
  textFieldSlotDiv.classList.add('v-text-field__slot')

  // Create the input element
  const inputElement = document.createElement('input')
  inputElement.id = 'input-806'
  inputElement.placeholder = 'Input Link'
  inputElement.type = 'text'

  // Append input element to text field slot div
  textFieldSlotDiv.appendChild(inputElement)

  // Append fieldset and text field slot div to input slot div
  inputSlotDiv.appendChild(fieldset)
  inputSlotDiv.appendChild(textFieldSlotDiv)

  // Append input slot div to input control div
  inputControlDiv.appendChild(inputSlotDiv)

  // Append input control div to input container div
  inputContainerDiv.appendChild(inputControlDiv)

  // Append input container div to form element
  formElement.appendChild(inputContainerDiv)

  // Create the button element
  const buttonElement = document.createElement('button')
  buttonElement.type = 'submit'
  buttonElement.classList.add(
    'v-btn',
    'v-btn--block',
    'v-btn--is-elevated',
    'v-btn--has-bg',
    `theme--${theme}`,
    'v-size--small',
    'primary',
  )

  // Create the button content span
  const buttonContentSpan = document.createElement('span')
  buttonContentSpan.classList.add('v-btn__content')
  buttonContentSpan.textContent = 'Confirm'

  // Append button content span to button element
  buttonElement.appendChild(buttonContentSpan)

  // Append button element to form element
  formElement.appendChild(buttonElement)

  // Append form element to the text container div
  textContainerDiv.appendChild(formElement)

  // Append text container div to the main container div
  containerDiv.appendChild(textContainerDiv)
  // end vuetify card

  tippyModal.innerHTML = ''
  tippyModal.append(containerDiv)
  tooltip.update(options.editor.view)

  // make sure
  setTimeout(() => {
    inputElement.focus()
    inputElement.style.outlineColor = ' #dadce0'
  }, 100)

  inputElement.addEventListener('keydown', () => {
    inputElement.style.outlineColor = ' #dadce0'
  })

  // event listenr for submit button
  formElement.addEventListener('submit', event => {
    event.preventDefault()
    const url = inputElement.value

    if (!url) {
      inputElement.style.outlineColor = 'red'

      return
    }

    const sanitizeURL = find(url)
      .filter(link => link.isLink)
      .filter(link => {
        if (options.validate) {
          return options.validate(link.value)
        }

        return true
      })
      .at(0)

    if (!sanitizeURL?.href) {
      inputElement.style.outlineColor = 'red'

      return
    }

    tooltip?.hide()

    // eslint-disable-next-line consistent-return
    return options.editor
      .chain()
      .setMark(options.extentionName, { href: sanitizeURL.href })
      .setMeta('preventautohyperlink', true)
      .run()
  })
}
