<template>
  <v-list
    dense
    max-width="300"
    class="pt-1"
  >
    <v-subheader class="ms-2"> Action </v-subheader>

    <v-list-item
      v-if="canMoveNodeUp"
      color="primary"
      @click="moveBlock('UP')"
    >
      <v-list-item-avatar
        tile
        size="30"
      >
        <v-icon>
          {{ icons.mdiArrowUp }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> Move Up </v-list-item-title>
        <v-list-item-subtitle> Move the current block up </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="canMoveNodeDown"
      color="primary"
      @click="moveBlock('DOWN')"
    >
      <v-list-item-avatar
        tile
        size="30"
      >
        <v-icon>
          {{ icons.mdiArrowDown }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> Move Down </v-list-item-title>
        <v-list-item-subtitle> Move the current block down </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="editor.isActive('highlight')"
      color="primary"
      @click="editor.chain().focus().unsetHighlight().blur().run()"
    >
      <v-list-item-avatar
        tile
        size="30"
        class="mb-1"
      >
        <v-icon>
          {{ icons.mdiFormatColorHighlight }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> Unset Highlight </v-list-item-title>
        <v-list-item-subtitle> Clear current highlight </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!editor.isActive('textStyle', { color: null })"
      color="primary"
      @click="editor.chain().focus().unsetColor().blur().run()"
    >
      <v-list-item-avatar
        tile
        size="30"
        class="mb-1"
      >
        <v-icon>
          {{ icons.mdiFormatColorText }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> Unset Color </v-list-item-title>
        <v-list-item-subtitle> Clear current text color </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      color="primary"
      @click="editor.commands.deleteSelection()"
    >
      <v-list-item-avatar
        tile
        size="30"
      >
        <v-icon>
          {{ icons.mdiDelete }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> Delete </v-list-item-title>
        <v-list-item-subtitle> Delete the current block </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-menu
      v-model="menu"
      open-on-hover
      nudge-right="300"
      top
      max-width="200"
      right
    >
      <template #activator="{ on, attrs }">
        <v-list-item
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="menu = !menu"
        >
          <v-list-item-avatar
            tile
            size="30"
          >
            <v-icon>
              {{ icons.mdiAutorenew }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Turn Into </v-list-item-title>
            <v-list-item-subtitle> Convert the current block </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list
        dense
        class="pt-1"
        max-width="200"
      >
        <v-subheader class="ms-2"> Turn Into </v-subheader>

        <div
          v-for="tool in convertTools"
          :key="tool.label"
        >
          <v-list-item
            v-if="tool.name !== topLevelNodeType"
            color="primary"
            @click="tool.command"
          >
            <v-list-item-avatar
              tile
              size="24"
            >
              <v-icon>
                {{ tool.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ tool.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-list>
</template>

<script>
import { ref, computed } from 'vue'
import { MoveNode } from '@/components/editor/utils/pm-utils'
import {
  mdiDelete,
  mdiAutorenew,
  mdiArrowUp,
  mdiArrowDown,
  mdiFormatParagraph,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatListNumbered,
  mdiFormatListBulleted,
  mdiFormatColumns,
  mdiCodeTags,
  mdiFormatQuoteOpen,
  mdiFormatListCheckbox,
  mdiFormatColorText,
  mdiFormatColorHighlight,
} from '@mdi/js'

export default {
  props: {
    editor: {
      required: true,
      type: Object,
    },
    topLevelNodeType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const menu = ref(false)
    const convertTools = [
      {
        name: 'paragraph',
        title: 'Paragraph',
        icon: mdiFormatParagraph,
        command: () => {
          props.editor.chain().focus().setNode('paragraph').blur().run()
        },
      },
      {
        name: 'heading1',
        title: 'Heading 1',
        icon: mdiFormatHeader1,
        command: () => {
          props.editor.chain().focus().setNode('heading', { level: 1 }).blur().run()
        },
      },
      {
        name: 'heading2',
        title: 'Heading 2',
        icon: mdiFormatHeader2,
        command: () => {
          props.editor.chain().focus().setNode('heading', { level: 2 }).blur().run()
        },
      },
      {
        name: 'heading3',
        title: 'Heading 3',
        icon: mdiFormatHeader3,
        command: () => {
          props.editor.chain().focus().setNode('heading', { level: 3 }).blur().run()
        },
      },
      {
        name: 'numberedList',
        title: 'Numbered List',
        icon: mdiFormatListNumbered,
        command: () => {
          props.editor.chain().focus().toggleOrderedList().blur().run()
        },
      },
      {
        name: 'bulletedList',
        title: 'Bulleted List',
        icon: mdiFormatListBulleted,
        command: () => {
          props.editor.chain().focus().toggleBulletList().blur().run()
        },
      },
      {
        name: '2 column',
        title: 'Column',
        icon: mdiFormatColumns,
        command: () => {
          props.editor.chain().focus().setColumns(2).blur().run()
        },
      },
      {
        name: 'codeBlock',
        title: 'Code Block',
        icon: mdiCodeTags,
        command: () => {
          props.editor.chain().focus().toggleCodeBlock().blur().run()
        },
      },
      {
        name: 'blockquote',
        title: 'Blockquote',
        icon: mdiFormatQuoteOpen,
        command: () => {
          props.editor.chain().focus().toggleBlockquote().blur().run()
        },
      },
      {
        name: 'todoList',
        title: 'Todo List',
        icon: mdiFormatListCheckbox,
        command: () => {
          props.editor.chain().focus().toggleTaskList().blur().run()
        },
      },
    ]

    const moveBlock = direction => {
      MoveNode({
        view: props.editor.view,
        dir: direction,
        currentResolved: props.editor.view.state.selection.$from,
      })
    }

    const canMoveNodeDown = computed(() => {
      const selectionStart = props.editor.view.state.selection.$from

      return selectionStart.index(0) < selectionStart.node(0).childCount
    })

    const canMoveNodeUp = computed(() => {
      const selectionStart = props.editor.view.state.selection.$from

      return selectionStart.index(0) > 0
    })

    return {
      menu,
      convertTools,
      moveBlock,
      canMoveNodeDown,
      canMoveNodeUp,

      icons: {
        mdiFormatColorHighlight,
        mdiFormatColorText,
        mdiDelete,
        mdiAutorenew,
        mdiArrowUp,
        mdiArrowDown,
      },
    }
  },
}
</script>
