<template>
  <v-card
    :width="width"
    min-height="350px"
    class="user-selector-container elevation-0"
  >
    <chip-search-input
      class="py-2 px-3"
      object-type="user"
      :selected-object-ids="selectedUserIds"
      :object-list="userList"
      :width="width"
      :search.sync="search"
      :selected-keys="selectedKeys"
      :autoFocus="autoFocus"
      @input-search="search = $event"
      @remove="handleChipDelete"
      @clear="selectedKeys = []"
    />

    <v-divider />

    <multi-tree-view
      v-if="hierarchyList.length && !loading"
      v-model="selectedKeys"
      :items="hierarchyList"
      :search="search"
      style="overflow-y: auto; height: 300px"
      class="user-selector-treeview text-subtitle-2 pb-2"
    >
      <template #prepend="{ item }">
        <v-simple-checkbox
          :value="selectedKeys.includes(item.key)"
          :indeterminate="isIndeterminate(item)"
          :disabled="item.disabled"
          color="primary"
          class="my-0 ps-1 py-0 mx-auto v-icon--dense v-input--dense v-input--selection-controls"
          @input="handleSelect(item, $event)"
        />
      </template>

      <template #label="{ item }">
        <div v-if="!item.users">
          <v-avatar
            size="30"
            class="mr-2 v-avatar-light-bg"
          >
            <v-img
              v-if="item.photo"
              :src="item.photo"
            />
            <span v-else>{{ avatarText(item.name) }}</span>
          </v-avatar>
          <span>{{ item.name }}</span>
          <v-chip
            x-small
            v-if="item.id === currentUserId"
            color="primary"
            class="ml-2 px-2"
          >
            Anda
          </v-chip>
        </div>
        <span v-else>{{ item.name }}</span>
      </template>

      <template #append="{ item }">
        <div v-if="isTeam(item.key) && !isJoinedTeam(item.status)">
          <v-btn
            v-if="item.is_public"
            x-small
            color="primary"
            :loading="loadingJoinTeam === item.id"
            @click="handleJoinTeam({ id: item.id, isPublic: item.is_public })"
          >
            Join
          </v-btn>
          <v-btn
            v-else
            x-small
            color="primary"
            :loading="loadingJoinTeam === item.id"
            @click="handleJoinTeam({ id: item.id, isPublic: item.is_public })"
          >
            {{ item.status === 'requested' ? 'Request Ulang' : 'Request Join' }}
          </v-btn>
        </div>
        <div v-else-if="isInvitable(item)">
          <InvitePermission
            :object-type="objectType"
            :object-detail="objectDetail"
            :user-id="item.id"
            :is-guest="item.is_guest ? true : false"
            @success="item.object_permission = $event"
          />
        </div>
      </template>
    </multi-tree-view>
    <v-sheet
      v-else
      height="300px"
    >
      <v-skeleton-loader
        type="list-item, list-item-two-line, list-item-two-line, list-item-three-line"
      />
    </v-sheet>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useJob from '@/composables/useJob'
import useTeam from '@/composables/useTeam'
import useFolder from '@/composables/useFolder'
import useDocument from '@/composables/useDocument'
import ChipSearchInput from './components/ChipSearchInput.vue'
import MultiTreeView from './components/treeview/MultiTreeView.vue'
import InvitePermission from '@/components/inputs/invite-permission/InvitePermission.vue'

const TEAM_PREFIX = 't'
const USER_PREFIX = 'u'

const emit = defineEmits(['input', 'update:selectedUsers'])
const { value, width, objectId, objectType } = defineProps({
  value: {
    type: [Array, Object, Number, String],
    default: () => [],
  },
  width: {
    type: [Number, String],
    default: 400,
  },
  objectId: {
    type: [Number, String],
    required: true,
  },
  objectType: {
    type: String,
    required: true,
    validator: value => ['document', 'job-type', 'folder'].includes(value),
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
})

const { joinTeam, requestJoin, fetchTeamHiearchy } = useTeam()
const { fetchDetail, documentDetail } = useDocument()
const { fetchFolderDetail, folderDetail } = useFolder()
const { fetchJobTypeDetail, jobTypeDetail } = useJob()

const objectDetail = computed(() => {
  switch (objectType) {
    case 'folder':
      return folderDetail.value
    case 'job-type':
      return jobTypeDetail.value
    case 'document':
      return documentDetail.value
    default:
      return {}
  }
})

const search = ref()
const ownerId = ref()
const loading = ref(false)
const loadingJoinTeam = ref(0)
const userList = ref([])
const hierarchyList = ref([])
const selectedKeys = ref([])
const selectedUsers = computed(() => {
  return selectedKeys.value
    .map(key => userList.value.find(user => user.id === Number(key.split('-')[1])))
    .filter(user => user !== null && user !== undefined)
})

watch(selectedUsers, val => {
  emit('update:selectedUsers', val)
})

const selectedUserIds = computed(() =>
  selectedKeys.value.filter(key => !isTeam(key)).map(key => Number(key.split('-')[1])),
)

const currentUserId = store.getters.getUserData.id
const isUserLoginOwner = computed(() => currentUserId === ownerId.value)
const isJoinedObject = permission => permission !== 'not_joined'
const isJoinedTeam = status => status === 'joined'

const isTeam = key => {
  if (!key) return false
  else return key.startsWith(TEAM_PREFIX)
}

const isIndeterminate = item => {
  if (!isTeam(item.key)) return false

  const keysSet = new Set(selectedKeys.value)

  if (keysSet.has(item.key)) return false
  if (item.users.every(user => keysSet.has(user.key))) return false

  return item.users.some(user => keysSet.has(user.key))
}
const isInvitable = user => {
  if (!isJoinedObject(user.object_permission) && isUserLoginOwner.value && !user.is_object_owner) {
    return true
  }

  return false
}

const findTeam = id => {
  const team = hierarchyList.value.find(team => team.id === id)

  if (team.id === id) return team

  return findTeam(team.id)
}
const mapUsers = team => {
  return team.users.map(item => {
    const {
      id,
      name,
      email,
      photo,
      is_guest,
      is_object_owner: isObjectOwner,
      folder_permissions: folderPermissions,
    } = item.user

    const key = `${USER_PREFIX}-${id}`
    let permissionType = folderPermissions?.find(
      el => el.job_type.id === objectDetail.value.id,
    )?.permission_type

    if (item.user.is_object_owner) ownerId.value = item.user.id
    if (objectDetail.value.is_public) permissionType = true
    if (permissionType === undefined) permissionType = 'not_joined'

    return {
      id,
      key,
      name,
      email,
      photo,
      is_guest,
      is_public: team.is_public,
      is_object_owner: isObjectOwner,
      object_permission: permissionType,
      disabled: !isJoinedObject(permissionType) && !isObjectOwner,
    }
  })
}
const mapHierarchyList = teams => {
  return teams.map(team => ({
    key: `${TEAM_PREFIX}-${team.id}`,
    id: team.id,
    name: team.name,
    status: team.status,
    is_public: team.is_public,
    disabled: false,
    is_object_owner: false,
    email: '',
    users: [...mapUsers(team), ...mapHierarchyList(team.children)],
  }))
}

const mapUsersNotInTeam = users => {
  return users.map(user => {
    const modify = {
      key: `${USER_PREFIX}-${user.id}`,
      ...user,
    }

    return modify
  })
}

const fetchUsersTeams = async () => {
  const teamHierarchy = await fetchTeamHiearchy({
    folderId: objectType === 'folder' ? objectId : null,
    jobTypeId: objectType === 'job-type' ? objectId : null,
    documentId: objectType === 'document' ? objectId : null,
  })

  hierarchyList.value = [
    ...mapHierarchyList(teamHierarchy.teams),
    ...mapUsersNotInTeam(teamHierarchy.usersNotInTeam),
  ]
  userList.value = teamHierarchy.userList
}

const addCheckAllTeam = (teams = hierarchyList.value) => {
  // ! IMIMNENT, there is a bug causing "not yet joined" team
  // to be "visually selected" when it shouldn't happen, this function may be the cultprit
  if (value.length === 0) return

  teams.forEach(team => {
    const children = team.users || []

    addCheckAllTeam(children.filter(el => el.key.startsWith(TEAM_PREFIX)))

    const users = children.filter(el => el.key.startsWith(USER_PREFIX))

    if (users.every(user => selectedUserIds.value.includes(user.id))) {
      selectedKeys.value = Array.from(new Set([...selectedKeys.value, team.key]))
    } else {
      selectedKeys.value = selectedKeys.value.filter(key => key !== team.key)
    }
  })
}
const toggleAllUserInTeam = (team, newValue) => {
  const users = team.users
    .filter(el => !el.disabled && el.key.startsWith(USER_PREFIX))
    .map(user => user.id)

  const teamsChildren = team.users.filter(el => el.key.startsWith(TEAM_PREFIX))

  teamsChildren.forEach(child => toggleAllUserInTeam(child, newValue))

  if (!newValue) {
    selectedKeys.value = selectedKeys.value.filter(
      key => !users.includes(Number(key.split('-')[1])),
    )
  } else {
    selectedKeys.value = Array.from(
      new Set([...selectedKeys.value, ...users.map(id => `${USER_PREFIX}-${id}`)]),
    )
  }
}
const handleSelect = (team, newValue) => {
  if (isTeam(team.key)) {
    toggleAllUserInTeam(team, newValue)
  } else {
    selectedKeys.value = newValue
      ? Array.from(new Set([...selectedKeys.value, team.key]))
      : selectedKeys.value.filter(key => key !== team.key)
  }

  addCheckAllTeam()
}

const handleJoinTeam = async ({ id, isPublic }) => {
  loadingJoinTeam.value = id
  const team = findTeam(id)

  if (isPublic) {
    try {
      await joinTeam(id)

      team.status = 'joined'
      team.disabled = false
      team.users.forEach(user => {
        user.disabled = false
      })
    } catch (err) {
      //
    }
  } else {
    try {
      await requestJoin(id)

      team.status = 'requested'
    } catch (err) {
      //
    }
  }

  loadingJoinTeam.value = 0
}
const handleChipDelete = userId => {
  selectedKeys.value = selectedKeys.value.filter(key => key !== `${USER_PREFIX}-${userId}`)
}

watch(selectedUserIds, value => {
  emit('input', value)
})
onMounted(async () => {
  loading.value = true

  switch (objectType) {
    case 'folder':
      await fetchFolderDetail(objectId)
      break
    case 'job-type':
      await fetchJobTypeDetail(objectId)
      break
    case 'document':
      fetchDetail(objectId)
      break
    default:
      break
  }

  await fetchUsersTeams()
  loading.value = false

  selectedKeys.value = value.map(userId => `${USER_PREFIX}-${userId}`)
  addCheckAllTeam()
})
</script>
