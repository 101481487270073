<template>
  <v-btn
    elevation="0"
    class="px-0 mx-0"
    min-width="48"
    max-width="48"
    tile
    @click="setLink"
  >
    <v-icon>{{ icons.mdiLink }}</v-icon>
  </v-btn>
</template>

<script>
import { ref, computed } from 'vue'
import { mdiLink, mdiLinkOff } from '@mdi/js'

export default {
  props: {
    editor: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const linkMenu = ref(false)
    const linkUrl = ref('')
    const isLink = computed(() => props.editor.isActive('link'))

    const setLink = () => {
      props.editor.chain().focus().extendMarkRange('hyperlink').setHyperlink().run()
      linkMenu.value = false

      // if (linkUrl.value === null || linkUrl.value === '') {
      //   return
      // }

      // const pattern = /^(http:\/\/|https:\/\/)/
      // const url = pattern.test(linkUrl.value)

      // if (!url) {
      //   // props.editor
      //   //   .chain()
      //   //   .focus()
      //   //   .extendMarkRange('link')
      //   //   .setLink({ href: `http://${linkUrl.value}` })
      //   //   .run()
      //   // this.url = linkUrl.value
      // } else {
      //   // update link
      //   props.editor.chain().focus().extendMarkRange('link').setLink({ href: linkUrl.value }).run()
      //   linkMenu.value = false
      //   // this.url = linkUrl.value
      // }

      // linkUrl.value = ''
      // linkMenu.value = false
      // // document.addEventListener('click', this.clickOutsideHandler)
    }

    const removeLink = () => {
      props.editor.chain().focus().unsetLink().run()
    }

    return {
      isLink,
      setLink,
      linkUrl,
      linkMenu,
      removeLink,

      icons: {
        mdiLink,
        mdiLinkOff,
      },
    }
  },
}
</script>
