import gql from 'graphql-tag'

export const deleteAutomation = gql`
  mutation DeleteAutomation($workspaceId: Float!, $automationId: Float!) {
    deleteAutomation(workspace_id: $workspaceId, automation_id: $automationId) {
      status
      msg
      data
    }
  }
`
