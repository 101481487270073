<template>
  <node-view-wrapper
    style="align-self: center; max-width: 100%; padding: 10px; margin-block: 0"
    :status="node.attrs?.status"
  >
    <ResizingComponent :props="props">
      <template #content>
        <iframe
          ref="iframe"
          :src="embedUrl"
          style="width: 100%; pointer-events: auto"
          :allowfullscreen="extension.options.allowFullscreen"
          :autoplay="extension.options.autoplay"
          :ccLanguage="extension.options.ccLanguage"
          :ccLoadPolicy="extension.options.ccLoadPolicy"
          :disableKBcontrols="extension.options.disableKBcontrols"
          :enableIFrameApi="extension.options.enableIFrameApi"
          :endTime="extension.options.endTime"
          :interfaceLanguage="extension.options.interfaceLanguage"
          :ivLoadPolicy="extension.options.ivLoadPolicy"
          :loop="extension.options.loop"
          :modestBranding="extension.options.modestBranding"
          :origin="extension.options.origin"
          :playlist="extension.options.playlist"
          :progressBarColor="extension.options.progressBarColor"
        ></iframe>
      </template>
    </ResizingComponent>
  </node-view-wrapper>
</template>
<script setup>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
import { getEmbedUrlFromYoutubeUrl } from '../../extensions/youtube/utils'
import ResizingComponent from './ResizingComponent.vue'

const props = defineProps(nodeViewProps)

const embedUrl = getEmbedUrlFromYoutubeUrl({
  url: props.node.attrs.src,
  allowFullscreen: props.extension.options.allowFullscreen,
  autoplay: props.extension.options.autoplay,
  ccLanguage: props.extension.options.ccLanguage,
  ccLoadPolicy: props.extension.options.ccLoadPolicy,
  controls: props.extension.options.controls,
  disableKBcontrols: props.extension.options.disableKBcontrols,
  enableIFrameApi: props.extension.options.enableIFrameApi,
  endTime: props.extension.options.endTime,
  interfaceLanguage: props.extension.options.interfaceLanguage,
  ivLoadPolicy: props.extension.options.ivLoadPolicy,
  loop: props.extension.options.loop,
  modestBranding: props.extension.options.modestBranding,
  nocookie: props.extension.options.nocookie,
  origin: props.extension.options.origin,
  playlist: props.extension.options.playlist,
  progressBarColor: props.extension.options.progressBarColor,
  startAt: props.node.attrs.start || 0,
})
</script>
<style lang=""></style>
