<template>
  <v-menu
    ref="menu"
    offset-y
    open-on-hover
  >
    <template #activator="menu">
      <v-tooltip
        top
        class="px-0"
        :open-on-hover="false"
        :open-on-click="false"
        :value="$refs.menu && $refs.menu.isActive"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            min-width="70"
            max-width="70"
            elevation="0"
            class="px-0"
            v-bind="{ ...menu.attrs, ...attrs }"
            style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            v-on="{ ...menu.on, ...on }"
          >
            <v-icon size="26">
              {{ icons.mdiFormatColorText }}
            </v-icon>
            <v-icon size="26">
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        Font Color
      </v-tooltip>
    </template>
    <v-list
      dense
      class="pt-1"
    >
      <v-subheader> Recently Used </v-subheader>
      <v-list-item
        class="ps-3 mb-2"
        @click="setColor(recentlyUsed.hex)"
      >
        <v-list-item-icon class="pr-2 mb-0">
          <v-icon :color="recentlyUsed.hex !== '#000' ? recentlyUsed.hex : null">
            {{ icons.mdiFormatColorText }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ recentlyUsed.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-subheader> Text Color </v-subheader>
      <div
        v-for="(color, index) in colorPalette"
        :key="color.hex"
      >
        <v-list-item
          class="ps-3"
          color="primary"
          :input-value="color.active"
          @click="setColor(color.hex, index)"
        >
          <v-list-item-icon class="pr-2 mb-0">
            <v-icon :color="color.hex !== '#000' ? color.hex : null">
              {{ icons.mdiFormatColorText }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ color.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { ref, computed } from 'vue'
import { mdiChevronDown, mdiFormatColorText } from '@mdi/js'

export default {
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const recentlyUsed = ref({ label: 'Default', hex: '#000' })
    const colorPalette = computed(() => [
      {
        label: 'Default',
        hex: '#000',
        active:
          !props.editor.isActive('textStyle') ||
          props.editor.isActive('textStyle', { color: null }),
      },
      {
        label: 'Gray',
        hex: '#808080',
        active: props.editor.isActive('textStyle', { color: '#808080' }),
      },
      {
        label: 'Violet',
        hex: '#958DF1',
        active: props.editor.isActive('textStyle', { color: '#958DF1' }),
      },
      {
        label: 'Red',
        hex: '#FF0000',
        active: props.editor.isActive('textStyle', { color: '#FF0000' }),
      },
      {
        label: 'Orange',
        hex: '#FFA500',
        active: props.editor.isActive('textStyle', { color: '#FFA500' }),
      },
      {
        label: 'Yellow',
        hex: '#FFFF00',
        active: props.editor.isActive('textStyle', { color: '#FFFF00' }),
      },
      {
        label: 'Blue',
        hex: '#70CFF8',
        active: props.editor.isActive('textStyle', { color: '#70CFF8' }),
      },
      {
        label: 'Green',
        hex: '#008000',
        active: props.editor.isActive('textStyle', { color: '#008000' }),
      },
      {
        label: 'Brown',
        hex: '#A52A2A',
        active: props.editor.isActive('textStyle', { color: '#A52A2A' }),
      },
      {
        label: 'Pink',
        hex: '#FFC0CB',
        active: props.editor.isActive('textStyle', { color: '#FFC0CB' }),
      },
    ])
    const setColor = (color, index) => {
      if (index !== undefined) recentlyUsed.value = colorPalette.value[index]

      const { state } = props.editor
      // eslint-disable-next-line no-unused-vars
      const { from, to, empty } = state.selection

      if (empty) {
        // No text is selected
        // props.isHighlight = false
      } else {
        const { marks } = state.doc.resolve(from).marks()

        if (!marks || !marks.some(mark => mark.type.name === 'link' && mark.attrs.href)) {
          // The selection does not contain the "href" attribute
          if (color === '#000') {
            props.editor.chain().focus().unsetColor().blur().run()
          } else {
            props.editor.chain().focus().setColor(color).blur().run()
          }
        } else {
          // The selection contains the "href" attribute, handle accordingly
          // You can add your specific logic here if needed
          console.log("Selection contains 'href' attribute")
        }
      }
    }

    return {
      setColor,
      colorPalette,
      recentlyUsed,

      icons: {
        mdiFormatColorText,
        mdiChevronDown,
      },
    }
  },
}
</script>
