// https://github.com/ueberdosis/tiptap/blob/main/demos/src/Experiments/GlobalDragHandle/Vue/DragHandle.js

import { Fragment, Slice } from 'prosemirror-model'
import { NodeSelection, Plugin } from 'prosemirror-state'
// @ts-ignore
import { __serializeForClipboard } from 'prosemirror-view'

function absoluteRect(node) {
  const data = node.getBoundingClientRect()

  return {
    top: data.top,
    left: data.left,
    width: data.width,
  }
}

function nodeDOMAtCoords(coords) {
  return document.elementsFromPoint(coords.x, coords.y).find(elem => {
    return (
      elem.parentElement?.matches?.('.ProseMirror') ||
      elem.matches(
        [
          'li', // match  list item

          'ol', // Match ordered lists
          'ol ol > li:first-child', // match first li in nested ol
          'ol > li:not(:first-child)', // Match non-first list items

          // 'ul', // Match unordered lists
          'ul ul > li:first-child', // Match first li in nested ul
          'ul > li:not(:first-child)', // Match non-first list items

          '[data-type=detailsContent] > *', // Custom data type
          'pre', // Match preformatted text
          'blockquote', // Match blockquotes
          'h1, h2, h3', // Match headings
          '[data-type=callout]', // Custom data type
          '[data-type=horizontalRule]', // Custom data type
          '.tableWrapper', // Match table wrapper
          'hr', // Match horizontal rules
          'iframe', // Match iframes
          'video', // Match videos
        ].join(', '),
      )
    )
  })
}

export function nodePosAtDOM(node, view) {
  const boundingRect = node.getBoundingClientRect()

  return view.posAtCoords({
    left: boundingRect.left + 1,
    top: boundingRect.top + 1,
  })?.inside
}

export default function DragHandle(options) {
  function handleDragStart(event, view) {
    view.focus()

    if (!event.dataTransfer) return

    const node = nodeDOMAtCoords({
      x: event.clientX + 50 + options.dragHandleWidth,
      y: event.clientY,
    })

    if (!(node instanceof Element)) return

    const nodePos = nodePosAtDOM(node, view)
    if (nodePos === null) return

    view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)))

    const slice = view.state.selection.content()

    const { content } = slice
    if (
      content.childCount === 1 &&
      content.firstChild.type.name === 'listItem' &&
      view.state.doc.resolve(nodePos).parent.type.name === 'bulletList'
    ) {
      const bulletList = view.state.schema.nodes.bulletList.create(null, content)
      const newSlice = new Slice(Fragment.from(bulletList), slice.openStart, slice.openEnd)
      view.dragging = { slice: newSlice, move: event.ctrlKey }
    } else {
      view.dragging = { slice, move: event.ctrlKey }
    }

    const { dom, text } = __serializeForClipboard(view, view.dragging.slice)

    event.dataTransfer.clearData()
    event.dataTransfer.setData('text/html', dom.innerHTML)
    event.dataTransfer.setData('text/plain', text)
    event.dataTransfer.effectAllowed = 'copyMove'
    event.dataTransfer.setDragImage(node, 0, 0)
  }

  function handleClick(event, view) {
    view.focus()

    view.dom.classList.remove('dragging')

    const node = nodeDOMAtCoords({
      x: event.clientX + 50 + options.dragHandleWidth,
      y: event.clientY,
    })

    if (!(node instanceof Element)) return

    const nodePos = nodePosAtDOM(node, view)
    if (nodePos === null) return

    view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)))

    const customEvent = new CustomEvent('drag-handle-clicked', {
      bubbles: true,
      detail: { isOpen: true },
    })
    view.dom.dispatchEvent(customEvent)
  }

  let dragHandleElement = null

  function hideDragHandle() {
    if (dragHandleElement) {
      dragHandleElement.classList.add('hidden')
    }
  }

  function showDragHandle() {
    if (dragHandleElement) {
      dragHandleElement.classList.remove('hidden')
    }
  }

  return new Plugin({
    view: view => {
      dragHandleElement = document.createElement('div')
      dragHandleElement.draggable = true
      dragHandleElement.dataset.dragHandle = ''
      dragHandleElement.classList.add('drag-handle')
      dragHandleElement.addEventListener('dragstart', e => {
        handleDragStart(e, view)
      })
      dragHandleElement.addEventListener('click', e => {
        handleClick(e, view)
      })

      hideDragHandle()

      view?.dom?.parentElement?.appendChild(dragHandleElement)

      return {
        destroy: () => {
          dragHandleElement?.remove?.()
          dragHandleElement = null
        },
      }
    },
    props: {
      handleDOMEvents: {
        mousemove: (view, event) => {
          if (!view.editable) {
            return
          }

          const node = nodeDOMAtCoords({
            x: event.clientX + 50 + options.dragHandleWidth,
            y: event.clientY,
          })

          if (!(node instanceof Element)) {
            hideDragHandle()

            return
          }

          const compStyle = window.getComputedStyle(node)
          const lineHeight = parseInt(compStyle.lineHeight, 10)
          const paddingTop = parseInt(compStyle.paddingTop, 10)

          const rect = absoluteRect(node)

          rect.top += (lineHeight - 24) / 2
          rect.top += paddingTop
          // Li markers
          if (node.matches('ul:not([data-type=taskList]) li, ol li')) {
            rect.left -= options.dragHandleWidth
          }
          rect.width = options.dragHandleWidth

          if (!dragHandleElement) return

          dragHandleElement.style.left = `${rect.left - rect.width}px`
          dragHandleElement.style.top = `${rect.top}px`
          showDragHandle()
        },
        keydown: () => {
          hideDragHandle()
        },
        mousewheel: () => {
          hideDragHandle()
        },
        // dragging class is used for CSS
        dragstart: view => {
          view.dom.classList.add('dragging')
        },
        drop: view => {
          view.dom.classList.remove('dragging')
        },
        dragend: view => {
          view.dom.classList.remove('dragging')
        },
      },
    },
  })
}
