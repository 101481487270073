import { mdiFileDocumentOutline, mdiEyeOffOutline } from '@mdi/js'
import { mergeAttributes, Node } from '@tiptap/core'
import { h } from 'jsx-dom-cjs'

function isHavePermission(node, options) {
  if (node.attrs.docIsPublic) return true

  return (
    node.attrs.createdBy === options.currentUser ||
    (!node.attrs.docIsPublic && node.attrs.docPermission.includes(options.currentUser))
  )
}

export const DocumentMention = Node.create({
  name: 'documentMention',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addOptions() {
    return {
      currentUser: 0,
      HTMLAttributes: {
        class: 'document-mention',
      },
      renderText({ node }) {
        return `${node.attrs.label}`
      },
      deleteTriggerWithBackspace: false,
      renderHTML({ options, node }) {
        const currentUrl = window.location.href
        const parts = new URL(currentUrl)
        const pathParts = parts.pathname.split('/')
        const workspace = pathParts[1]
        const url = `/${workspace}/documents/detail/${node.attrs.uuid}`

        return [
          'a',
          mergeAttributes(
            {
              target: `${isHavePermission(node, options) ? '_blank' : '_self'}`,
              rel: 'noopener noreferrer nofollow',
              href: `${isHavePermission(node, options) ? url : 'javascript:void(0)'}`,
              class: 'document-mention',
            },
            options.HTMLAttributes,
          ),
          [
            'span',
            { class: 'v-icon notranslate me-1 ' },
            h(
              'svg',
              {
                xmlns: 'http://www.w3.org/2000/svg',
                viewBox: '0 0 24 24',
                role: 'img',
                'aria-hidden': 'true',
                class: 'v-icon__svg',
                style: 'font-size: 20px; height: 20px; width: 20px;',
              },
              [
                h('path', {
                  d: isHavePermission(node, options) ? mdiFileDocumentOutline : mdiEyeOffOutline,
                }),
              ],
            ),
          ],
          h('span', {
            textContent: `${isHavePermission(node, options) ? node.attrs.label : 'No Access'}`,
          }),
        ]
      },
    }
  },

  addAttributes() {
    return {
      uuid: {
        default: null,
        parseHTML: element => element.getAttribute('data-uuid'),
      },
      label: {
        default: null,
        parseHTML: element => element.getAttribute('data-label'),
      },
      docIsPublic: {
        default: false,
        parseHTML: element => element.getAttribute('data-docIsPublic'),
      },
      docPermission: {
        default: [],
        parseHTML: element => element.getAttribute('data-docPermission'),
      },
      createdBy: {
        default: {},
        parseHTML: element => element.getAttribute('data-createdBy'),
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    if (this.options.renderLabel !== undefined) {
      console.warn('renderLabel is deprecated use renderText and renderHTML instead')

      return [
        'a',
        mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
        this.options.renderLabel({
          options: this.options,
          node,
        }),
      ]
    }
    const mergedOptions = { ...this.options }

    mergedOptions.HTMLAttributes = {
      'data-type': this.name,
      'data-label': node.attrs.label,
      class: this.options.HTMLAttributes.class,
    }
    const html = this.options.renderHTML({
      options: mergedOptions,
      node,
    })

    if (typeof html === 'string') {
      return [
        'a',
        mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
        html,
      ]
    }

    return html
  },

  renderText({ node }) {
    if (this.options.renderLabel !== undefined) {
      console.warn('renderLabel is deprecated use renderText and renderHTML instead')

      return this.options.renderLabel({
        options: this.options,
        node,
      })
    }

    return this.options.renderText({
      options: this.options,
      node,
    })
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false
          const { selection } = state
          const { empty, anchor } = selection

          if (!empty) {
            return false
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true
              tr.insertText(
                this.options.deleteTriggerWithBackspace ? '' : '@' || '',
                pos,
                pos + node.nodeSize,
              )

              return false
            }
          })

          return isMention
        }),
    }
  },
})
