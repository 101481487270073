<template>
  <div>
    <ckeditor-field
      :placeholder="placeholder"
      :read-only="readOnly"
      :content.sync="editorData"
      :plugins="plugins"
      :configs="configs"
      :ready="onEditorReady"
      class="comment-field"
    />

    <v-menu
      v-model="mentionMenu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
    >
      <v-card width="400">
        <user-team-selector
          v-if="mentionMenu"
          @select="handleSelectMention"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { UppyUpload } from '@/components/ckeditor/extensions/ckeditor5-uppy-upload'
import { Viewer } from '@/components/ckeditor/extensions/ckeditor5-viewer'
import { Mention } from '@ckeditor/ckeditor5-mention'
import { MentionCustomization } from '@/components/ckeditor/extensions/mentionHelper'
import CkeditorField from '@/components/ckeditor/CkeditorField.vue'
import router from '@/router'
import store from '@/store'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import useAttachment from '@/composables/useAttachment'

export default {
  components: {
    UserTeamSelector,
    CkeditorField,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const editorInstance = ref()
    const attachMenu = ref(false)
    const selectedUser = ref([])
    const mentionMenu = ref(false)
    const menuX = ref(0)
    const menuY = ref(0)

    const configs = {}
    const plugins = ref([UppyUpload, Viewer, Mention, MentionCustomization])

    const { baseAttachmentUrl } = useAttachment()

    const onEditorReady = editor => {
      editorInstance.value = editor

      editor.commands.get('uppyUpload').on('execute', evt => {
        emit('toggle-dialog', evt.return)
        attachMenu.value = true
      })

      editor.commands.get('viewer').on('execute', evt => {
        router.push({
          name: 'attachment-detail',
          params: {
            id: evt.return.split('/')[evt.return.split('/').length - 2],
          },
        })
      })

      editor.keystrokes.set('Shift+2', data => {
        const { x, y, width } = data.domTarget.getBoundingClientRect()

        menuX.value = x + width - 400 - 1
        menuY.value = y + 1
        mentionMenu.value = true

        setTimeout(() => {
          document.querySelector('.v-menu__content input').focus()
        }, 500)
      })
    }

    const closeDialog = () => {
      editorInstance.value.commands.get('uppyUpload').execute(false)
      attachMenu.value = false
    }

    const insertFile = evt => {
      const file = evt[0]
      const url = `${baseAttachmentUrl}/${file.job.job_type.id}/${file.id}/${file.file_name}`

      if (file.file_type === 'image') {
        editorInstance.value.execute('insertImage', { source: url })
      } else {
        editorInstance.value.execute('link', url)
      }

      closeDialog()
    }

    const handleSelectMention = mentions => {
      const editor = editorInstance.value
      const { focus } = editor.model.document.selection
      const workspaceSlug = store.getters.getCurrentWorkspace.identifier_id
      const mentionLength = selectedUser.value.reduce((acc, item) => acc + item.name.length + 2, 0)
      const shiftedLength = mentionLength ? 0 - mentionLength : -1

      if (!mentions.length && selectedUser.value.length === 1) {
        editor.model.change(writer => {
          const range = editor.model.createRange(focus.getShiftedBy(shiftedLength), focus)
          writer.remove(range)
        })
      }

      mentions.forEach((item, index) => {
        editor.execute('mention', {
          marker: '@',
          mention: {
            id: `@${item.name}`,
            name: item.name,
            link: `/${workspaceSlug}/users/detail/${item.id}`,
            userId: item.id,
          },
          range:
            index === 0 ? editor.model.createRange(focus.getShiftedBy(shiftedLength), focus) : null,
        })
      })

      selectedUser.value = mentions.length ? mentions : selectedUser.value
    }

    watch(mentionMenu, () => {
      if (!mentionMenu.value) {
        selectedUser.value = []
      }
    })

    return {
      onEditorReady,
      closeDialog,
      insertFile,
      handleSelectMention,
      menuX,
      menuY,
      mentionMenu,

      attachMenu,
      plugins,
      configs,
    }
  },
  computed: {
    editorData: {
      get() {
        return this.content
      },
      set(value) {
        this.$emit('update:content', value)
      },
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.comment-field {
  .ck-read-only {
    background-color: map-deep-get($theme-colors, 'primary') !important;
    border: none !important;

    .dark-layout & {
      background-color: map-deep-get($theme-colors, 'primary') !important;
    }

    &.ck-content * {
      color: #f0efff !important;
      .theme--dark & {
        color: #f0efff !important;
      }
    }
  }
  .ck.ck-read-only.ck-editor__editable_inline {
    border: none;
    max-height: 100%;
  }
  .ck.ck-editor__editable_inline {
    border: none !important;
    box-shadow: none !important;
    max-height: 213px;
  }
}
</style>
