import { Mark, mergeAttributes } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import Image from '@tiptap/extension-image'

export const commentPluginKey = new PluginKey('inlineComment')
export const commentImagePluginKey = new PluginKey('inlineCommentImage')

export const Comment = Mark.create({
  name: 'inlineComment',
  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  addAttributes() {
    return {
      'data-comment-id': {
        default: null,
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'mark',
        getAttrs: element => ({
          color: element.getAttribute('data-color') || element.style.backgroundColor,
          'data-comment-id': element.getAttribute('data-comment-id'),
        }),
      },
    ]
  },
  renderHTML({ mark, HTMLAttributes }) {
    return [
      'mark',
      mergeAttributes(
        {
          class: 'inline-comment',
          'data-comment-id': mark.attrs['data-comment-id'],
        },
        HTMLAttributes,
      ),
      0,
    ]
  },
  addCommands() {
    return {
      setComment:
        commentId =>
        ({ commands }) => {
          commands.setMeta('addToHistory', false)
          commands.setMark(this.name, { 'data-comment-id': commentId })
        },
      toggleComment:
        commentId =>
        ({ commands }) => {
          commands.setMeta('addToHistory', false)
          commands.toggleMark(this.name, { 'data-comment-id': commentId })
        },
      unsetComment:
        commentId =>
        ({ commands }) => {
          commands.setMeta('addToHistory', false)
          commands.unsetMark(this.name, { 'data-comment-id': commentId })
        },
    }
  },
  addProseMirrorPlugins() {
    const inlineCommentPlugin = new Plugin({
      key: commentPluginKey,
      state: {
        init() {
          return { focusId: null, hoverId: null }
        },
        apply(tr, prev) {
          const newState = tr.getMeta(commentPluginKey)
          // const positions = {}

          // tr.doc.descendants((node, pos) => {
          //   if (node.isText && node.marks.some(mark => mark.type.name === 'inlineComment')) {
          //     node.marks.forEach(mark => {
          //       if (mark.attrs['data-comment-id']) {
          //         positions[mark.attrs['data-comment-id']] = pos
          //       }
          //     })
          //   }
          // })

          if (newState) return { ...prev, ...newState }

          return prev
        },
      },
      props: {
        decorations(state) {
          const decorations = []
          const { focusId, hoverId } = state[commentPluginKey.key]

          if (focusId !== null) {
            state.doc.descendants((node, pos) => {
              if (node.isText && node.marks.some(mark => mark.type.name === 'inlineComment')) {
                node.marks.forEach(mark => {
                  if (mark.attrs['data-comment-id'] === focusId) {
                    const decor = Decoration.inline(pos, pos + node.nodeSize, {
                      nodeName: 'span',
                      style: 'background-color: #ffd5006b',
                    })
                    decorations.push(decor)
                  }
                })
              }
            })
          }

          if (hoverId !== null) {
            state.doc.descendants((node, pos) => {
              if (node.isText && node.marks.some(mark => mark.type.name === 'inlineComment')) {
                node.marks.forEach(mark => {
                  if (mark.attrs['data-comment-id'] === hoverId) {
                    const decor = Decoration.inline(pos, pos + node.nodeSize, {
                      nodeName: 'span',
                      style: 'background-color: #ffd5006b',
                    })
                    decorations.push(decor)
                  }
                })
              }

              // if (node.type === 'image') {
              // }
            })
          }

          return DecorationSet.create(state.doc, decorations)
        },
        handleClick: view => {
          const mark = view.state.selection.$head
            .marks()
            .find(markItem => markItem.type.name === 'inlineComment')

          if (mark && mark.type.name === 'inlineComment') {
            view.dispatch(
              view.state.tr.setMeta(commentPluginKey, {
                focusId: mark.attrs['data-comment-id'],
              }),
            )

            return false
          }

          if (view.state[commentPluginKey.key].focusId) {
            view.dispatch(view.state.tr.setMeta(commentPluginKey, { focusId: null }))

            return false
          }

          return true
        },
        handleDOMEvents: {
          mouseover(view, event) {
            const el = event.target
            if (el.tagName === 'MARK' && el.classList.contains('inline-comment')) {
              view.dispatch(
                view.state.tr.setMeta(commentPluginKey, {
                  hoverId: +el.getAttribute('data-comment-id'),
                }),
              )
            }
          },
          mouseout(view, event) {
            const el = event.relatedTarget
            if (!el || !el.closest('.inline-comment')) {
              view.dispatch(view.state.tr.setMeta(commentPluginKey, { hoverId: null }))
            }
          },
        },
      },
    })

    return [inlineCommentPlugin]
  },
})

export const CommentImage = Image.extend({
  name: 'inlineCommentImage',
  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  addCommands() {
    return {
      setCommentImage:
        commentId =>
        ({ commands }) => {
          commands.updateAttributes('image', {
            classComment: 'inline-comment',
            dataCommentId: commentId,
          })
        },
      unsetCommentImage:
        () =>
        ({ commands }) => {
          commands.setMeta('addToHistory', false)
          commands.updateAttributes('image', { classComment: null, dataCommentId: null })
        },
    }
  },
  addProseMirrorPlugins() {
    const inlineCommentImagePlugin = new Plugin({
      key: commentImagePluginKey,
      state: {
        init() {
          return { focusId: null, hoverId: null }
        },
        apply(tr, prev) {
          const newState = tr.getMeta(commentImagePluginKey)
          // const positions = {}

          // tr.doc.descendants((node, pos) => {
          //   if (node.isText && node.marks.some(mark => mark.type.name === 'inlineComment')) {
          //     node.marks.forEach(mark => {
          //       if (mark.attrs['data-comment-id']) {
          //         positions[mark.attrs['data-comment-id']] = pos
          //       }
          //     })
          //   }
          // })

          if (newState) return { ...prev, ...newState }

          return prev
        },
      },
      props: {
        decorations(state) {
          // const decorations = []
          const { focusId, hoverId } = state[commentImagePluginKey.key]

          if (focusId !== null) {
            // state.doc.descendants(node => {
            // if (node.isText && node.marks.some(mark => mark.type.name === 'inlineComment')) {
            //   node.marks.forEach(mark => {
            //     if (mark.attrs['data-comment-id'] === focusId) {
            //       const decor = Decoration.inline(pos, pos + node.nodeSize, {
            //         nodeName: 'span',
            //         style: 'background-color: #ffd5006b',
            //       })
            //       decorations.push(decor)
            //     }
            //   })
            // }
            // if(node.type === 'image' && node.attrs.some(attr => attr.type.name === 'dataCommentId')){}
            // console.log(node.attrs)
            // })
          }

          if (hoverId !== null) {
            //   state.doc.descendants((node, pos) => {
            //     if (Object.hasOwn(node.attrs, 'dataCommentId')) {
            //       if (node.attrs.dataCommentId === hoverId) {
            //         const decor = Decoration.inline(pos, pos + node.nodeSize, {
            //           nodeName: 'span',
            //           style: 'background-color: #ffd5006b',
            //         })
            //         decorations.push(decor)
            //       }
            //     }
            //   })
          }

          // return DecorationSet.create(state.doc, decorations)
        },
        // handleClick: view => {
        //   const mark = view.state.selection.$head
        //     .marks()
        //     .find(markItem => markItem.type.name === 'inlineComment')

        //   if (mark && mark.type.name === 'inlineComment') {
        //     view.dispatch(
        //       view.state.tr.setMeta(commentImagePluginKey, {
        //         focusId: mark.attrs['data-comment-id'],
        //       }),
        //     )

        //     return false
        //   }

        //   if (view.state[commentImagePluginKey.key].focusId) {
        //     view.dispatch(view.state.tr.setMeta(commentImagePluginKey, { focusId: null }))

        //     return false
        //   }

        //   return true
        // },
        handleDOMEvents: {
          mouseover(view, event) {
            const el = event.target
            if (el.tagName === 'IMG' && el.classList.contains('inline-comment')) {
              view.dispatch(
                view.state.tr.setMeta(commentImagePluginKey, {
                  hoverId: +el.getAttribute('data-comment-id'),
                }),
              )
            }
          },
          mouseout(view, event) {
            const el = event.relatedTarget
            if (!el || !el.closest('.inline-comment')) {
              view.dispatch(view.state.tr.setMeta(commentImagePluginKey, { hoverId: null }))
            }
          },
        },
      },
    })

    return [inlineCommentImagePlugin]
  },
})
