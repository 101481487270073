import { getAttributes } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
// import Tooltip from './tippyHelper'

export default function clickHandler(options) {
  // Create the tooltip instance
  // const tooltip = new Tooltip(options)

  // Initialize the tooltip
  // const { tippyModal } = tooltip.init()

  return new Plugin({
    key: new PluginKey('handleClickHyperlink'),
    props: {
      handleClick: (view, pos, event) => {
        if (event.button !== 0) {
          return false
        }

        let a = event.target
        const els = []

        while (a.nodeName !== 'DIV') {
          els.push(a)
          a = a.parentNode
        }

        if (!els.find(value => value.nodeName === 'A')) {
          return false
        }

        const attrs = getAttributes(view.state, options.type.name)
        const link = event.target

        const href = link?.href ?? attrs.href
        const target = link?.target ?? attrs.target

        if (link && href) {
          window.open(href, target)

          return true
        }

        return false
      },
      // this for modalClickHandler
      // handleClick: (view, pos, event) => {
      //   if (event.button !== 0) return false

      //   // Get the target HTML element and its position
      //   const nodeTarget = event.target
      //   const nodePos = view.posAtDOM(nodeTarget, 0)

      //   // Find the closest link element to the target element
      //   const link = nodeTarget?.closest('a')

      //   // Extract attributes from the state
      //   const attrs = getAttributes(view.state, options.type.name)

      //   // Extract href and target attributes from the link element or the state
      //   const href = link?.href ?? attrs.href
      //   const target = link?.target ?? attrs.target

      //   // If there is no previewHyperlink modal provided, then open the link in new window
      //   if (!options.modal) {
      //     if (link && href) {
      //       window.open(href, target)
      //     }

      //     return true
      //   }

      //   // if the link does not contain href attribute, hide the tooltip
      //   if (!link?.href) return tooltip.hide()

      //   // Create a preview of the hyperlink
      //   const hyperlinkPreview = options.modal({
      //     link,
      //     nodePos,
      //     tippy: tooltip,
      //     ...options,
      //   })

      //   // If there is no hyperlink preview, hide the modal
      //   if (!hyperlinkPreview) return tooltip.hide()

      //   // Empty the modal and append the hyperlink preview box

      //   while (tippyModal.firstChild) {
      //     tippyModal.removeChild(tippyModal.firstChild)
      //   }

      //   tippyModal.append(hyperlinkPreview)

      //   // Update the modal position
      //   tooltip.update(options.editor.view, nodePos)

      //   return false
      // },
    },
  })
}
