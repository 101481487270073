import gql from 'graphql-tag'

export const updateRelationJobAndDocument = gql`
  mutation UpdateRelationJobAndDocument(
    $workspaceId: Float!
    $documentUuids: [String!]!
    $jobId: Float!
  ) {
    updateRelationJobAndDocument(
      workspace_id: $workspaceId
      document_uuids: $documentUuids
      job_id: $jobId
    ) {
      status
      msg
      data
    }
  }
`
