import errorHandling from '@/utils/errorHandling'
import { uploadMedia } from './upload'

const host = 'https://oriensstaging.oriens.my.id'

export const handleImagePaste = (view, file, documentId) => {
  const uri = window.URL || window.webkitURL
  const img = new Image()
  img.src = uri.createObjectURL(file)
  const { schema } = view.state
  const $pos = view.state.tr.selection.$anchor
  const { depth } = $pos
  const from = $pos.before(depth)
  const to = $pos.after(depth)

  img.onload = () => {
    uploadMedia(file, documentId, progressEvent => {
      const uploadProgress = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100),
        10,
      )
      const loadingNode = schema.nodes.loading.create({
        content: `Image Uploading... ${progressEvent.loaded}/${progressEvent.total} (${uploadProgress}%)`,
      })
      const loadingTransaction = view.state.tr.insert($pos.pos, loadingNode)

      view.dispatch(loadingTransaction)
      view.dispatch(view.state.tr.delete(from, to).scrollIntoView())
    })
      .then(({ data }) => {
        const imgUri = data.data.destination.slice('assets/'.length)
        const fileName = data.data.originalname
        const url = `${host}/${imgUri}/${fileName}`

        const node = schema.nodes.image.create({ src: url })
        const transaction = view.state.tr.insert($pos.pos, node)

        view.dispatch(transaction)

        view.dispatch(view.state.tr.delete(from, to).scrollIntoView())
      })
      .catch(err => {
        errorHandling(err)
      })
  }
}

export const handleVideoPaste = (view, file, documentId) => {
  const { schema } = view.state
  const $pos = view.state.tr.selection.$anchor
  const { depth } = $pos
  const from = $pos.before(depth)
  const to = $pos.after(depth)

  uploadMedia(file, documentId, progressEvent => {
    const uploadProgress = parseInt(
      Math.round((progressEvent.loaded / progressEvent.total) * 100),
      10,
    )
    const loadingNode = schema.nodes.loading.create({
      content: `Video Uploading... ${progressEvent.loaded}/${progressEvent.total} (${uploadProgress}%)`,
    })

    const loadingTransaction = view.state.tr.insert($pos.pos, loadingNode)

    view.dispatch(loadingTransaction)
    view.dispatch(view.state.tr.delete(from, to).scrollIntoView())
  })
    .then(({ data }) => {
      const path = data.data.destination.slice('assets/'.length)
      const fileName = data.data.originalname
      const url = `${host}/${path}/${fileName}`
      const videoNode = schema.nodes.video.create({ src: url })
      const replaceTransaction = view.state.tr.insert($pos.pos, videoNode)

      view.dispatch(replaceTransaction)

      view.dispatch(view.state.tr.delete(from, to).scrollIntoView())
    })
    .catch(err => {
      errorHandling(err)
    })
}
