<template>
  <v-menu
    offset-y
    :open-on-hover="true"
  >
    <template #activator="menu">
      <v-btn
        class="px-3"
        elevation="0"
        v-bind="{ ...menu.attrs }"
        style="border-top-left-radius: 0; border-bottom-left-radius: 0"
        v-on="{ ...menu.on }"
      >
        Font-Family
        <v-icon size="26">
          {{ icons.mdiChevronDown }}
        </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-subheader> Font Family </v-subheader>
      <div
        v-for="font in fontFamily"
        :key="font.name"
      >
        <v-list-item
          color="primary"
          :input-value="font.active"
          @click="setFont(font.class)"
        >
          <v-list-item-content>
            <v-list-item-title :style="{ fontFamily: font.class }">
              {{ font.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { computed } from 'vue'
import { mdiChevronDown } from '@mdi/js'

export default {
  props: {
    editor: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const fontFamily = computed(() => [
      {
        name: 'Default',
        class: 'Default',
        active:
          !props.editor.isActive('textStyle') || props.editor.isActive({ fontFamily: 'Default' }),
      },
      {
        name: 'Inter',
        class: 'Inter',
        active: props.editor.isActive({ fontFamily: 'Inter' }),
      },
      {
        name: 'Comic Sans',
        class: 'Comic Sans MS, Comic Sans',
        active: props.editor.isActive({ fontFamily: 'Comic Sans MS, Comic Sans' }),
      },
      {
        name: 'Serif',
        class: 'serif',
        active: props.editor.isActive({ fontFamily: 'serif' }),
      },
      {
        name: 'Monospace',
        class: 'monospace',
        active: props.editor.isActive({ fontFamily: 'monospace' }),
      },
      {
        name: 'Cursive',
        class: 'cursive',
        active: props.editor.isActive({ fontFamily: 'cursive' }),
      },
    ])

    const setFont = font => {
      props.editor.chain().focus().setFontFamily(font).blur().run()
    }

    return {
      fontFamily,
      setFont,

      icons: {
        mdiChevronDown,
      },
    }
  },
}
</script>
