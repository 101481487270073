import { Extension } from '@tiptap/core'

import { Column } from './column'
import { Columns } from './columns'

export const ColumnExtension = Extension.create({
  name: 'columnExtension',

  addExtensions() {
    const extensions = []

    extensions.push(Column)
    extensions.push(Columns)

    return extensions
  },
})

export { Column, Columns }

export default ColumnExtension
