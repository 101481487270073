import {
  mdiTableColumn,
  mdiTableColumnPlusBefore,
  mdiTableColumnRemove,
  mdiTableHeadersEye,
  mdiTableMergeCells,
  mdiTableRow,
  mdiTableRowPlusAfter,
  mdiTableRowPlusBefore,
  mdiTableRowRemove,
} from '@mdi/js'

export function tableRowTools() {
  return [
    {
      title: 'Add row up',
      name: 'addRowBefore',
      icon: mdiTableRowPlusBefore,
      command: editor => {
        editor.chain().focus().addRowBefore().run()
      },
    },
    {
      title: 'Add row down',
      name: 'addRowAfter',
      icon: mdiTableRowPlusAfter,
      command: editor => {
        console.log('Add row after')
        editor.commands.addRowAfter()
      },
    },
    {
      title: 'Delete row',
      name: 'deleteRow',
      icon: mdiTableRowRemove,
      command: editor => {
        console.log('Delete row')
        editor.commands.deleteRow()
      },
    },
  ]
}
export function tableColumnTools() {
  return [
    {
      title: 'Add column left',
      name: 'addColumnBefore',
      icon: mdiTableColumnPlusBefore,
      command: editor => {
        editor.commands.addColumnBefore()
      },
    },
    {
      title: 'Add column right',
      name: 'addColumnAfter',
      icon: mdiTableColumnPlusBefore,
      command: editor => {
        editor.commands.addColumnAfter()
      },
    },

    {
      title: 'Delete column',
      name: 'deleteColumn',
      icon: mdiTableColumnRemove,
      command: editor => {
        editor.commands.deleteColumn()
      },
    },
  ]
}
export function tableCellTools() {
  return [
    {
      title: 'Merge cells',
      name: 'mergeCells',
      icon: mdiTableMergeCells,
      command: editor => {
        editor.chain().focus().mergeOrSplit().run()
      },
    },
    {
      title: 'Toggle header cells',
      name: 'headerCells',
      icon: mdiTableHeadersEye,
      command: editor => {
        editor.chain().focus().toggleHeaderCell().run()
      },
    },
    {
      title: 'Toggle column header',
      name: 'toggleHeaderColumn',
      desc: 'Toggle header in column',
      icon: mdiTableColumn,
      command: editor => {
        editor.commands.toggleHeaderColumn()
      },
    },
    {
      title: 'Toggle row header',
      name: 'toggleHeaderRow',
      desc: 'Toggle header in row',
      icon: mdiTableRow,
      command: editor => {
        editor.commands.toggleHeaderRow()
      },
    },
  ]
}
