import { Node, mergeAttributes } from '@tiptap/core'
import { Selection } from 'prosemirror-state'

export const Callout = Node.create({
  name: 'callout',
  group: 'block',
  content: 'inline*',
  defining: true,
  draggable: true,
  marks: '',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  parseHTML() {
    return [{ tag: 'callout' }]
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'callout',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      ['div', { class: 'callout-emoji' }],
      ['div', { class: 'callout-content' }],
      ['div', 0],
    ]
  },
  addCommands() {
    return {
      setCallout:
        () =>
        ({ commands }) =>
          commands.setNode(this.name),
    }
  },
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state, dispatch }) => {
          const { selection } = state
          const { empty, anchor } = selection

          if (!empty) {
            return false
          }

          const $pos = state.doc.resolve(anchor)
          const nodeAfter = $pos.nodeAfter

          // Check if the cursor is at the start of the callout node
          if ($pos.parent.type.name === this.name && $pos.parentOffset === 0) {
            if ($pos.before() - 1 >= 0) {
              dispatch(tr.delete($pos.before() - 1, $pos.after()))

              return true
            }
          }

          // Check if the cursor is at the end of a node and the next node is a callout
          if (nodeAfter && nodeAfter.type.name === this.name && $pos.textOffset === 0) {
            dispatch(tr.delete($pos.pos, $pos.pos + nodeAfter.nodeSize))

            return true
          }

          return false
        }),
      ArrowUp: () =>
        this.editor.commands.command(({ state, dispatch }) => {
          const { selection } = state
          const { empty, anchor } = selection

          if (!empty) {
            return false
          }

          const $pos = state.doc.resolve(anchor)
          console.log($pos)

          // Check if the cursor is at the start of the callout node
          if ($pos.parent.type.name === this.name && $pos.parentOffset === 0) {
            const resolvedPos = $pos.before($pos.depth) - 1
            if (resolvedPos >= 0) {
              const newSelection = Selection.near(state.doc.resolve(resolvedPos), -1)
              dispatch(state.tr.setSelection(newSelection))
            }

            return true
          }

          return false
        }),
    }
  },
})
