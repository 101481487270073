<template>
  <v-menu
    v-model="isOpen"
    offset-y
    nudge-bottom="40"
    right
    min-width="500px"
    attach="#toolbarMenu"
    transition="slide-x-transition"
    :close-on-content-click="false"
    :close-on-click="false"
  >
    <template #activator="menu">
      <v-btn
        class="px-3"
        elevation="0"
        v-bind="{ ...menu.attrs }"
        style="border-top-left-radius: 0; border-bottom-left-radius: 0"
        v-on="{ ...menu.on }"
        @click="handleStartComment"
      >
        Comment
      </v-btn>
    </template>
    <v-card
      outlined
      width="500px"
    >
      <comment-input
        v-if="isOpen"
        :content="content"
        :document-id="documentId"
        @canceled="cancelComment"
        @close="handleSubmitComment"
      />
    </v-card>
  </v-menu>
</template>

<script>
import { ref } from 'vue'
import CommentInput from './comment/CommentInput.vue'
import { TextSelection, NodeSelection } from 'prosemirror-state'

export default {
  components: {
    CommentInput,
  },
  props: {
    editor: {
      required: true,
      type: Object,
    },
    documentId: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const content = ref('')
    const isOpen = ref(false)
    const textSelection = ref(null)
    const nodeSelection = ref(null)

    const handleStartComment = () => {
      if (props.editor.state.selection instanceof TextSelection) {
        textSelection.value = props.editor.state.selection
        props.editor.chain().focus().setComment().blur().run()
      }

      if (props.editor.state.selection instanceof NodeSelection) {
        nodeSelection.value = props.editor.state.selection
        props.editor.chain().focus().setCommentImage().blur().run()
      }
    }

    const handleSubmitComment = id => {
      isOpen.value = false
      if (props.editor.state.selection instanceof TextSelection) {
        props.editor
          .chain()
          .setTextSelection(textSelection.value)
          .setComment(id)
          .setTextSelection(textSelection.value.from)
          .run()
      }

      if (props.editor.state.selection instanceof NodeSelection) {
        props.editor
          .chain()
          .setTextSelection(nodeSelection.value)
          .setCommentImage(id)
          .setTextSelection(nodeSelection.value.from)
          .run()
      }
    }

    const cancelComment = () => {
      if (
        textSelection.value &&
        !textSelection.value.$head.marks().find(markItem => markItem.type.name === 'inlineComment')
      ) {
        props.editor
          .chain()
          .setTextSelection(textSelection.value)
          .unsetComment()
          .setTextSelection(textSelection.value.from)
          .run()
      }

      if (nodeSelection.value) {
        props.editor
          .chain()
          .setTextSelection(nodeSelection.value)
          .unsetCommentImage()
          .setTextSelection(nodeSelection.value.from)
          .run()
      }
    }

    return {
      isOpen,
      content,
      handleSubmitComment,
      handleStartComment,
      cancelComment,
    }
  },
}
</script>
