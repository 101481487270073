import { Node, mergeAttributes } from '@tiptap/core'
import BookmarkComponent from '../../tools/vueNodeView/BookmarkComponent.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

export const Bookmark = Node.create({
  name: 'bookmark',
  atom: true,
  draggable: true,
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'bookmark',
      },
      inline: false,
    }
  },
  inline() {
    return this.options.inline
  },
  group() {
    return this.options.inline ? 'inline' : 'block'
  },
  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: element => ({ src: element.getAttribute('src') }),
        renderHTML: attributes => ({
          src: attributes.src,
        }),
      },
      img: {
        default: null,
        parseHTML: element => ({ img: element.getAttribute('img') }),
        renderHTML: attributes => ({
          img: attributes.img,
        }),
      },
      title: {
        default: null,
        parseHTML: element => ({ title: element.getAttribute('title') }),
        renderHTML: attributes => ({
          title: attributes.title,
        }),
      },
      description: {
        default: null,
        parseHTML: element => ({ description: element.getAttribute('description') }),
        renderHTML: attributes => ({
          description: attributes.description,
        }),
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'div',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes)]
  },
  addNodeView() {
    return VueNodeViewRenderer(BookmarkComponent)
  },
  addCommands() {
    return {
      setBookmark:
        options =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: options,
          }),
    }
  },
})
