<template>
  <v-menu
    ref="inlineMenu"
    offset-y
    open-on-hover
  >
    <template #activator="menu">
      <v-tooltip
        top
        :open-on-hover="false"
        :open-on-click="false"
        :value="$refs.inlineMenu && $refs.inlineMenu.isActive"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="px-0 mx-0"
            elevation="0"
            min-width="48"
            max-width="48"
            tile
            v-bind="{ ...menu.attrs, ...attrs }"
            v-on="{ ...menu.on, ...on }"
          >
            <v-icon size="26">
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        Others
      </v-tooltip>
    </template>
    <v-list
      dense
      class="pt-1"
    >
      <v-subheader class="ms-2"> Inline Tools </v-subheader>
      <div
        v-for="tool in inlineTools"
        :key="tool.label"
      >
        <v-list-item
          :input-value="tool.active"
          color="primary"
          @click="tool.command"
        >
          <v-list-item-avatar
            tile
            size="24"
          >
            <v-icon>
              {{ tool.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ tool.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { computed } from 'vue'
import {
  mdiChevronDown,
  mdiFormatStrikethrough,
  mdiFormatSubscript,
  mdiCodeTags,
  mdiFormatSuperscript,
} from '@mdi/js'

export default {
  props: {
    editor: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const inlineTools = computed(() => [
      {
        label: 'Strikethrough',
        icon: mdiFormatStrikethrough,
        active: props.editor.isActive('strike'),
        command: () => props.editor.chain().focus().toggleStrike().blur().run(),
      },
      {
        label: 'Inline code',
        icon: mdiCodeTags,
        active: props.editor.isActive('code'),
        command: () => props.editor.chain().focus().toggleCode().blur().run(),
      },
      {
        label: 'Subscript',
        icon: mdiFormatSubscript,
        active: props.editor.isActive('subscript'),
        command: () => props.editor.chain().focus().toggleSubscript().blur().run(),
      },
      {
        label: 'Superscript',
        icon: mdiFormatSuperscript,
        active: props.editor.isActive('superscript'),
        command: () => props.editor.chain().focus().toggleSuperscript().blur().run(),
      },
    ])

    return {
      inlineTools,

      icons: {
        mdiChevronDown,
      },
    }
  },
}
</script>
