<template>
  <v-menu
    v-model="editMode"
    max-width="300"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
        :editMode="editMode"
        :handleOpen="handleOpen"
      >
        <v-btn
          text
          x-small
          v-on="on"
          v-bind="attrs"
          :block="block"
          :plain="block"
          v-ripple="!block"
          elevation="0"
          class="font-weight-regular"
          :class="block ? 'justify-start px-0 date-input-button' : ''"
          @click.prevent.stop="handleOpen()"
        >
          <slot :handleOpen="handleOpen">
            <span @click.prevent.stop="handleOpen()">{{ value ? dateFormat(value, 3) : '-' }}</span>
          </slot>
        </v-btn>
      </slot>
    </template>

    <DateTimeSelect
      v-model="localValue"
      @reset="localValue = value"
    />
  </v-menu>
</template>

<script setup>
import { ref, watch } from 'vue'
import DateTimeSelect from './components/DateTimeSelect.vue'
import dateFormat from '@/utils/dateFormat'
import errorHandling from '@/utils/errorHandling'

const emit = defineEmits(['input'])
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  block: {
    type: Boolean,
    default: false,
  },

  /* ? This validator props are used to determine whether the dialog is alowed to be open or not
   *   if this props is not present, validator is ignored and dialog would always open
   *
   *   @example => :validator="job_type.folder_permission[0].permission_type === 'Admin'"
   */
  validator: {
    type: Boolean,
    default: undefined,
  },
})

let storePrevious = props.value
const localValue = ref(props.value)
const editMode = ref(false)

const handleOpen = e => {
  console.log(props.validator)
  console.log(e)

  if (props.validator === undefined || props.validator) editMode.value = true
  else if (props.validator === false)
    errorHandling('Anda tidak memiliki Permission untuk melakukan aksi ini!')
}

watch(editMode, val => {
  if (props.validator === false) return (editMode.value = false)

  if (!val && localValue.value !== storePrevious) {
    emit('input', localValue.value)

    storePrevious = localValue.value
  }
})
</script>

<style lang="scss">
.date-input-button {
  .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
