import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core'

import { getEmbedUrlFromYoutubeUrl, isValidYoutubeUrl, YOUTUBE_REGEX_GLOBAL } from './utils.js'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import YoutubeNodeComponent from '../../tools/vueNodeView/YoutubeNodeComponent.vue'

/**
 * This extension adds support for youtube videos.
 * @see https://www.tiptap.dev/api/nodes/youtube
 */
export const Youtube = Node.create({
  name: 'youtube',

  addOptions() {
    return {
      addPasteHandler: true,
      allowFullscreen: true,
      autoplay: false,
      ccLanguage: undefined,
      ccLoadPolicy: undefined,
      controls: true,
      disableKBcontrols: false,
      enableIFrameApi: false,
      endTime: 0,
      height: 480,
      interfaceLanguage: undefined,
      ivLoadPolicy: 0,
      loop: false,
      modestBranding: false,
      HTMLAttributes: {},
      inline: false,
      nocookie: false,
      origin: '',
      playlist: '',
      progressBarColor: undefined,
      width: 640,
    }
  },

  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block'
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      start: {
        default: 0,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
      style: {
        default: `width: ${this.options.width}px; ${this.options.height}px; cursor: pointer;`,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-youtube-video] iframe',
      },
    ]
  },

  addCommands() {
    return {
      setYoutubeVideo:
        options =>
        ({ commands }) => {
          if (!isValidYoutubeUrl(options.src)) {
            return false
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          })
        },
    }
  },

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return []
    }

    return [
      nodePasteRule({
        find: YOUTUBE_REGEX_GLOBAL,
        type: this.type,
        getAttributes: match => {
          return { src: match.input }
        },
      }),
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    const embedUrl = getEmbedUrlFromYoutubeUrl({
      url: HTMLAttributes.src,
      allowFullscreen: this.options.allowFullscreen,
      autoplay: this.options.autoplay,
      ccLanguage: this.options.ccLanguage,
      ccLoadPolicy: this.options.ccLoadPolicy,
      controls: this.options.controls,
      disableKBcontrols: this.options.disableKBcontrols,
      enableIFrameApi: this.options.enableIFrameApi,
      endTime: this.options.endTime,
      interfaceLanguage: this.options.interfaceLanguage,
      ivLoadPolicy: this.options.ivLoadPolicy,
      loop: this.options.loop,
      modestBranding: this.options.modestBranding,
      nocookie: this.options.nocookie,
      origin: this.options.origin,
      playlist: this.options.playlist,
      progressBarColor: this.options.progressBarColor,
      startAt: HTMLAttributes.start || 0,
    })

    HTMLAttributes.src = embedUrl

    return [
      'div',
      { 'data-youtube-video': '' },
      [
        'iframe',
        mergeAttributes(
          this.options.HTMLAttributes,
          {
            style: node.attrs.style,
            allowfullscreen: this.options.allowFullscreen,
            autoplay: this.options.autoplay,
            ccLanguage: this.options.ccLanguage,
            ccLoadPolicy: this.options.ccLoadPolicy,
            disableKBcontrols: this.options.disableKBcontrols,
            enableIFrameApi: this.options.enableIFrameApi,
            endTime: this.options.endTime,
            interfaceLanguage: this.options.interfaceLanguage,
            ivLoadPolicy: this.options.ivLoadPolicy,
            loop: this.options.loop,
            modestBranding: this.options.modestBranding,
            origin: this.options.origin,
            playlist: this.options.playlist,
            progressBarColor: this.options.progressBarColor,
          },
          HTMLAttributes,
        ),
      ],
    ]
  },
  addNodeView() {
    return VueNodeViewRenderer(YoutubeNodeComponent)
  },

  // addNodeView() {
  //   return ({ HTMLAttributes }) => {
  //     const embedUrl = getEmbedUrlFromYoutubeUrl({
  //       url: HTMLAttributes.src,
  //       allowFullscreen: this.options.allowFullscreen,
  //       autoplay: this.options.autoplay,
  //       ccLanguage: this.options.ccLanguage,
  //       ccLoadPolicy: this.options.ccLoadPolicy,
  //       controls: this.options.controls,
  //       disableKBcontrols: this.options.disableKBcontrols,
  //       enableIFrameApi: this.options.enableIFrameApi,
  //       endTime: this.options.endTime,
  //       interfaceLanguage: this.options.interfaceLanguage,
  //       ivLoadPolicy: this.options.ivLoadPolicy,
  //       loop: this.options.loop,
  //       modestBranding: this.options.modestBranding,
  //       nocookie: this.options.nocookie,
  //       origin: this.options.origin,
  //       playlist: this.options.playlist,
  //       progressBarColor: this.options.progressBarColor,
  //       startAt: HTMLAttributes.start || 0,
  //     })

  //     HTMLAttributes.src = embedUrl
  //   }
  // },
})
