import { h } from 'jsx-dom-cjs'
import tippy from 'tippy.js'

import { CellSelection, TableMap, updateColumnsOnResize } from 'prosemirror-tables'

import {
  mdiTableColumnPlusBefore,
  mdiTableColumnPlusAfter,
  mdiTableColumnRemove,
  mdiTableRowPlusAfter,
  mdiTableRowPlusBefore,
  mdiTableRowRemove,
} from '@mdi/js'

export function updateColumns(node, colgroup, table, cellMinWidth, overrideCol, overrideValue) {
  let totalWidth = 0
  let fixedWidth = true
  let nextDOM = colgroup.firstChild
  const row = node.firstChild

  if (!row) return

  for (let i = 0, col = 0; i < row.childCount; i += 1) {
    const { colspan, colwidth } = row.child(i).attrs

    for (let j = 0; j < colspan; j += 1, col += 1) {
      const hasWidth = overrideCol === col ? overrideValue : colwidth && colwidth[j]
      const cssWidth = hasWidth ? `${hasWidth}px` : ''

      totalWidth += hasWidth || cellMinWidth

      if (!hasWidth) {
        fixedWidth = false
      }

      if (!nextDOM) {
        colgroup.appendChild(document.createElement('col')).style.width = cssWidth
      } else {
        if (nextDOM.style.width !== cssWidth) {
          nextDOM.style.width = cssWidth
        }

        nextDOM = nextDOM.nextSibling
      }
    }
  }

  while (nextDOM) {
    const after = nextDOM.nextSibling

    nextDOM.parentNode?.removeChild(nextDOM)
    nextDOM = after
  }

  if (fixedWidth) {
    table.style.width = `${totalWidth}px`
    table.style.minWidth = ''
  } else {
    table.style.width = ''
    table.style.minWidth = `${totalWidth}px`
  }
}

const defaultTippyOptions = {
  allowHTML: true,
  arrow: false,
  trigger: 'click',
  animation: 'scale-subtle',
  theme: `tippy-box-custom no-padding`,
  interactive: true,
  hideOnClick: true,
  placement: 'right',
}

const columnsToolboxItems = [
  {
    label: 'Add column left',
    icon: mdiTableColumnPlusBefore,
    action: ({ editor }) => editor.chain().focus().addColumnBefore().run(),
  },
  {
    label: 'Add Column Right',
    icon: mdiTableColumnPlusAfter,
    action: ({ editor }) => editor.chain().focus().addColumnAfter().run(),
  },
  {
    label: 'Delete Column',
    icon: mdiTableColumnRemove,
    action: ({ editor }) => editor.chain().focus().deleteColumn().run(),
  },
]

const rowsToolboxItems = [
  {
    label: 'Add Row Up',
    icon: mdiTableRowPlusBefore,
    action: ({ editor }) => editor.chain().focus().addRowBefore().run(),
  },
  {
    label: 'Add Row Down',
    icon: mdiTableRowPlusAfter,
    action: ({ editor }) => editor.chain().focus().addRowAfter().run(),
  },
  {
    label: 'Delete Row',
    icon: mdiTableRowRemove,
    action: ({ editor }) => editor.chain().focus().deleteRow().run(),
  },
]

function createToolbox({ triggerButton, items, tippyOptions, onClickItem }) {
  const vCard = h(
    'div',
    { className: `v-card v-sheet` },
    h(
      'div',
      { role: 'list', className: `v-list pt-1 v-sheet v-list--dense` },
      h('div', { className: `v-subheader ms-1` }, 'Row Action'),

      items.map(item =>
        h(
          'div',
          {
            tabindex: '0',
            role: 'listitem',
            className: `v-list-item v-list-item--link`,
            onClick() {
              onClickItem(item)
            },
          },
          h(
            'div',
            { className: 'v-list-item__icon' },
            h(
              'span',
              { 'aria-hidden': 'true', className: `v-icon notranslate` },
              h(
                'svg',
                {
                  xmlns: 'http://www.w3.org/2000/svg',
                  viewBox: '0 0 24 24',
                  role: 'img',
                  'aria-hidden': 'true',
                  className: 'v-icon__svg',
                },
                h('path', {
                  d: item.icon,
                }),
              ),
            ),
          ),
          h(
            'div',
            { className: 'v-list-item__content' },
            h('div', { className: 'v-list-item__title' }, item.label),
          ),
        ),
      ),
    ),
  )

  const toolbox = tippy(triggerButton, {
    // content: h(
    //   'div',
    //   { className: 'tableToolbox' },
    //   items.map(item =>
    //     h(
    //       'div',
    //       {
    //         className: 'toolboxItem',
    //         onClick() {
    //           onClickItem(item)
    //         },
    //       },
    //       [
    //         h(
    //           'div',
    //           {
    //             className: 'iconContainer',
    //           },
    //           [
    //             h('svg', {}, [
    //               h('path', {
    //                 d: item.icon,
    //               }),
    //             ]),
    //           ],
    //         ),
    //         h('div', { className: 'label' }, item.label),
    //       ],
    //     ),
    //   ),
    // ),
    content: vCard,
    ...tippyOptions,
  })

  return Array.isArray(toolbox) ? toolbox[0] : toolbox
}

export class TableView {
  get dom() {
    return this.root
  }

  get contentDOM() {
    return this.tbody
  }

  constructor(node, cellMinWidth, decorations, editor, getPos) {
    this.node = node
    this.cellMinWidth = cellMinWidth
    this.decorations = decorations
    this.editor = editor
    this.getPos = getPos
    this.hoveredCell = null
    this.map = TableMap.get(node)

    /**
     * DOM
     */

    // Controllers
    if (editor.isEditable) {
      this.rowsControl = h(
        'div',
        { className: 'rowsControl' },
        h('button', {
          onClick: () => this.selectRow(),
        }),
      )

      this.columnsControl = h(
        'div',
        { className: 'columnsControl' },
        h('button', {
          onClick: () => this.selectColumn(),
        }),
      )

      this.controls = h(
        'div',
        { className: 'tableControls', contentEditable: 'false' },
        this.rowsControl,
        this.columnsControl,
      )

      this.columnsToolbox = createToolbox({
        triggerButton: this.columnsControl.querySelector('button'),
        items: columnsToolboxItems,
        tippyOptions: {
          ...defaultTippyOptions,
          appendTo: this.controls,
        },
        onClickItem: item => {
          item.action({
            editor: this.editor,
            triggerButton: this.columnsControl.firstElementChild,
            controlsContainer: this.controls,
          })
          this.columnsToolbox.hide()
        },
      })

      this.rowsToolbox = createToolbox({
        triggerButton: this.rowsControl.firstElementChild,
        items: rowsToolboxItems,
        tippyOptions: {
          ...defaultTippyOptions,
          appendTo: this.controls,
        },
        onClickItem: item => {
          item.action({
            editor: this.editor,
            triggerButton: this.rowsControl.firstElementChild,
            controlsContainer: this.controls,
          })
          this.rowsToolbox.hide()
        },
      })
    }

    // Table

    this.colgroup = h(
      'colgroup',
      null,
      Array.from({ length: this.map.width }, () => 1).map(() => h('col')),
    )
    this.tbody = h('tbody')
    this.table = h('table', null, this.colgroup, this.tbody)

    this.root = h(
      'div',
      {
        className: 'tableWrapper controls--disabled',
      },
      this.controls,
      this.table,
    )

    this.render()
  }

  update(node, decorations) {
    if (node.type !== this.node.type) {
      return false
    }

    this.node = node
    this.decorations = decorations
    this.map = TableMap.get(this.node)

    if (this.editor.isEditable) {
      this.updateControls()
    }

    this.render()

    return true
  }

  render() {
    if (this.colgroup.children.length !== this.map.width) {
      const cols = Array.from({ length: this.map.width }, () => 1).map(() => h('col'))
      this.colgroup.replaceChildren(...cols)
    }

    updateColumnsOnResize(this.node, this.colgroup, this.table, this.cellMinWidth)
  }

  ignoreMutation() {
    return true
  }

  updateControls() {
    const { hoveredTable: table, hoveredCell: cell } = Object.values(this.decorations).reduce(
      (acc, curr) => {
        if (curr.spec.hoveredCell !== undefined) {
          acc['hoveredCell'] = curr.spec.hoveredCell
        }

        if (curr.spec.hoveredTable !== undefined) {
          acc['hoveredTable'] = curr.spec.hoveredTable
        }

        return acc
      },
      {},
    )

    if (table === undefined || cell === undefined) {
      return this.root.classList.add('controls--disabled')
    }

    this.root.classList.remove('controls--disabled')
    this.hoveredCell = cell

    const cellDom = this.editor.view.nodeDOM(cell.pos)

    const tableRect = this.table.getBoundingClientRect()
    const cellRect = cellDom.getBoundingClientRect()

    this.columnsControl.style.left = `${
      cellRect.left - tableRect.left - this.table.parentElement.scrollLeft
    }px`
    this.columnsControl.style.width = `${cellRect.width}px`

    this.rowsControl.style.top = `${cellRect.top - tableRect.top}px`
    this.rowsControl.style.height = `${cellRect.height}px`
  }

  selectColumn() {
    if (!this.hoveredCell) return

    const colIndex = this.map.colCount(this.hoveredCell.pos - (this.getPos() + 1))
    const anchorCellPos = this.hoveredCell.pos
    const headCellPos =
      this.map.map[colIndex + this.map.width * (this.map.height - 1)] + (this.getPos() + 1)

    const cellSelection = CellSelection.create(
      this.editor.view.state.doc,
      anchorCellPos,
      headCellPos,
    )
    this.editor.view.dispatch(
      // @ts-ignore
      this.editor.state.tr.setSelection(cellSelection),
    )
  }

  selectRow() {
    if (!this.hoveredCell) return

    const anchorCellPos = this.hoveredCell.pos
    const anchorCellIndex = this.map.map.indexOf(anchorCellPos - (this.getPos() + 1))
    const headCellPos = this.map.map[anchorCellIndex + (this.map.width - 1)] + (this.getPos() + 1)

    const cellSelection = CellSelection.create(this.editor.state.doc, anchorCellPos, headCellPos)
    this.editor.view.dispatch(
      // @ts-ignore
      this.editor.view.state.tr.setSelection(cellSelection),
    )
  }
}
