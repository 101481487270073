<template>
  <node-view-wrapper
    class="vue-component dom"
    :status="node.attrs?.status"
  >
    <div v-if="node.attrs.url">
      <audio
        controls
        style="width: 100%"
        class="audioComponent"
      >
        <source :src="node.attrs.url" />
      </audio>
    </div>
    <div v-else>
      <upload-component
        :loading="isUploading"
        :input-file="inputFile"
        :icon="mdiVolumeHigh"
        :upload-loaded="uploadLoaded"
        :upload-total="uploadTotal"
        :upload-progress="uploadProgress"
        upload-type="audio"
        @submit="uploadFile"
        @set-inputFile="inputFile = $event"
      />
    </div>
  </node-view-wrapper>
</template>
<script setup>
import { mdiVolumeHigh } from '@mdi/js'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { ref } from 'vue'
import { uploadMedia } from '../../utils/upload'
import UploadComponent from './UploadComponent.vue'

const props = defineProps(nodeViewProps)
const inputFile = ref()
const isUploading = ref(false)
const uploadLoaded = ref(0)
const uploadTotal = ref(0)
const uploadProgress = ref(0)

const uploadFile = () => {
  const [, , , documentId] = window.location.href.split('/')
  const file = inputFile.value
  const host = process.env.VUE_APP_BACKEND_URL

  uploadMedia(file, documentId, progressEvent => {
    isUploading.value = true
    uploadLoaded.value = progressEvent.loaded
    uploadTotal.value = progressEvent.total
    uploadProgress.value = parseInt(
      Math.round((progressEvent.loaded / progressEvent.total) * 100),
      10,
    )
  })
    .then(response => {
      const fileUrl = response.data.data.destination.slice('assets/'.length)
      const fileName = response.data.data.originalname
      const encodedFileName = encodeURIComponent(fileName)
      const url = `${host}/${fileUrl}/${encodedFileName}`

      props.updateAttributes({
        url,
      })

      isUploading.value = false
    })
    .catch(error => {
      console.error('Error uploading image:', error)
      isUploading.value = false
    })
}
</script>
<style lang=""></style>
