import gql from 'graphql-tag'

export const editCustomAttributeValue = gql`
  mutation editCustomAttributeValue(
    $jobId: Float!
    $values: [customAttributeValues!]!
    $workspace_id: Float!
  ) {
    editCustomAttributeValue(job_id: $jobId, values: $values, workspace_id: $workspace_id) {
      status
      msg
      data
    }
  }
`
