import { ref } from 'vue'

export default () => {
  const documents = ref([])

  const onDragOver = event => {
    event.preventDefault()
    if (!event.currentTarget.classList.contains('dragBorder')) {
      event.currentTarget.classList.add('dragBorder')
    }
  }

  const onDragLeave = event => {
    event.currentTarget.classList.remove('dragBorder')
  }

  const onDrop = event => {
    event.preventDefault()
    for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
      const preview = URL.createObjectURL(event.dataTransfer.files[i])
      documents.value.push({
        file: event.dataTransfer.files[i],
        preview,
      })
    }
    event.currentTarget.classList.remove('dragBorder')
  }

  const onFileChange = event => {
    for (let i = 0; i < event.target.files.length; i += 1) {
      const preview = URL.createObjectURL(event.target.files[i])
      documents.value.push({
        file: event.target.files[i],
        preview,
      })
    }
  }

  const deleteDoc = index => {
    documents.value.splice(index, 1)
  }

  return {
    documents,
    onDragOver,
    onDragLeave,
    onDrop,
    onFileChange,
    deleteDoc,
  }
}
