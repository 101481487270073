// tiptap extension
import Blockquote from '@tiptap/extension-blockquote'
import BulletList from '@tiptap/extension-bullet-list'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import Dropcursor from '@tiptap/extension-dropcursor'
import FontFamily from '@tiptap/extension-font-family'
import Heading from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
// import HorizontalRule from '@tiptap/extension-horizontal-rule'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import StarterKit from '@tiptap/starter-kit'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import { common, createLowlight } from './lowlight'

// custom extensions
import { Hyperlink, previewHyperlinkModal, setHyperlinkModal } from './hyperlink'
import { BookmarkExtension } from './bookmark'
import { CalloutExtension } from './callout'
import { ColumnExtension } from './column'
import { DocumentWithoutTitle } from './title'
import { LinkToPage } from './page'
import { LoadingExtension } from './loading'
import { Placeholder } from './placeholder'
import { ToggleExtension } from './toggle'
import { YoutubeExtension } from './youtube'
import Commands from '../tools/commands/commands'
import MentionCommands from '../tools/commands/mentionCommands'
import Video from './video'
import { ImageCustom } from './image-custom'
import { Details, DetailsContent, DetailsSummary } from './details'
import DragAndDrop from './dragAndDrop'
// import FocusClasses from './focus'
import Table from './table'
import Separator from './separator'
import Mention from './userMention'
import TrailingNode from './trailingNode'
import FileHandler from './fileHandler'
import Audio from './audio'

const lowlight = createLowlight()
lowlight.register(common)

const defaultExtension = [
  Audio,
  FileHandler,
  TrailingNode,
  Mention,
  MentionCommands,
  Separator,
  // FocusClasses,
  DragAndDrop,
  Hyperlink.configure({
    hyperlinkOnPaste: false,
    openOnClick: true,
    modals: {
      previewHyperlink: previewHyperlinkModal,
      setHyperlink: setHyperlinkModal,
    },
  }),
  Details,
  DetailsContent,
  DetailsSummary,
  DocumentWithoutTitle,
  BookmarkExtension,
  CalloutExtension,
  LoadingExtension,
  YoutubeExtension,
  ColumnExtension,
  ToggleExtension,
  ImageCustom,
  Dropcursor.configure({
    width: 3,
    color: '#BFE5F4',
    class: 'drop-cursor',
  }),
  FontFamily,
  LinkToPage,
  Typography,
  TextStyle,
  Underline,
  Commands,
  ListItem,
  TaskList,
  Color,
  Video,
  Table.configure({ resizable: true }),
  TableRow.extend({ allowGapCursor: true }),
  TableHeader.extend({ isolating: false }),
  TableCell.extend({ isolating: false }),
  CodeBlockLowlight.configure({ lowlight }),
  Highlight.configure({ multicolor: true }),
  TextAlign.configure({ types: ['heading', 'paragraph'] }),
  StarterKit.configure({
    blockquote: false,
    bulletList: false,
    codeBlock: false,
    document: false,
    dropcursor: false,
    heading: false,
    history: false,
    horizontalRule: false,
    listItem: false,
    orderedList: false,
  }),
  Placeholder.configure({
    placeholder: ({ node, editor }) => {
      let text = "Write something … or type '/' to choose block"
      switch (node.type.name) {
        case 'heading':
          text = 'Heading'
          break
        case 'codeBlock':
          text = 'write code ...'
          break
        case 'tableCell':
          text = 'table cell'
          break
        case 'tableHeader':
          text = 'table header'
          break
        case 'blockquote':
          text = 'write quote ...'
          break
        case 'orderedList':
          text = 'text'
          break

        default:
          break
      }
      if (editor.isActive('table')) text = ''

      return text
    },
    showOnlyCurrent: false,
    includeChildren: true,
  }),
  Heading.configure({
    levels: [1, 2, 3],
    HTMLAttributes: {
      class: 'heading',
    },
  }),
  OrderedList.configure({
    atom: true,
    isolating: true,
    content: '(inline|hardBreak?)*',
    HTMLAttributes: {
      class: 'orderedList',
    },
  }),
  TaskItem.configure({
    nested: true,
    HTMLAttributes: {
      class: 'todoList',
    },
  }),
  BulletList.configure({
    HTMLAttributes: {
      class: 'bulletedList',
    },
  }),
  Blockquote.configure({
    HTMLAttributes: {
      class: 'blockquote',
    },
  }),
  // HorizontalRule.configure({
  //   HTMLAttributes: {
  //     class: 'separator',
  //   },
  // }),
  Superscript.configure({
    HTMLAttributes: {
      class: 'superscript',
    },
  }),
  Subscript.configure({
    HTMLAttributes: {
      class: 'subscript',
    },
  }),
]

export default defaultExtension
