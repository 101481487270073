<template>
  <v-btn-toggle
    multiple
    dense
    tile
  >
    <template v-for="tool in formatTools">
      <v-tooltip
        :key="tool.label"
        top
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :input-value="tool.active"
            elevation="0"
            v-on="on"
            @click="tool.command"
          >
            <v-icon>{{ tool.icon }}</v-icon>
          </v-btn>
        </template>
        {{ tool.label }}
      </v-tooltip>
    </template>
  </v-btn-toggle>
</template>

<script>
import { computed } from 'vue'
import { mdiFormatBold, mdiFormatItalic, mdiFormatUnderline } from '@mdi/js'

export default {
  props: {
    editor: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const formatTools = computed(() => [
      {
        icon: mdiFormatBold,
        label: 'Bold',
        active: props.editor.isActive('bold'),
        command: () => {
          props.editor.chain().focus().toggleBold().blur().run()
        },
      },
      {
        icon: mdiFormatItalic,
        label: 'Italic',
        active: props.editor.isActive('italic'),
        command: () => {
          props.editor.chain().focus().toggleItalic().blur().run()
        },
      },
      {
        icon: mdiFormatUnderline,
        label: 'Underline',
        active: props.editor.isActive('underline'),
        command: () => {
          props.editor.chain().focus().toggleUnderline().blur().run()
        },
      },
    ])

    return {
      formatTools,
    }
  },
}
</script>
