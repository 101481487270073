import Image from '@tiptap/extension-image'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { api as viewerApi } from 'v-viewer'

export const imagePluginKey = new PluginKey('imagePlugin')

export const ImageCustom = Image.extend({
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      style: {
        default: 'width: 100%; height: auto; cursor: pointer;',
      },
      dataCommentId: {
        default: null,
      },
      classComment: {
        default: null,
      },
    }
  },
  addNodeView() {
    return ({ node, editor, getPos }) => {
      const {
        view,
        options: { editable },
      } = editor
      const { src, alt, style, dataCommentId, classComment } = node.attrs
      const $container = document.createElement('div')
      const $img = document.createElement('img')

      $container.appendChild($img)
      $img.setAttribute('src', src)
      $img.setAttribute('alt', alt)
      $img.setAttribute('style', style)
      $img.setAttribute('draggable', 'true')
      $img.setAttribute('data-comment-id', dataCommentId)
      $img.setAttribute('class', classComment)
      if (node.attrs?.status) $img.setAttribute('status', node.attrs?.status ?? '')

      if (!editable) return { dom: $img }

      const dotsPosition = [
        'top: -4px; left: -4px; cursor: nwse-resize;',
        'top: -4px; right: -4px; cursor: nesw-resize;',
        'bottom: -4px; left: -4px; cursor: nesw-resize;',
        'bottom: -4px; right: -4px; cursor: nwse-resize;',
      ]

      let isResizing = false
      let startX
      let startWidth
      let startHeight

      $container.addEventListener('click', () => {
        // remove remaining dots
        if ($container.childElementCount > 2) {
          for (let i = 0; i < 4; i += 1) {
            $container.removeChild($container.lastChild)
          }
        }

        $container.setAttribute(
          'style',
          `position: relative; border: 1px dashed #6C6C6C; ${style} cursor: pointer;`,
        )

        // eslint-disable-next-line array-callback-return
        Array.from({ length: 4 }, (_, index) => {
          const $dot = document.createElement('div')
          $dot.setAttribute(
            'style',
            `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; ${dotsPosition[index]}`,
          )

          $dot.addEventListener('mousedown', e => {
            e.preventDefault()
            isResizing = true
            startX = e.clientX
            startWidth = $container.offsetWidth
            startHeight = $container.offsetHeight

            const onMouseMove = evt => {
              if (!isResizing) return

              const deltaX = evt.clientX - startX

              const aspectRatio = startWidth / startHeight
              const newWidth = startWidth + deltaX
              const newHeight = newWidth / aspectRatio

              $container.style.width = `${newWidth}px`
              $container.style.height = `${newHeight}px`

              $img.style.width = `${newWidth}px`
              $img.style.height = `${newHeight}px`
            }

            const onMouseUp = () => {
              if (isResizing) {
                isResizing = false
              }
              if (typeof getPos === 'function') {
                const newAttrs = {
                  ...node.attrs,
                  style: `${$img.style.cssText}`,
                }
                view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs))
              }

              document.removeEventListener('mousemove', onMouseMove)
              document.removeEventListener('mouseup', onMouseUp)
            }

            document.addEventListener('mousemove', onMouseMove)
            document.addEventListener('mouseup', onMouseUp)
          })
          $container.appendChild($dot)
        })
      })

      document.addEventListener('click', e => {
        if (!$container.contains(e.target)) {
          $container.removeAttribute('style')
          if ($container.childElementCount > 2) {
            for (let i = 0; i < 4; i += 1) {
              $container.removeChild($container.lastChild)
            }
          }
        }
      })

      return {
        dom: $container,
      }
    }
  },
  addProseMirrorPlugins() {
    const imagePlugin = new Plugin({
      key: imagePluginKey,
      props: {
        handleDOMEvents: {
          dblclick(view, event) {
            if (event.target.nodeName === 'IMG') {
              const images = view.docView.children.reduce((acc, el) => {
                if (/img/.test(el.dom.innerHTML)) {
                  const parser = new DOMParser()
                  const doc = parser.parseFromString(el.dom.innerHTML, 'text/html')
                  const imgElement = doc.querySelector('img')
                  const imgSrc = imgElement ? imgElement.getAttribute('src') : null

                  if (imgSrc) {
                    acc.push(imgSrc)
                  }
                }

                return acc
              }, [])

              const indexImage = images.findIndex(image => image === event.target.currentSrc ?? 0)

              viewerApi({
                options: {
                  toolbar: true,
                  initialViewIndex: indexImage,
                },
                images: images.length === 0 ? event.target.currentSrc : images,
              })
            }
          },
        },
      },
    })

    return [imagePlugin]
  },
})
