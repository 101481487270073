import gql from 'graphql-tag'

export const getAutomationById = gql`
  query GetAutomationById($workspaceId: Float!, $automationId: Float!) {
    getAutomationById(workspace_id: $workspaceId, automation_id: $automationId) {
      id
      name
      description
      is_active
      is_automation_locked
      group_operator
      folder {
        id
      }
      job_type {
        id
      }

      triggers {
        id
        name
        is_active
        property
        from_operator
        to_operator
        from_value
        to_value
      }
      actions {
        id
        name
        is_active
        action_type
        to_do
      }
    }
  }
`
