import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import FileHandlerComponent from '../../tools/vueNodeView/FileHandlerComponent.vue'

export const FileHandler = Node.create({
  name: 'fileHandler',
  atom: true,
  draggable: true,
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      inline: false,
    }
  },
  inline() {
    return this.options.inline
  },
  group() {
    return this.options.inline ? 'inline' : 'block'
  },
  addAttributes() {
    return {
      title: {
        default: null,
        parseHTML: element => ({ title: element.getAttribute('title') }),
        renderHTML: attributes => ({
          title: attributes.title,
        }),
      },
      url: {
        default: null,
        parseHTML: element => ({ url: element.getAttribute('url') }),
        renderHTML: attributes => ({
          url: attributes.url,
        }),
      },
      sizeFile: {
        default: null,
        parseHTML: element => ({ sizeFile: element.getAttribute('sizeFile') }),
        renderHTML: attributes => ({
          sizeFile: attributes.sizeFile,
        }),
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'vue-component',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['vue-component', mergeAttributes(HTMLAttributes)]
  },
  addNodeView() {
    return VueNodeViewRenderer(FileHandlerComponent)
  },
  addCommands() {
    return {
      setFile:
        options =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: options,
          }),
    }
  },
})
