<template>
  <div
    ref="container"
    class="containerVideo"
    @mousemove="containerOnMouseMove"
    @mouseleave="containerOnMouseLeave"
    :style="`${props.node.attrs.style}`"
    style="pointer-events: auto"
  >
    <div
      @mousedown="onMouseDown($event, 'left')"
      class="resizerVideo resizer-left"
      style="left: 15px"
    >
      <div class="handle"></div>
    </div>
    <slot name="content"></slot>
    <div
      @mousedown="onMouseDown($event, 'right')"
      class="resizerVideo resizer-right"
      style="right: 15px"
    >
      <div class="handle"></div>
    </div>
    <div
      ref="overlay"
      class="overlay"
      v-show="isResizing"
    ></div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const { props } = defineProps({
  props: {
    type: Object,
  },
})

const container = ref()
const overlay = ref()
const isResizing = ref(false)
const startX = ref()
const startWidth = ref()
const startHeight = ref()

const containerOnMouseMove = event => {
  event.preventDefault()

  container.value.querySelectorAll('.resizerVideo').forEach(resizer => {
    resizer.style.opacity = '1'
  })
}

const containerOnMouseLeave = event => {
  event.preventDefault()
  container.value.querySelectorAll('.resizerVideo').forEach(resizer => {
    resizer.style.opacity = '0'
  })
}

const onMouseDown = (event, position) => {
  event.preventDefault()
  isResizing.value = true
  startX.value = event.clientX
  startWidth.value = container.value.offsetWidth
  startHeight.value = container.value.offsetHeight

  document.addEventListener('mousemove', mousemoveEvent => onMouseMove(mousemoveEvent, position))
  document.addEventListener('mouseup', onMouseUp)
}
const onMouseMove = (event, position) => {
  if (!isResizing.value) return

  const deltaX = event.clientX - startX.value

  const newWidth = position === 'left' ? startWidth.value - deltaX : startWidth.value + deltaX
  container.value.style.width = `${newWidth}px`
  overlay.value.style.width = `${newWidth}px`
}

const onMouseUp = () => {
  if (isResizing.value) {
    isResizing.value = false

    if (typeof props.getPos === 'function') {
      const newAttrs = {
        ...props.node.attrs,
        style: container.value.style.cssText,
      }
      props.editor.view.dispatch(
        props.editor.view.state.tr.setNodeMarkup(props.getPos(), null, newAttrs),
      )
    }

    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
  }
}
</script>
<style lang=""></style>
