import { Mark, markPasteRule, mergeAttributes } from '@tiptap/core'
import { find, registerCustomProtocol, reset } from 'linkifyjs'
import autoHyperlink from './helpers/autolink'
import clickHandler from './helpers/clickHandler'
import { pasteHandler } from './helpers/pasteHandler'
import editHyperlinkHelper from './helpers/editHandler'
import Tooltip from './helpers/tippyHelper'
import { roundArrow } from 'tippy.js'
import hoverHandlerHyperlink from './helpers/hoverHandler'

export const Hyperlink = Mark.create({
  name: 'hyperlink',

  priority: 1000,

  keepOnSplit: false,

  Marks: '',
  inclusive: false,

  onCreate() {
    this.options.protocols.forEach(protocol => {
      if (typeof protocol === 'string') {
        registerCustomProtocol(protocol)

        return
      }
      registerCustomProtocol(protocol.scheme, protocol.optionalSlashes)
    })
  },

  onDestroy() {
    reset()
  },

  addOptions() {
    return {
      openOnClick: true,
      hyperlinkOnPaste: true,
      autoHyperlink: true,
      protocols: [],
      HTMLAttributes: {
        target: '_blank',
        rel: 'noopener noreferrer nofollow',
        class: null,
      },
      modals: {
        previewHyperlink: null,
        setHyperlink: null,
      },
      validate: undefined,
    }
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
      class: {
        default: this.options.HTMLAttributes.class,
      },
    }
  },

  parseHTML() {
    return [{ tag: 'a[href]:not([href *= "javascript:" i])' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setHyperlink:
        attributes =>
        ({ editor, chain }) => {
          if (!this.options.modals.setHyperlink) {
            return chain()
              .setMark(this.name, attributes)
              .setMeta('preventAutohyperlink', true)
              .run()
          }
          this.options.modals.setHyperlink({
            editor,
            validate: this.options.validate,
            extentionName: this.name,
            attributes,
            Tooltip,
            roundArrow,
          })

          return true
        },

      editHyperlink:
        attributes =>
        ({ editor }) => {
          return editHyperlinkHelper({
            ...attributes,
            editor,
            validate: this.options.validate,
          })
        },

      editHyperLinkText:
        newText =>
        ({ editor }) => {
          return editHyperlinkHelper({
            editor,
            newText,
            validate: this.options.validate,
          })
        },

      editHyperLinkHref:
        newURL =>
        ({ editor }) => {
          return editHyperlinkHelper({
            editor,
            validate: this.options.validate,
            newURL,
          })
        },

      unsetHyperlink:
        () =>
        ({ chain }) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .setMeta('preventAutohyperlink', true)
            .run()
        },
    }
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: text =>
          find(text)
            .filter(link => {
              if (this.options.validate) {
                return this.options.validate(link.value)
              }

              return true
            })
            .filter(link => link.isLink)
            .map(link => ({
              text: link.value,
              index: link.start,
              data: link,
            })),
        type: this.type,
        getAttributes: match => ({
          href: match.data?.href,
        }),
      }),
    ]
  },

  addProseMirrorPlugins() {
    const plugins = []

    plugins.push(
      hoverHandlerHyperlink({
        type: this.type,
        editor: this.editor,
        validate: this.options.validate,
        modal: this.options.modals.previewHyperlink,
      }),
    )

    if (this.options.autoHyperlink) {
      plugins.push(
        autoHyperlink({
          type: this.type,
          validate: this.options.validate,
        }),
      )
    }

    if (this.options.openOnClick) {
      plugins.push(
        clickHandler({
          type: this.type,
          editor: this.editor,
          validate: this.options.validate,
          modal: this.options.modals.previewHyperlink,
        }),
      )
    }

    if (this.options.hyperlinkOnPaste) {
      plugins.push(
        pasteHandler({
          editor: this.editor,
          type: this.type,
        }),
      )
    }

    return plugins
  },
})
