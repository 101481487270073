<template>
  <v-tab-item
    class="overflow-auto px-1"
    style="max-height: 100%; margin: 0 !important"
    v-scroll.self="handleScrollLog"
  >
    <div v-if="automationLogList.length > 0">
      <v-card
        v-for="(detail, index) in automationLogList"
        :key="`automation_list--item-${index}`"
        class="mb-3"
        outlined
      >
        <v-card-title class="d-flex justify-space-between pt-4 pb-0 px-5">
          <h6 class="text-h6 font-weight-semibold">{{ detail.automation.name }}</h6>
        </v-card-title>

        <v-card-text class="d-flex flex-column pt-2 pb-4 px-5">
          <p
            class="mb-0 text-subtitle-2"
            style="max-width: 85%"
          >
            Nama Objek {{ detail?.job?.id }} } – {{ detail?.job?.name || detail?.document?.name }}
          </p>

          <p
            class="mb-0 text-subtitle-2"
            style="max-width: 85%"
          >
            Merubah dari – {{ detail?.history?.fromValue }}
          </p>
          <p
            class="mb-0 text-subtitle-2"
            style="max-width: 85%"
          >
            Menjadi – {{ detail?.history?.toValue }}
          </p>
        </v-card-text>
      </v-card>
    </div>

    <div
      v-else
      class="d-flex align-center"
      style="min-height: 500px"
    >
      <h6 class="w-full text-h6 text-center">Belum ada histori Automation yang terbuat.</h6>
    </div>
  </v-tab-item>
</template>

<script>
import useAutomation from '@/composables/useAutomationV3'
import { useDebounceFn } from '@vueuse/core'
import { inject, onMounted, onUnmounted, ref, watch } from 'vue'

export default {
  setup() {
    const { fetchAutomationLog } = useAutomation()

    const folderDetail = inject('folderDetail')
    const jobTypeDetail = inject('jobTypeDetail')

    const pagination = ref({
      limit: 10,
      offset: 0,
    })
    const automationLogCount = ref()
    const automationLogList = ref([])
    const automationLogFilter = ref({
      sortType: 'DESC',
      status: 'SUCCESS',
    })

    const handleScrollLog = useDebounceFn(async data => {
      if (automationLogList.value.length >= automationLogCount.value) return

      if (
        data.target.offsetHeight + data.target.scrollTop >= data.target.scrollHeight - 500 &&
        automationLogList.value.length < automationLogCount.value
      ) {
        await fetchAutomationLog(
          folderDetail.value.id,
          jobTypeDetail.value.id,
          automationLogFilter.value,
          pagination.value,
        ).then(({ data }) => {
          automationLogList.value.push(...data)
          pagination.value.offset = automationLogList.value.length
        })
      }
    }, 200)

    onMounted(
      async () =>
        await fetchAutomationLog(
          folderDetail.value?.id,
          jobTypeDetail.value?.id,
          automationLogFilter.value,
          pagination.value,
        ).then(({ count, data }) => {
          automationLogList.value = data
          automationLogCount.value = count
          pagination.value.offset = automationLogList.value.length
        }),
    )

    onUnmounted(() => (automationLogList.value = []))

    watch(jobTypeDetail, async newDetail => {
      if (!newDetail?.id) return

      automationLogList.value = []
      automationLogCount.value = undefined

      await fetchAutomationLog(
        folderDetail.value?.id,
        jobTypeDetail.value?.id,
        automationLogFilter.value,
        pagination.value,
      ).then(({ count, data }) => {
        automationLogList.value = data
        automationLogCount.value = count
        pagination.value.offset = automationLogList.value.length
      })
    })

    return {
      automationLogList,

      handleScrollLog,
    }
  },
}
</script>
