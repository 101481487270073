<template>
  <v-menu
    v-model="editMode"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
        :editMode="editMode"
        :handleOpen="handleOpen"
      >
        <v-btn
          text
          x-small
          v-on="on"
          v-bind="attrs"
          elevation="0"
          class="font-weight-regular"
          style="padding-left: 5px; padding-right: 5px"
          @click.prevent.stop="handleOpen()"
        >
          <slot>
            <div
              v-if="value.length"
              class="multi-select-input"
              @click.prevent.stop="handleOpen()"
            >
              <v-chip
                v-for="item in value"
                :key="item.id"
                :color="item.color"
                style="cursor: pointer"
                x-small
                label
                @click.prevent.stop="handleOpen()"
              >
                {{ item.name }}
              </v-chip>
            </div>
            <span
              v-else
              @click.prevent.stop="handleOpen()"
              >-</span
            >
          </slot>
        </v-btn>
      </slot>
    </template>

    <ItemSelect
      v-model="localValue"
      :items="items"
      :multiple="true"
    />
  </v-menu>
</template>

<script setup>
import errorHandling from '@/utils/errorHandling'
import { ref, watch } from 'vue'
import ItemSelect from './components/ItemSelect.vue'

const emit = defineEmits(['input'])
const props = defineProps({
  // id, name, color (optional)
  value: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },

  /* ? This validator props are used to determine whether the dialog is alowed to be open or not
   *   if this props is not present, validator is ignored and dialog would always open
   *
   *   @example => :validator="job_type.folder_permission[0].permission_type === 'Admin'"
   */
  validator: {
    type: Boolean,
    default: undefined,
  },
})

let storePrevious = props.value
const localValue = ref(props.value)
const editMode = ref(false)

const handleOpen = () => {
  if (props.validator === undefined || props.validator) editMode.value = true
  else if (props.validator === false)
    errorHandling('Anda tidak memiliki Permission untuk melakukan aksi ini!')
}

watch(editMode, val => {
  if (props.validator === false) return (editMode.value = false)

  if (!val && localValue.value !== storePrevious) {
    emit('input', localValue.value)

    storePrevious = localValue.value
  }
})
</script>

<style lang="scss">
.multi-select-input {
  .v-chip {
    &:not(:first-child) {
      margin-left: 2px;
    }
    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}
</style>
