import tippy from 'tippy.js'
import { posToDOMRect } from '@tiptap/core'

class Tooltip {
  preventHide = false

  constructor(options) {
    this.editor = options.editor
    this.view = this.editor.view
    this.tippyWrapper = document.createElement('div')
    this.tippyWrapper.addEventListener('mousedown', this.mousedownHandler, {
      capture: true,
    })
    this.view.dom.addEventListener('dragstart', this.dragstartHandler)
    this.editor.on('blur', this.blurHandler)
  }

  init() {
    this.tippyWrapper.innerHTML = ''

    return { tippyModal: this.tippyWrapper, tippyInstance: this.tippyInstance }
  }

  show() {
    this.tippyInstance?.show()

    return true
  }

  hide() {
    setTimeout(() => this.tippyInstance?.hide())

    return false
  }

  mousedownHandler = () => {
    this.preventHide = true
  }

  dragstartHandler = () => {
    this.hide()
  }

  blurHandler = ({ event }) => {
    if (this.preventHide) {
      this.preventHide = false

      return
    }
    if (event?.relatedTarget && this.tippyWrapper.parentNode?.contains(event.relatedTarget)) {
      return
    }
    this.hide()
  }

  tippyBlurHandler = event => {
    this.blurHandler({ event })
  }

  createTooltip() {
    if (!this.editor || !this.editor.options) return
    const { element: editorElement } = this.editor.options
    const editorIsAttached = !!editorElement.parentElement

    if (this.tippyInstance || !editorIsAttached) {
      return
    }

    this.tippyInstance = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.tippyWrapper,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom',
      hideOnClick: true,
      onClickOutside: () => {
        this.hide()
      },
      onAfterUpdate: () => {
        this.show()
      },
      appendTo: () => document.getElementById('editor'),
    })

    if (this.tippyInstance.popper.firstChild) {
      this.tippyInstance.popper.firstChild.addEventListener('blur', this.tippyBlurHandler)
    }
  }

  update(view, nodePos, option = {}) {
    this.createTooltip()

    // eslint-disable-next-line no-param-reassign
    option.arrow = option?.arrow ?? false

    if (this.tippyInstance) {
      this.tippyInstance.setProps({
        ...option,
        getReferenceClientRect: () => {
          const pos = nodePos ?? view.state.selection.from
          // width: 0 is a hack to prevent tippy display in the wrong position
          // eslint-disable-next-line newline-before-return
          return { ...posToDOMRect(view, pos, pos), width: 0 }
        },
      })
    }

    return {}
  }

  destroyTooltip() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy()
      this.tippyInstance = undefined
      this.tippyWrapper.removeEventListener('mousedown', this.mousedownHandler, {
        capture: true,
      })
      this.view.dom.removeEventListener('dragstart', this.dragstartHandler)
      this.editor.off('blur', this.blurHandler)
    }
  }
}

export default Tooltip
