import gql from 'graphql-tag'

const channels = gql`
  query (
    $pagination: pagination!
    $is_archived: Boolean
    $search: String
    $filter: filterChannel
    $team_id: Float!
  ) {
    channels(
      pagination: $pagination
      is_archived: $is_archived
      search: $search
      filter: $filter
      team_id: $team_id
    ) {
      id
      name
      comment_permission
      channel_notification
      is_pinned
      archived_at
      user {
        id
        phone
        email
        name
        first_name
        last_name
        photo
      }
      team {
        id
        name
        is_public
        channel_permission
        description
        workspace {
          id
          name
        }
        users {
          user {
            id
            phone
            email
            name
            first_name
            last_name
            photo
          }
        }
        created_at
        updated_at
        deleted_at
      }
      thread {
        id
        html_comment
        is_pinned
        channel {
          id
          name
        }
        user {
          id
          phone
          email
          name
          first_name
          last_name
          photo
        }
        posts {
          id
          html_comment
          user {
            id
            name
          }
          created_at
          updated_at
          deleted_at
        }
        archived_at
        last_activity_at
        created_at
        updated_at
        deleted_at
      }
      last_activity_at
      deleted_at
    }
  }
`

export default channels
