<template>
  <node-view-wrapper
    class="vue-component dom"
    :status="node.attrs?.status"
  >
    <div v-if="node.attrs.url">
      <a
        :href="node.attrs.url"
        target="_blank"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            outlined
            :color="hover ? 'secondary' : ''"
            class="pa-2 fileHandlerComponent"
          >
            <div class="d-flex">
              <v-icon
                :color="hover ? 'white' : ''"
                class="me-2"
                >{{ mdiFileDocumentOutline }}</v-icon
              >
              <p :class="`text-subtitle-1 mb-0 ${hover ? 'text-white' : ''}`">
                {{
                  node.attrs.title.length > 50
                    ? node.attrs.title.substring(0, 50) + '...'
                    : node.attrs.title
                }}

                <span class="text-caption">{{ node.attrs.sizeFile.toFixed(2) }} KB</span>
              </p>
            </div>
          </v-card>
        </v-hover>
      </a>
    </div>
    <div v-else>
      <upload-component
        :loading="isUploading"
        :input-file="inputFile"
        :icon="mdiFileUploadOutline"
        :upload-loaded="uploadLoaded"
        :upload-total="uploadTotal"
        :upload-progress="uploadProgress"
        upload-type="file"
        @submit="uploadFile"
        @set-inputFile="inputFile = $event"
      />
    </div>
  </node-view-wrapper>
</template>
<script setup>
import { mdiFileDocumentOutline, mdiFileUploadOutline } from '@mdi/js'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { ref } from 'vue'
import { uploadMedia } from '../../utils/upload'
import UploadComponent from './UploadComponent.vue'

const props = defineProps(nodeViewProps)
const inputFile = ref()
const isUploading = ref(false)
const uploadLoaded = ref(0)
const uploadTotal = ref(0)
const uploadProgress = ref(0)

const uploadFile = () => {
  const [, , , documentId] = window.location.href.split('/')
  const file = inputFile.value
  const host = process.env.VUE_APP_BACKEND_URL

  uploadMedia(file, documentId, progressEvent => {
    isUploading.value = true
    uploadLoaded.value = progressEvent.loaded
    uploadTotal.value = progressEvent.total
    uploadProgress.value = parseInt(
      Math.round((progressEvent.loaded / progressEvent.total) * 100),
      10,
    )
  })
    .then(response => {
      const fileUrl = response.data.data.destination.slice('assets/'.length)
      const fileName = response.data.data.originalname
      const fileSize = response.data.data.size
      const encodedFileName = encodeURIComponent(fileName)
      const url = `${host}/${fileUrl}/${encodedFileName}`
      const sizeInKb = fileSize / 1024
      // let sizeInMb = sizeInKb / 1024

      props.updateAttributes({
        title: fileName,
        url,
        sizeFile: sizeInKb,
      })

      isUploading.value = false
    })
    .catch(error => {
      console.error('Error uploading image:', error)
      isUploading.value = false
    })
}
</script>
<style lang=""></style>
