import {
  mdiAccountArrowRightOutline,
  mdiAccountEditOutline,
  mdiAccountMinusOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountSwitchOutline,
  mdiAccountSyncOutline,
  mdiAlertOutline,
  mdiArchiveOutline,
  mdiAttachment,
  mdiBellOutline,
  mdiBellPlusOutline,
  mdiBriefcaseEditOutline,
  mdiBriefcaseOutline,
  mdiBriefcasePlusOutline,
  mdiCalendarCheckOutline,
  mdiCalendarClockOutline,
  mdiCalendarOutline,
  mdiCalendarRangeOutline,
  mdiCalendarStartOutline,
  mdiCalendarTodayOutline,
  mdiCalendarWeekOutline,
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxOutline,
  mdiCheckCircleOutline,
  mdiClockOutline,
  mdiCloseCircleOutline,
  mdiContentDuplicate,
  mdiCursorText,
  mdiDeleteOutline,
  mdiEarth,
  mdiEqual,
  mdiFileDocumentMultipleOutline,
  mdiFileDocumentOutline,
  mdiFileEditOutline,
  mdiFileLockOutline,
  mdiFileOutline,
  mdiFilePlusOutline,
  mdiFlagOutline,
  mdiFolderOutline,
  mdiFormatListBulleted,
  mdiFunction,
  mdiFunctionVariant,
  mdiGreaterThan,
  mdiInformationOutline,
  mdiLessThan,
  mdiLinkVariant,
  mdiLinkVariantPlus,
  mdiLockOutline,
  mdiMagnify,
  mdiMessageTextOutline,
  mdiMonitorEye,
  mdiNotEqual,
  mdiNumeric,
  mdiPaperclip,
  mdiPencilOutline,
  mdiShieldAccountOutline,
  mdiTagOutline,
  mdiTextBoxOutline,
} from '@mdi/js'

/**
 * ? Type List,
 * ---
 * - string -> show normal text field
 * - integer -> show normal / number only text field
 * - multiple -> show selection based, may change based on property
 * - date -> show date selector
 * - user -> show user selector
 * - file -> show file selector? not yet decided, maybe attachment selector?
 * - boolean -> show true false selector, only show operator, not value field
 * - status -> show status single selector
 * - priority -> show priority single selector
 * - document -> show document selector
 * - document-tag -> show documet tag selector
 * - UNKNOWN -> not yet decided
 */
export const attributePropertyMap = {
  ADD: {
    id: 'ADD',
    label: 'Job Dibuat',
    message: 'Job Dibuat',
    icon: mdiBriefcasePlusOutline,
  },

  // ------------------------------------ JOB ------------------------------------ //
  1: {
    id: '1',
    label: 'Name',
    message: 'Nama Job',
    type: 'string',
    icon: mdiBriefcaseOutline,
  },
  2: {
    id: '2',
    label: 'Priority',
    message: 'Prioritas Job',
    type: 'priority',
    icon: mdiFlagOutline,
  },
  3: {
    id: '3',
    label: 'Status',
    message: 'Status Job',
    type: 'status',
    icon: mdiInformationOutline,
  },
  4: {
    id: '4',
    label: 'Start Date',
    message: 'Tanggal mulai Job',
    type: 'date',
    icon: mdiCalendarStartOutline,
  },
  5: {
    id: '5',
    label: 'Expected Close Date',
    message: 'Tanggal perkiraan selesai',
    type: 'date',
    icon: mdiCalendarCheckOutline,
  },
  6: {
    id: '6',
    label: 'Archive At',
    message: 'Tanggal pengarsipan Job',
    type: 'date',
    icon: mdiArchiveOutline,
  },
  7: {
    id: '7',
    label: 'Create By',
    message: 'Pembuat Job',
    type: 'user',
    icon: mdiAccountOutline,
  },
  8: {
    id: '8',
    label: 'Assign To',
    message: 'Penerima tugas Job',
    type: 'user',
    icon: mdiAccountArrowRightOutline,
  },
  9: {
    id: '9',
    label: 'Reference Tag',
    message: 'Tag referensi untuk Job',
    type: 'multiple',
    icon: mdiTagOutline,
  },
  10: {
    id: '10',
    label: 'Description',
    message: 'Deskripsi detail tentang Job',
    type: 'string',
    icon: mdiTextBoxOutline,
  },
  11: {
    id: '11',
    label: 'Delete At',
    message: 'Tanggal penghapusan Job',
    type: 'date',
    icon: mdiDeleteOutline,
  },
  12: {
    id: '12',
    label: 'Watchers',
    message: 'Referensi notifikasi terkait Job',
    type: 'user',
    icon: mdiBellOutline,
  },
  13: {
    id: '13',
    label: 'Relation to Document',
    message: 'Document yang terelasikan dengan Job',
    type: 'document',
    icon: mdiFileDocumentMultipleOutline,
  },
  14: {
    id: '14',
    label: 'Attachment',
    message: 'Lampiran terkait (detail belum ditentukan)',
    type: 'UNKNOWN',
    icon: mdiPaperclip,
  },
  15: {
    id: '15',
    label: 'Sub Job',
    message: 'Job turunan',
    type: 'UNKNOWN',
    icon: mdiBriefcaseOutline,
  },
  16: {
    id: '16',
    label: 'Parent ID',
    message: 'ID Job induk',
    type: 'UNKNOWN',
    icon: mdiLinkVariant,
  },

  // --------------------------------- DOCUMENT --------------------------------- //

  20: {
    id: '20',
    label: 'Name',
    message: 'Nama dokumen',
    type: 'string',
    icon: mdiFileDocumentOutline,
  },
  21: {
    id: '21',
    label: 'Created By',
    message: 'Pembuat dokumen',
    type: 'user',
    icon: mdiAccountOutline,
  },
  22: {
    id: '22',
    label: 'Is Public',
    message: 'Apakah dokumen bersifat publik',
    type: 'boolean',
    icon: mdiEarth,
  },
  23: {
    id: '23',
    label: 'Last Edit By',
    message: 'Pengedit terakhir dokumen',
    type: 'user',
    icon: mdiAccountEditOutline,
  },
  24: {
    id: '24',
    label: 'Last Edit At',
    message: 'Tanggal pengeditan terakhir',
    type: 'date',
    icon: mdiCalendarClockOutline,
  },
  25: {
    id: '25',
    label: 'Is Lock',
    message: 'Apakah dokumen dikunci',
    type: 'boolean',
    icon: mdiLockOutline,
  },
  26: {
    id: '26',
    label: 'Parent Document',
    message: 'Dokumen induk',
    type: 'document',
    icon: mdiLinkVariant,
  },
  27: {
    id: '27',
    label: 'Document Children',
    message: 'Dokumen turunan',
    type: 'document',
    icon: mdiLinkVariantPlus,
  },
  28: {
    id: '28',
    label: 'Delete At',
    message: 'Tanggal penghapusan dokumen',
    type: 'date',
    icon: mdiDeleteOutline,
  },
  29: {
    id: '29',
    label: 'Archive At',
    message: 'Tanggal pengarsipan dokumen',
    type: 'date',
    icon: mdiArchiveOutline,
  },
  30: {
    id: '30',
    label: 'Document Tag Reference',
    message: 'Referensi tag dokumen',
    type: 'document-tag',
    icon: mdiTagOutline,
  },
  31: {
    id: '31',
    label: 'Watchers',
    message: 'Referensi notifikasi terkait dokumen',
    type: 'UNKNOWN',
    icon: mdiBellOutline,
  },
  32: {
    id: '32',
    label: 'Folder',
    message: 'Folder yang berisi dokumen',
    type: 'UNKNOWN',
    icon: mdiFolderOutline,
  },
  33: {
    id: '33',
    label: 'Job',
    message: 'Job terkait dokumen',
    type: 'UNKNOWN',
    icon: mdiBriefcaseOutline,
  },
}
export const attributePropertyList = [
  {
    header: 'Creation Action',
  },
  {
    id: 'ADD',
    label: 'Job Dibuat',
    message: 'Job Dibuat',
    icon: mdiBriefcasePlusOutline,
  },

  {
    header: 'Job Properties',
  },
  {
    id: '1',
    label: 'Name',
    message: 'Nama Job',
    icon: mdiBriefcaseOutline,
  },
  {
    id: '2',
    label: 'Priority',
    message: 'Prioritas Job',
    icon: mdiFlagOutline,
  },
  {
    id: '3',
    label: 'Status',
    message: 'Status Job',
    icon: mdiInformationOutline,
  },
  {
    id: '4',
    label: 'Start Date',
    message: 'Tanggal mulai Job',
    icon: mdiCalendarStartOutline,
  },
  {
    id: '5',
    label: 'Expected Close Date',
    message: 'Tanggal perkiraan selesai',
    icon: mdiCalendarCheckOutline,
  },
  {
    id: '6',
    label: 'Archive At',
    message: 'Tanggal pengarsipan Job',
    icon: mdiArchiveOutline,
  },
  {
    id: '7',
    label: 'Create By',
    message: 'Pembuat Job',
    icon: mdiAccountOutline,
  },
  {
    id: '8',
    label: 'Assign To',
    message: 'Penerima tugas Job',
    icon: mdiAccountArrowRightOutline,
  },
  {
    id: '9',
    label: 'Reference Tag',
    message: 'Tag referensi untuk Job',
    icon: mdiTagOutline,
  },
  {
    id: '10',
    label: 'Description',
    message: 'Deskripsi detail tentang Job',
    icon: mdiTextBoxOutline,
  },
  {
    id: '11',
    label: 'Delete At',
    message: 'Tanggal penghapusan Job',
    icon: mdiDeleteOutline,
  },
  {
    id: '12',
    label: 'Watchers',
    message: 'Referensi notifikasi terkait Job',
    icon: mdiBellOutline,
  },
  {
    id: '13',
    label: 'Relation to Document',
    message: 'Document yang terelasikan dengan Job',
    icon: mdiFileDocumentMultipleOutline,
  },
  {
    id: '14',
    label: 'Attachment',
    message: 'Lampiran terkait (detail belum ditentukan)',
    icon: mdiPaperclip,
  },
  {
    id: '15',
    label: 'Sub Job',
    message: 'Job turunan',
    icon: mdiBriefcaseOutline,
  },
  {
    id: '16',
    label: 'Parent ID',
    message: 'ID Job induk',
    icon: mdiLinkVariant,
  },

  // ------------------------------------------------------------------- //
  {
    header: 'Document Properties',
  },
  {
    id: '20',
    label: 'Name',
    message: 'Nama dokumen',
    icon: mdiFileDocumentOutline,
  },
  {
    id: '21',
    label: 'Created By',
    message: 'Pembuat dokumen',
    icon: mdiAccountOutline,
  },
  {
    id: '22',
    label: 'Is Public',
    message: 'Apakah dokumen bersifat publik',
    icon: mdiEarth,
  },
  {
    id: '23',
    label: 'Last Edit By',
    message: 'Pengedit terakhir dokumen',
    icon: mdiAccountEditOutline,
  },
  {
    id: '24',
    label: 'Last Edit At',
    message: 'Tanggal pengeditan terakhir',
    icon: mdiCalendarClockOutline,
  },
  {
    id: '25',
    label: 'Is Lock',
    message: 'Apakah dokumen dikunci',
    icon: mdiLockOutline,
  },
  {
    id: '26',
    label: 'Parent Document',
    message: 'Dokumen induk',
    icon: mdiLinkVariant,
  },
  {
    id: '27',
    label: 'Document Children',
    message: 'Dokumen turunan',
    icon: mdiLinkVariantPlus,
  },
  {
    id: '28',
    label: 'Delete At',
    message: 'Tanggal penghapusan dokumen',
    icon: mdiDeleteOutline,
  },
  {
    id: '29',
    label: 'Archive At',
    message: 'Tanggal pengarsipan dokumen',
    icon: mdiArchiveOutline,
  },
  {
    id: '30',
    label: 'Document Tag Reference',
    message: 'Referensi tag dokumen',
    icon: mdiTagOutline,
  },
  {
    id: '31',
    label: 'Watchers',
    message: 'Referensi notifikasi terkait dokumen',
    icon: mdiBellOutline,
  },
  {
    id: '32',
    label: 'Folder',
    message: 'Folder yang berisi dokumen',
    icon: mdiFolderOutline,
  },
  {
    id: '33',
    label: 'Job',
    message: 'Job terkait dokumen',
    icon: mdiBriefcaseOutline,
  },
]

export const operatorList = [
  {
    id: 1,
    label: 'Sama Dengan',
    message: 'Memeriksa apakah properti bernilai sama dengan nilai yang ditentukan',
    type: [
      'string',
      'integer',
      'single',
      'date',
      'file',
      'priority',
      'status',
      'document',
      'document-tag',
    ],
    icon: mdiEqual,
  },
  {
    id: 2,
    label: 'Tidak Sama Dengan',
    message: 'Memeriksa apakah properti tidak bernilai sama dengan nilai yang ditentukan',
    type: [
      'string',
      'integer',
      'single',
      'date',
      'file',
      'priority',
      'status',
      'document',
      'document-tag',
    ],
    icon: mdiNotEqual,
  },
  {
    id: 3,
    label: 'Memiliki', // Specifically String
    message: 'Memeriksa apakah properti mengandung nilai tertentu',
    type: ['string'],
    icon: mdiMagnify,
  },
  {
    id: 4,
    label: 'Tidak Memiliki', // Specifically String
    message: 'Memeriksa apakah properti tidak mengandung nilai tertentu',
    type: ['string'],
    icon: mdiMagnify,
  },
  {
    id: 7,
    label: 'Lebih dari',
    message: 'Memeriksa apakah properti bernilai lebih besar dari nilai yang ditentukan',
    type: ['integer', 'date'],
    icon: mdiGreaterThan,
  },
  {
    id: 8,
    label: 'Kurang dari',
    message: 'Memeriksa apakah properti bernilai lebih kecil dari nilai yang ditentukan',
    type: ['integer', 'date'],
    icon: mdiLessThan,
  },
  {
    id: 11,
    label: 'Memiliki', // For Array of items
    message: 'Memeriksa apakah properti mengandung beberapa nilai tertentu',
    type: ['multiple', 'user', 'file', 'status', 'priority'],
    icon: mdiFormatListBulleted,
  },
  {
    id: 12,
    label: 'Tidak Memiliki', // For Array of items
    message: 'Memeriksa apakah properti tidak mengandung beberapa nilai tertentu',
    type: ['multiple', 'user', 'file', 'status', 'priority'],
    icon: mdiFormatListBulleted,
  },
  {
    id: 13,
    label: 'Bernilai Benar',
    message: 'Memeriksa apakah properti bernilai benar',
    type: ['boolean'],
    icon: mdiCheckCircleOutline,
  },
  {
    id: 14,
    label: 'Bernilai Salah',
    message: 'Memeriksa apakah properti bernilai salah',
    type: ['boolean'],
    icon: mdiCloseCircleOutline,
  },
  {
    id: 15,
    label: 'Diubah',
    message: 'Memeriksa apakah properti telah diubah nilainya',
    type: ['string', 'integer', 'single', 'multiple', 'date', 'user', 'file', 'priority', 'status'],
    icon: mdiPencilOutline,
  },
  {
    id: 16,
    label: 'Kosong',
    message: 'Memeriksa apakah properti tidak memiliki nilai',
    type: ['string', 'integer', 'single', 'multiple', 'date', 'user', 'file', 'priority', 'status'],
    icon: mdiAlertOutline,
  },
]
export const operatorMap = {
  1: {
    id: 1,
    label: 'Sama Dengan',
    message: 'Memeriksa apakah properti bernilai sama dengan nilai yang ditentukan',
    type: ['string', 'integer', 'single', 'date', 'file', 'priority', 'status'],
    icon: mdiEqual,
  },
  2: {
    id: 2,
    label: 'Tidak Sama Dengan',
    message: 'Memeriksa apakah properti tidak bernilai sama dengan nilai yang ditentukan',
    type: ['string', 'integer', 'single', 'date', 'file', 'priority', 'status'],
    icon: mdiNotEqual,
  },
  3: {
    id: 3,
    label: 'Memiliki', // Specifically String
    message: 'Memeriksa apakah properti mengandung nilai tertentu',
    type: ['string'],
    icon: mdiMagnify,
  },
  4: {
    id: 4,
    label: 'Tidak Memiliki', // Specifically String
    message: 'Memeriksa apakah properti tidak mengandung nilai tertentu',
    type: ['string'],
    icon: mdiMagnify,
  },
  7: {
    id: 7,
    label: 'Lebih dari',
    message: 'Memeriksa apakah properti bernilai lebih besar dari nilai yang ditentukan',
    type: ['integer', 'date'],
    icon: mdiGreaterThan,
  },
  8: {
    id: 8,
    label: 'Kurang dari',
    message: 'Memeriksa apakah properti bernilai lebih kecil dari nilai yang ditentukan',
    type: ['integer', 'date'],
    icon: mdiLessThan,
  },
  11: {
    id: 11,
    label: 'Memiliki', // For Array of items
    message: 'Memeriksa apakah properti mengandung beberapa nilai tertentu',
    type: ['multiple', 'user', 'file', 'status', 'priority', 'document-tag'],
    icon: mdiFormatListBulleted,
  },
  12: {
    id: 12,
    label: 'Tidak Memiliki', // For Array of items
    message: 'Memeriksa apakah properti tidak mengandung beberapa nilai tertentu',
    type: ['multiple', 'user', 'file', 'status', 'priority', 'document-tag'],
    icon: mdiFormatListBulleted,
  },
  13: {
    id: 13,
    label: 'Bernilai Benar',
    message: 'Memeriksa apakah properti bernilai benar',
    type: ['boolean'],
    icon: mdiCheckCircleOutline,
  },
  14: {
    id: 14,
    label: 'Bernilai Salah',
    message: 'Memeriksa apakah properti bernilai salah',
    type: ['boolean'],
    icon: mdiCloseCircleOutline,
  },
  15: {
    id: 15,
    label: 'Diubah',
    message: 'Memeriksa apakah properti telah diubah nilainya',
    type: [
      'string',
      'integer',
      'single',
      'multiple',
      'date',
      'user',
      'file',
      'priority',
      'status',
      'document',
      'document-tag',
    ],
    icon: mdiPencilOutline,
  },
  16: {
    id: 16,
    label: 'Kosong',
    message: 'Memeriksa apakah properti tidak memiliki nilai',
    type: [
      'string',
      'integer',
      'single',
      'multiple',
      'date',
      'user',
      'file',
      'priority',
      'status',
      'document',
      'document-tag',
    ],
    icon: mdiAlertOutline,
  },
}

export const actionTypeList = [
  {
    id: 1,
    label: 'Ciptakan Job Baru',
    message: 'Tambahkan detail untuk membuat job baru',
    icon: mdiBriefcasePlusOutline,
  },
  // {
  //   id: 2,
  //   label: 'Perbarui Semua Job berdasarkan Job Type',
  //   message: 'Perbarui Seluruh Job yang ada di Job Type',
  //   icon: mdiBriefcaseEditOutline,
  // },
  {
    id: 3,
    label: 'Ubah Attribut Job',
    message: 'Perbarui Attribut dari Job yang telah men-trigger Automation ini',
    icon: mdiBriefcaseOutline,
  },
  {
    id: 4,
    label: 'Ciptakan Dokumen Baru',
    message: 'Tambahkan detail untuk membuat dokumen baru',
    icon: mdiFilePlusOutline,
  },
  // {
  //   id: 5,
  //   label: 'Perbarui Semua Dokumen berdasarkan Job Type',
  //   message: 'Perbarui seluruh dokumen yang ada',
  //   icon: mdiFileEditOutline,
  // },
  {
    id: 6,
    label: 'Ubah Atribut Dokumen',
    message: 'Memperbarui perubahan pada dokumen yang telah men-trigger Automation ini',
    icon: mdiFileOutline,
  },

  {
    id: 7,
    label: 'Kirim Notifikasi',
    message: 'Mengirim notifikasi kepada pengguna',
    icon: mdiBellPlusOutline,
  },

  {
    id: 8,
    label: 'Update User Permission',
    message: 'Update User Permission pada suatu Job Type',
    icon: mdiAccountEditOutline,
  },
  {
    id: 9,
    label: 'Duplikasi Job',
    message: 'Duplikasi Job yang men-trigger Automation ini',
    icon: mdiContentDuplicate,
  },

  {
    id: 10,
    label: 'Update Document Permission',
    message: 'Update Document Permission pada suatu Document',
    icon: mdiFileLockOutline,
  },
]
export const actionTypeMap = {
  1: {
    id: 1,
    label: 'Ciptakan Job Baru',
    message: 'Tambahkan detail untuk membuat job baru',
    icon: mdiBriefcasePlusOutline,
  },
  // 2: {
  //   id: 2,
  //   label: 'Perbarui Semua Job berdasarkan Job Type',
  //   message: 'Perbarui Seluruh Job yang ada di Job Type',
  //   icon: mdiBriefcaseEditOutline,
  // },
  3: {
    id: 3,
    label: 'Ubah Attribut Job',
    message: 'Perbarui Attribut dari Job yang telah men-trigger Automation ini',
    icon: mdiBriefcaseEditOutline,
  },
  4: {
    id: 4,
    label: 'Ciptakan Dokumen Baru',
    message: 'Tambahkan detail untuk membuat dokumen baru',
    icon: mdiFilePlusOutline,
  },
  // 5: {
  //   id: 5,
  //   label: 'Perbarui Semua Dokumen berdasarkan Job Type',
  //   message: 'Perbarui seluruh dokumen yang ada',
  //   icon: mdiFileEditOutline,
  // },
  6: {
    id: 6,
    label: 'Ubah Atribut Dokumen',
    message: 'Memperbarui perubahan pada dokumen yang telah men-trigger Automation ini',
    icon: mdiFileEditOutline,
  },

  7: {
    id: 7,
    label: 'Kirim Notifikasi',
    message: 'Mengirim notifikasi kepada pengguna',
    icon: mdiBellPlusOutline,
  },

  8: {
    id: 8,
    label: 'Update User Permission',
    message: 'Update User Permission pada suatu Job Type',
    icon: mdiAccountEditOutline,
  },
  9: {
    id: 9,
    label: 'Duplikasi Job',
    message: 'Duplikasi Job yang men-trigger Automation ini',
    icon: mdiContentDuplicate,
  },

  10: {
    id: 10,
    label: 'Update Document Permission',
    message: 'Update Document Permission pada suatu Document',
    icon: mdiFileLockOutline,
  },
}

export const actionFieldList = [
  {
    id: 0,
    name: 'name',
    label: 'Name',
    icon: mdiCursorText,
  },
  {
    id: 1,
    name: 'priority',
    label: 'Priority',
    icon: mdiFlagOutline,
  },
  {
    id: 2,
    name: 'status',
    label: 'Status',
    icon: mdiInformationOutline,
  },
  {
    id: 3,
    name: 'start_date',
    label: 'Tanggal Mulai',
    icon: mdiCalendarStartOutline,
  },
  {
    id: 4,
    name: 'expected_close_date',
    label: 'Tanggal perkiraan selesai',
    icon: mdiCalendarCheckOutline,
  },
  {
    id: 5,
    name: 'assign_user_id',
    label: 'Assign kepada User',
    icon: mdiAccountArrowRightOutline,
  },
]
export const actionDateList = [
  {
    id: 'now',
    label: 'Saat ini juga',
    icon: mdiClockOutline,
  },
  {
    id: 'today',
    label: 'Hari ini',
    icon: mdiCalendarTodayOutline,
  },
  {
    id: 'three_days',
    label: 'Dalam 3 Hari kedepan',
    icon: mdiCalendarRangeOutline,
  },
  {
    id: 'seven_days',
    label: 'Dalam 7 Hari kedepan',
    icon: mdiCalendarWeekOutline,
  },
]
export const actionAssignList = [
  {
    id: 0,
    label: 'Ubah',
    icon: mdiAccountSyncOutline,
  },
  {
    id: 1,
    label: 'Tambahkan',
    icon: mdiAccountPlusOutline,
  },
  {
    id: 2,
    label: 'Hapus',
    icon: mdiAccountMinusOutline,
  },
  {
    id: 3,
    label: 'Alihkan',
    icon: mdiAccountSwitchOutline,
  },
]
export const actionUserPermissionList = [
  {
    id: 1,
    label: 'Tambahkan / Update',
    icon: mdiAccountPlusOutline,
  },
  {
    id: 2,
    label: 'Hapus',
    icon: mdiAccountMinusOutline,
  },
]
export const actionUserPermissionTypeListJobType = [
  {
    id: 1,
    label: 'Admin',
    icon: mdiShieldAccountOutline,
  },
  {
    id: 2,
    label: 'Editor',
    icon: mdiPencilOutline,
  },
  {
    id: 3,
    label: 'Comment Only',
    icon: mdiMessageTextOutline,
  },
  {
    id: 4,
    label: 'View Only',
    icon: mdiMonitorEye,
  },
]
export const actionUserPermissionTypeListDocument = [
  {
    id: 1,
    label: 'Maintainer',
    icon: mdiShieldAccountOutline,
  },
  {
    id: 2,
    label: 'Contributor',
    icon: mdiPencilOutline,
  },
  {
    id: 3,
    label: 'Commentor',
    icon: mdiMessageTextOutline,
  },
  {
    id: 4,
    label: 'Viewer',
    icon: mdiMonitorEye,
  },
]
export const customAttributeTypeMap = {
  Text: {
    label: 'Teks',
    message: 'Custom Attribute Teks',
    type: 'string',
    icon: mdiTextBoxOutline,
  },
  Number: {
    label: 'Angka',
    message: 'Custom Attribute Angka',
    type: 'integer',
    icon: mdiNumeric,
  },
  Date: {
    label: 'Tanggal',
    message: 'Custom Attribute Tanggal',
    type: 'date',
    icon: mdiCalendarOutline,
  },
  'Select (Single)': {
    label: 'Select (Single)',
    message: 'Custom Attribute Select (Single)',
    type: 'single',
    icon: mdiCheckboxMarkedCircleOutline,
  },
  'Select (Multiple)': {
    label: 'Select (Multiple)',
    message: 'Custom Attribute Select (Multiple)',
    type: 'multiple',
    icon: mdiCheckboxBlankCircleOutline,
  },
  Boolean: {
    label: 'Boolean',
    message: 'Custom Attribute Boolean',
    type: 'boolean',
    icon: mdiCheckboxOutline,
  },
  File: {
    label: 'File',
    message: 'Custom Attribute File',
    type: 'file',
    icon: mdiFileOutline,
  },
  'Formula Text': {
    label: 'Formula Teks',
    type: 'formula_text',
    message: 'Custom Attribute Formula Teks',
    icon: mdiFunction,
  },
  'Formula Number': {
    label: 'Formula Angka',
    type: 'formula_number',
    message: 'Custom Attribute Formula Angka',
    icon: mdiFunctionVariant,
  },
  'Formula Date': {
    label: 'Formula Tanggal',
    type: 'formula_date',
    message: 'Custom Attribute Formula Tanggal',
    icon: mdiCalendarOutline,
  },
  'Formula Boolean': {
    label: 'Formula Boolean',
    type: 'formula_boolean',
    message: 'Custom Attribute Formula Boolean',
    icon: mdiCheckboxOutline,
  },

  // Miscellaneous
  Attachments: {
    label: 'Formula Boolean',
    type: 'attachment',
    message: 'Custom Attribute Formula Boolean',
    icon: mdiAttachment,
  },
  'Ya Tidak': {
    label: 'Boolean',
    message: 'Custom Attribute Boolean',
    type: 'boolean',
    icon: mdiCheckboxOutline,
  },
}
