<template>
  <v-card id="mentionMenuList">
    <v-tabs
      v-model="tab"
      align-with-title
      class="flex-grow-1"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab class="ml-0">
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiAccountGroup }}
        </v-icon>
        <span class="text-capitalize text-subtitle-2">User</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiFileDocumentMultiple }}
        </v-icon>
        <span class="text-capitalize text-subtitle-2">Dokumen</span>
      </v-tab>
      <v-tab> <span class="text-capitalize text-subtitle-2">Job</span> </v-tab>
      <v-btn
        small
        color="primary"
        class="mx-2 my-2"
        @click="mentionHandler"
        >Sebut</v-btn
      >
      <!-- </div> -->
      <v-tab-item>
        <user-team-selector
          :object-id="documentId"
          object-type="document"
          @update:selectedUsers="usersObjectSelected = $event"
          :autoFocus="true"
        />
      </v-tab-item>
      <v-tab-item>
        <document-selector @update:selectedDocuments="documentsSelected = $event" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { ref } from 'vue'
import { mdiAccountGroup, mdiFileDocumentMultiple } from '@mdi/js'
import UserTeamSelector from '@/components/inputs/user-team-selector/UserTeamSelector.vue'
import DocumentSelector from '@/components/inputs/DocumentSelector.vue'

export default {
  components: {
    UserTeamSelector,
    DocumentSelector,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
    instance: {
      type: Object,
      required: true,
    },
    topLevelNodeType: {
      type: String,
      default: '',
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const usersObjectSelected = ref([])
    const documentsSelected = ref([])
    const tab = ref()

    const mentionHandler = () => {
      if (usersObjectSelected.value.length !== 0 && documentsSelected.value.length !== 0) {
        const combinedContent = usersObjectSelected.value
          .flatMap(user => ({
            type: 'userMention',
            attrs: {
              id: user.id,
              label: user.name.trim(),
            },
          }))
          .concat(
            documentsSelected.value.flatMap(document => ({
              type: 'documentMention',
              attrs: {
                uuid: document.uuid,
                label: document.name.trim(),
                docIsPublic: document.is_public,
                docPermission: JSON.stringify(
                  document.document_permissions.map(permission => permission.user.id),
                ),
                createdBy: document.created_by.id,
              },
            })),
          )
          .concat([{ type: 'text', text: ' ' }])

        props.editor.chain().focus().insertContentAt(props.instance.range, combinedContent).run()
      } else if (usersObjectSelected.value.length !== 0) {
        const userContent = usersObjectSelected.value.flatMap(user => ({
          type: 'userMention',
          attrs: {
            id: user.id,
            label: user.name.trim(),
          },
        }))

        props.editor.chain().focus().insertContentAt(props.instance.range, userContent).run()
      } else if (documentsSelected.value.length !== 0) {
        const documentContent = documentsSelected.value.flatMap(document => ({
          type: 'documentMention',
          attrs: {
            uuid: document.uuid,
            label: document.name.trim(),
            docIsPublic: document.is_public,
            docPermission: JSON.stringify(
              document.document_permissions.map(permission => permission.user.id),
            ),
            createdBy: document.created_by.id,
          },
        }))

        props.editor.chain().focus().insertContentAt(props.instance.range, documentContent).run()
      }

      console.log(usersObjectSelected.value, documentsSelected.value)
    }

    return {
      tab,
      usersObjectSelected,
      mentionHandler,
      documentsSelected,

      icons: {
        mdiAccountGroup,
        mdiFileDocumentMultiple,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.slash-command-list {
  @include style-scroll-bar();
  overflow-y: auto;
}
</style>
