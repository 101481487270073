<template>
  <node-view-wrapper
    class="vue-component dom"
    :status="node.attrs?.status"
  >
    <div v-if="node.attrs.src">
      <v-hover v-slot="{ hover }">
        <v-card
          outlined
          :color="hover ? 'secondary' : ''"
          class="pa-2 webBookmarkComponent"
        >
          <a
            :href="node.attrs.src"
            target="_blank"
            class="d-flex flex-row"
          >
            <div class="flex-grow-1">
              <v-card-title
                v-if="node.attrs.title"
                class="font-weight-medium"
              >
                <span :class="`${hover ? 'text-white' : ''}`">
                  {{
                    node.attrs.title.length > 50
                      ? node.attrs.title.substring(0, 50) + '...'
                      : node.attrs.title
                  }}
                </span>
              </v-card-title>
              <v-card-subtitle
                v-if="node.attrs.description"
                :class="`py-1 ${hover ? 'text-white' : ''}`"
              >
                {{
                  node.attrs.description.length > 100
                    ? node.attrs.description.substring(0, 100) + '...'
                    : node.attrs.description
                }}
              </v-card-subtitle>
              <v-card-text class="pb-0 px-3">
                <a
                  :href="node.attrs.src"
                  target="_blank"
                  :class="`${hover ? 'text-white' : ''}`"
                  >{{ node.attrs.src }}</a
                >
              </v-card-text>
            </div>

            <v-img
              v-if="node.attrs.img"
              max-height="150"
              max-width="250"
              :src="node.attrs.img"
            ></v-img>
          </a>

          <div style="position: absolute; top: 10px; right: 20px; z-index: 1">
            <v-menu
              v-model="bookmarkMenu"
              :close-on-content-click="false"
              min-width="400"
              left
            >
              <template #activator="menu">
                <v-tooltip top>
                  <template #activator="tooltip">
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon
                        v-show="hover"
                        v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                        class="icon-background"
                        color="white"
                        size="30"
                        v-on="{ ...menu.on, ...tooltip.on }"
                        @click.stop
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-slide-x-reverse-transition>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </template>
              <v-card
                width="400"
                min-height="150px"
              >
                <v-row class="py-2 px-3">
                  <v-col cols="12">
                    <v-text-field
                      v-model="linkBookmark"
                      :value="linkBookmark"
                      placeholder="https://"
                      dense
                      outlined
                      clearable
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      class="w-100"
                      @click="changeLinkBookmark"
                    >
                      <div v-if="loadingScraping">
                        <v-progress-circular
                          indeterminate
                          color="white"
                        ></v-progress-circular>
                      </div>
                      <div v-else>Edit Bookmark</div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </div>
        </v-card>
      </v-hover>
    </div>
    <div v-else>
      <upload-component
        :loading="loadingScraping"
        :link-bookmark="linkBookmark"
        :icon="icons.mdiBookmarkOutline"
        upload-type="bookmark"
        @submit="changeLinkBookmark"
        @set-linkBookmark="linkBookmark = $event"
      />
    </div>
  </node-view-wrapper>
</template>
<script>
import { fetchOpenGraphData } from '@/components/editor/utils/scrape'
import { mdiBookmarkOutline, mdiPencil } from '@mdi/js'
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
import { ref, watch } from 'vue'
import UploadComponent from './UploadComponent.vue'

export default {
  components: {
    NodeViewWrapper,
    UploadComponent,
  },

  props: nodeViewProps,
  setup(props) {
    const linkBookmark = ref('')
    const loadingScraping = ref(false)
    const bookmarkMenu = ref(false)

    if (props.node.attrs.src) linkBookmark.value = props.node.attrs.src

    watch(bookmarkMenu, () => {
      if (!bookmarkMenu.value && props.node.attrs.src) {
        linkBookmark.value = props.node.attrs.src
      }
    })

    return {
      linkBookmark,
      bookmarkMenu,
      loadingScraping,
      icons: {
        mdiBookmarkOutline,
        mdiPencil,
      },
    }
  },
  methods: {
    async changeLinkBookmark() {
      if (!/^https?:\/\//i.test(this.linkBookmark)) {
        this.linkBookmark = `https://${this.linkBookmark}`
      }

      if (this.linkBookmark !== '' && !this.loadingScraping) {
        this.loadingScraping = true

        const response = await fetchOpenGraphData(this.linkBookmark)

        if (response.status) {
          this.updateAttributes({
            src: null,
            img: null,
            title: null,
            description: null,
          })

          if (response.status === 200) {
            this.updateAttributes({
              src: this.linkBookmark,
              img: response.data.ogImage,
              title: response.data.ogTitle,
              description: response.data.ogDescription,
            })
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('Link page not found!')
        }

        this.loadingScraping = false
      }
    },
  },
}
</script>
<style scoped>
.yellow-background {
  background-color: #f5c518;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}
.icon-background {
  background-color: black;
  border-radius: 50%;
  padding: 5px;
}
</style>
