<template>
  <v-menu
    ref="menu"
    offset-y
    open-on-hover
  >
    <template #activator="menu">
      <v-tooltip
        top
        :open-on-hover="false"
        :open-on-click="false"
        :value="$refs.menu && $refs.menu.isActive"
      >
        <template #activator="{ attrs, on }">
          <v-btn
            class="px-0"
            tile
            elevation="0"
            min-width="70"
            max-width="70"
            style="border-right: 0"
            v-bind="{ ...menu.attrs, ...attrs }"
            v-on="{ ...menu.on, ...on }"
          >
            <v-icon size="26">
              {{ icons.mdiFormatColorHighlight }}
            </v-icon>
            <v-icon size="26">
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        Highlight Color
      </v-tooltip>
    </template>
    <v-list
      dense
      class="pt-1"
    >
      <v-subheader> Recently Used </v-subheader>
      <v-list-item
        class="ps-3 mb-2"
        @click="setHighlight(recentlyUsed.hex)"
      >
        <v-list-item-icon class="pr-2 mb-0">
          <v-icon :color="recentlyUsed.hex !== '#000' ? recentlyUsed.hex : null">
            {{ icons.mdiFormatColorHighlight }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ recentlyUsed.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-subheader> Text Highlight </v-subheader>
      <div
        v-for="(color, index) in colorPalette"
        :key="color.hex"
      >
        <v-list-item
          class="ps-3"
          color="primary"
          :input-value="color.active"
          @click="setHighlight(color.hex, index)"
        >
          <v-list-item-icon class="pr-2 mb-0">
            <v-icon :color="color.hex !== '#000' ? color.hex : null">
              {{ icons.mdiFormatColorHighlight }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ color.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { ref, computed } from 'vue'
import { mdiChevronDown, mdiFormatColorHighlight } from '@mdi/js'

export default {
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const recentlyUsed = ref({ label: 'Default', hex: '#000' })
    const colorPalette = computed(() => [
      {
        label: 'None',
        hex: '#000',
        active: !props.editor.isActive('highlight'),
      },
      {
        label: 'Gray',
        hex: '#808080',
        active: props.editor.isActive('highlight', { color: '#808080' }),
      },
      {
        label: 'Violet',
        hex: '#958DF1',
        active: props.editor.isActive('highlight', { color: '#958DF1' }),
      },
      {
        label: 'Red',
        hex: '#FF0000',
        active: props.editor.isActive('highlight', { color: '#FF0000' }),
      },
      {
        label: 'Orange',
        hex: '#FFA500',
        active: props.editor.isActive('highlight', { color: '#FFA500' }),
      },
      {
        label: 'Yellow',
        hex: '#FFFF00',
        active: props.editor.isActive('highlight', { color: '#FFFF00' }),
      },
      {
        label: 'Blue',
        hex: '#70CFF8',
        active: props.editor.isActive('highlight', { color: '#70CFF8' }),
      },
      {
        label: 'Green',
        hex: '#008000',
        active: props.editor.isActive('highlight', { color: '#008000' }),
      },
      {
        label: 'Brown',
        hex: '#A52A2A',
        active: props.editor.isActive('highlight', { color: '#A52A2A' }),
      },
      {
        label: 'Pink',
        hex: '#FFC0CB',
        active: props.editor.isActive('highlight', { color: '#FFC0CB' }),
      },
    ])
    const setHighlight = (color, index) => {
      if (index !== undefined) recentlyUsed.value = colorPalette.value[index]

      if (color === '#000') {
        props.editor.chain().focus().unsetHighlight().blur().run()
      } else {
        props.editor.chain().focus().toggleHighlight({ color }).blur().run()
      }
    }

    return {
      setHighlight,
      colorPalette,
      recentlyUsed,

      icons: {
        mdiFormatColorHighlight,
        mdiChevronDown,
      },
    }
  },
}
</script>
