import { find } from 'linkifyjs'

export default function editHyperlink(options) {
  const from = options.nodePos

  let link = null

  const { node } = options.editor.view.domAtPos(from)
  const nodeName = node?.nodeName
  let currentPos = 1

  // get current block
  for (let i = 0; i < node.pmViewDesc.parent.children.length; i++) {
    const block = node.pmViewDesc.parent.children[i]

    if (options.nodePos < currentPos + block.size) {
      break
    }

    currentPos += block.size
  }

  let posTextOfBlock = currentPos

  if (nodeName === 'P') {
    const paragraphElement = node.closest('p')

    const { childNodes } = paragraphElement

    for (let i = 0; i < childNodes.length; i += 1) {
      const child = childNodes[i]
      const textLength = child.pmViewDesc.size

      // Check if the current node is within the range of the current position
      if (posTextOfBlock <= options.nodePos && options.nodePos < posTextOfBlock + textLength) {
        if (child.nodeName === 'A') {
          link = child
          break
        }
      }

      posTextOfBlock += textLength
    }
  }

  // if (nodeName === '#text') {
  //   link = node.parentNode?.closest('a')
  // } else {
  //   link = node?.closest('a')
  // }

  // If no link found and node is paragraph, try finding link in sibling/parent
  // if (!link && options.editor.view.domAtPos(from).node.nodeName === 'P' && node.nextSibling)
  //   link = node.nextSibling || node.parentElement

  // if (!link) return true

  const text = options.newText || link?.innerText

  // Get the position of the link in the editor view
  const sanitizeURL = find(options.newURL || link.href)
    // eslint-disable-next-line no-shadow
    .filter(link => link.isLink)
    // eslint-disable-next-line no-shadow
    .filter(link => (options.validate ? options.validate(link.value) : true))
    .at(0)

  return options.editor
    .chain()
    .setTextSelection(options.nodePos)
    .command(({ tr }) => {
      tr.deleteRange(
        options.nodePos,
        options.nodePos + (link?.innerText || '')?.length,
      ).replaceWith(
        options.nodePos,
        options.nodePos,
        options.editor.schema.text(text, [
          options.editor.schema.marks.hyperlink.create({
            href: sanitizeURL?.href,
          }),
        ]),
      )

      return true
    })
    .setTextSelection(options.nodePos)
    .run()

  // options.editor
  // .chain()
  // .focus()
  // .command(({ tr }) => {
  //   tr.deleteRange(nodePos, nodePos + (link?.innerText || '')?.length).replaceWith(
  //     nodePos,
  //     nodePos,
  //     options.editor.schema.text(text, [
  //       options.editor.schema.marks.hyperlink.create({
  //         href: sanitizeURL?.href,
  //       }),
  //     ]),
  //   )

  //   return true
  // })
  // .run()
}
