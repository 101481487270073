<template>
  <div
    style="border: 1px solid transparent"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <div class="bottom">
      <comment-field
        :content.sync="commentContent"
        :placeholder="placeholder"
        @toggle-dialog="attachMenu = $event"
        @show-viewer="$refs.viewerRef.show($event)"
      />
      <div
        v-if="isOpen"
        class="d-flex align-center pa-2"
      >
        <v-btn
          v-if="toggleable"
          color="primary"
          small
          text
          icon
          @click="
            isOpen = false
            $emit('close')
          "
        >
          <v-icon size="20">
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          class="ml-auto mr-4"
          :class="{ 'pulse-red': isListening }"
        >
          <v-icon
            v-if="isSupported"
            size="20"
            :color="isListening ? 'error' : null"
            @click="isListening ? stop() : start()"
          >
            {{ icons.mdiMicrophone }}
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          small
          :loading="loadingSubmit"
          @click="submitComment"
        >
          Submit
        </v-btn>
      </div>
      <div
        v-else
        class="d-flex align-center justify-center"
      >
        <v-btn
          color="primary"
          small
          text
          class="px-1"
          @click="
            isOpen = true
            $emit('open')
          "
        >
          Buat Komentar
          <v-icon
            size="18"
            class="ml-2"
          >
            {{ icons.mdiCommentPlus }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <input
      ref="dropFileInput"
      type="file"
      class="d-none"
      multiple
      @change="onFileChange"
    />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import {
  mdiAttachment,
  mdiPlus,
  mdiMicrophone,
  mdiCommentPlus,
  mdiChevronDown,
  mdiClose,
} from '@mdi/js'
import { useSpeechRecognition } from '@vueuse/core'
import { ellipsis } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import { addComment as addCommentMutation } from '@/graphql/mutations'
import { getCaretPosition, setCaretPosition } from '@/utils/caretHelper'
import errorHandling from '@/utils/errorHandling'
import dropFile from '@/utils/dropFile'
import CommentField from './CommentField.vue'
import store from '@/store'

export default {
  components: {
    CommentField,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Tulis komentar...',
    },
    content: {
      type: String,
      default: null,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loadingSubmit = ref(false)
    const loadingSearch = ref(false)
    const isOpen = ref(true)
    const commentContent = ref('')
    const attachment = ref()
    const isSubmitted = ref(false)

    const { documents, onDragOver, onDrop, onDragLeave, onFileChange, deleteDoc } = dropFile()

    onMounted(() => {
      if (props.content) {
        commentContent.value = props.content
      }
    })

    onBeforeUnmount(() => {
      if (!isSubmitted.value) {
        emit('canceled')
      }
    })

    const addComment = content => {
      const variables = {
        workspace_id: store.getters.getCurrentWorkspaceId,
        document_uuid: props.documentId,
        comment: content,
      }

      apolloClient
        .mutate({
          mutation: addCommentMutation,
          variables,
        })
        .then(({ data }) => {
          emit('close', JSON.parse(data.addComment.data).commentId)
          commentContent.value = ''
        })
        .catch(err => {
          errorHandling(err)
        })
    }

    const submitComment = () => {
      isSubmitted.value = true
      addComment(commentContent.value)
    }

    const { isSupported, isListening, isFinal, result, start, stop } = useSpeechRecognition({
      lang: 'id-ID',
    })

    return {
      loadingSubmit,
      loadingSearch,
      documents,
      commentContent,
      attachment,
      getCaretPosition,
      setCaretPosition,
      isOpen,

      onDragOver,
      onDragLeave,
      onDrop,
      onFileChange,
      deleteDoc,

      submitComment,

      icons: {
        mdiAttachment,
        mdiPlus,
        mdiMicrophone,
        mdiCommentPlus,
        mdiChevronDown,
        mdiClose,
      },

      ellipsis,

      isSupported,
      isListening,
      isFinal,
      result,
      start,
      stop,
    }
  },
}
</script>
