<template>
  <v-btn-toggle
    dense
    :max="0"
    multiple
    :value="[]"
  >
    <template v-for="(item, index) in tableCellTools">
      <v-tooltip
        :key="index"
        top
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            @click="selectItem(index)"
            v-on="on"
          >
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
    </template>
  </v-btn-toggle>
</template>

<script>
import { tableCellTools } from '../utils/table'

export default {
  props: {
    editor: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      tableCellTools: tableCellTools(),
    }
  },
  methods: {
    selectItem(index) {
      const item = this.tableCellTools[index]
      if (item.command) item.command(this.editor)
    },
  },
}
</script>
