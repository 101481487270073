const roleList = [
  {
    id: 1,
    name: 'ADMIN',
    create: '1,2,3,4,5,6,7,8,9.10',
    read: '1,2,3,4,5,6,7,8,9,10',
    update: '1,2,3,4,5,6,7,8,9,10',
    del: '1,2,3,4,5,6,7,8,9,10',
  },
  {
    id: 2,
    name: 'MEMBER',
    create: '1,2,3,4,5,6,7,8',
    read: '1,2,3,4,5,6,7,8,9,10',
    update: '1,2,3,4,5,6,7,8,10',
    del: '',
  },
  {
    id: 3,
    name: 'GUEST',
    create: '1',
    read: '1,2,3,4,5,7,8,9',
    update: '1',
    del: '',
  },
]

export default roleList
